import React from 'react'

import queryString from 'query-string' // adam added

import Lottie from 'react-lottie' // adam added
import animationData420 from '../../../assets/lotties/420.json'; // adam added

import { BrowserRouter as Router, Switch, Route, NavLink, Link } from 'react-router-dom';

import './SideBar.css'

import MobileSideBar from '../MobileSideBar/MobileSideBar';

import Home from '../../Home/Home'
import About from '../../About/About'
import Products from '../../Products/Products'
import Flavours from '../../Flavours/Flavours'
import Buy from '../../Buy/Buy'
import ViewerCounter from '../ViewerCounter/ViewerCounter'
import NotFound from '../NotFound/NotFound'

/*import AnimatedBook from '../../../assets/img/animated-book.gif'*/
import EmailIconSpinningMailbox from '../../../assets/img/email-icon-spinning-mailbox.gif'
import GuestBook from '../../../assets/img/guestbook-button.svg'
import VisitorButton from '../../../assets/img/visitor-button.svg'
import TableTopLogo from '../../../assets/img/tabletop-logo.svg'

import DiamondDustOG from '../../Flavours/FlavourComponents/DiamondDustOG/DiamondDustOG'
import OGKush from '../../Flavours/FlavourComponents/OGKush/OGKush'
import SourKush from '../../Flavours/FlavourComponents/SourKush/SourKush'
import LakeTahoeOG from '../../Flavours/FlavourComponents/LakeTahoeOG/LakeTahoeOG'
// import BlueLemonThai from '../../Flavours/FlavourComponents/BlueLemonThai/BlueLemonThai'
import AmherstSuperSkunk from '../../Flavours/FlavourComponents/AmherstSuperSkunk/AmherstSuperSkunk'
import SundaeDriver from '../../Flavours/FlavourComponents/SundaeDriver/SundaeDriver'
import PurplePunch from '../../Flavours/FlavourComponents/PurplePunch/PurplePunch'
import StickyBuns from '../../Flavours/FlavourComponents/StickyBuns/StickyBuns'
// import GarlicBreath from '../../Flavours/FlavourComponents/GarlicBreath/GarlicBreath'
// import GrandaddyBlack from '../../Flavours/FlavourComponents/GrandaddyBlack/GrandaddyBlack';
// import GrilledCheese from '../../Flavours/FlavourComponents/GrilledCheese/GrilledCheese'
// import GreaseMonkey from '../../Flavours/FlavourComponents/GreaseMonkey/GreaseMonkey'
import CactusBreath from '../../Flavours/FlavourComponents/CactusBreath/CactusBreath'
// import DieselDough from '../../Flavours/FlavourComponents/DieselDough/DieselDough'
// import GrapeDiamonds from '../../Flavours/FlavourComponents/GrapeDiamonds/GrapeDiamonds'
// import Moontang from '../../Flavours/FlavourComponents/Moontang/Moontang'
// import DurbanLime from '../../Flavours/FlavourComponents/DurbanLime/DurbanLime'
// import LemonDiesel from '../../Flavours/FlavourComponents/LemonDiesel/LemonDiesel'
// import ZombieKush from '../../Flavours/FlavourComponents/ZombieKush/ZombieKush'
// import RaceFuelOG from '../../Flavours/FlavourComponents/RaceFuelOG/RaceFuelOG'
// import Banjo from '../../Flavours/FlavourComponents/Banjo/Banjo'
import JungleCake from '../../Flavours/FlavourComponents/JungleCake/JungleCake'

import SanFranSourDough from '../../Flavours/FlavourComponents/SanFranSourDough/SanFranSourDough'
// import JustFineGrindBoldSativa from '../../Flavours/FlavourComponents/JustFineGrindBoldSativa/JustFineGrindBoldSativa'
// import JustFineGrindBoldIndica from '../../Flavours/FlavourComponents/JustFineGrindBoldIndica/JustFineGrindBoldIndica'
import AllenWrench from '../../Flavours/FlavourComponents/AllenWrench/AllenWrench'
// import SquareDance from '../../Flavours/FlavourComponents/SquareDance/SquareDance'
// import JustFineGrindSativa from '../../Flavours/FlavourComponents/JustFineGrindSativa/JustFineGrindSativa'
// import JustFineGrindIndica from '../../Flavours/FlavourComponents/JustFineGrindIndica/JustFineGrindIndica'
import PrettyGreat28 from '../../Flavours/FlavourComponents/PrettyGreat28/PrettyGreat28'
// import KiwiKush from '../../Flavours/FlavourComponents/KiwiKush/KiwiKush'

import CitySlicker from '../../Flavours/FlavourComponents/CitySlicker/CitySlicker'
import FuzzyHammer from '../../Flavours/FlavourComponents/FuzzyHammer/FuzzyHammer'
import Yogurt from '../../Flavours/FlavourComponents/Yogurt/Yogurt'
import HashBurger from '../../Flavours/FlavourComponents/HashBurger/HashBurger'

import DriedFlower from '../../Products/DriedFlower/DriedFlower'
import PreRolls from '../../Products/PreRolls/PreRolls'
// import Vape from '../../Products/Vape/Vape'

import Guestbook from '../../Guestbook/Guestbook'

import TermsAndConditions from '../Legal/TermsAndConditions';
import PrivacyPolicy from '../Legal/PrivacyPolicy';

import Newsletter from '../../Newsletter/Newsletter';

import Header from '../../BasePage/Header/Header.js';

import $ from 'jquery';

// adam added - LiveChat
import LiveChat from 'react-livechat'

// import '../../Guestbook/jqueryloader.js'
// const jQuery = window.$

const routes = [
    {
        path: "/",
        exact: true,
        sidebar: () => null,
        main: () => < Home />
    },
    {
        path: "/about",
        sidebar: () => null,
        main: () => <About />
    },
    // keep the /products/type/subpages ABOVE the main /products/type page here
    {
        path: '/products/purple-punch',
        sidebar: () => null,
        main: () => <PurplePunch />
    },
    {
        path: '/products/sticky-buns',
        sidebar: () => null,
        main: () => <StickyBuns />
    },
    /*{
        path: '/products/garlic-breath',
        sidebar: () => null,
        main: () => <GarlicBreath />
    },*/
    /*{
        path: '/products/grilled-cheese',
        sidebar: () => null,
        main: () => <GrilledCheese />
    },*/
    /*{
        path: '/products/grape-diamonds',
        sidebar: () => null,
        main: () => <GrapeDiamonds />
    },
    {
        path: '/products/moontang',
        sidebar: () => null,
        main: () => <Moontang />
    },
    {
        path: '/products/durban-lime',
        sidebar: () => null,
        main: () => <DurbanLime />
    },
    {
        path: '/products/lemon-diesel',
        sidebar: () => null,
        main: () => <LemonDiesel />
    },
    {
        path: '/products/zombie-kush',
        sidebar: () => null,
        main: () => <ZombieKush />
    },
    {
        path: '/products/race-fuel-og',
        sidebar: () => null,
        main: () => <RaceFuelOG />
    },
    {
        path: '/products/banjo',
        sidebar: () => null,
        main: () => <Banjo />
    },*/
    {
        path: '/products/jungle-cake',
        sidebar: () => null,
        main: () => <JungleCake />
    },
    /*{
        path: '/products/diesel-dough',
        sidebar: () => null,
        main: () => <DieselDough />
    },*/
    {
        path: '/products/diamond-dust-og',
        sidebar: () => null,
        main: () => <DiamondDustOG />
    },
    {
        path: '/products/og-kush',
        sidebar: () => null,
        main: () => <OGKush />
    },
    {
        path: '/products/sour-kush',
        sidebar: () => null,
        main: () => <SourKush />
    },
    {
        path: '/products/lake-tahoe-og',
        sidebar: () => null,
        main: () => <LakeTahoeOG />
    },
    /*{
        path: '/products/blue-lemon-thai',
        sidebar: () => null,
        main: () => <BlueLemonThai />
    },*/
    {
        path: '/products/amherst-super-skunk',
        sidebar: () => null,
        main: () => <AmherstSuperSkunk />
    },
    {
        path: '/products/sundae-driver',
        sidebar: () => null,
        main: () => <SundaeDriver />
    },
    {
        path: '/products/purple-punch',
        sidebar: () => null,
        main: () => <PurplePunch />
    },
    {
        path: '/products/sticky-buns',
        sidebar: () => null,
        main: () => <StickyBuns />
    },
    /*{
        path: '/products/grease-monkey',
        sidebar: () => null,
        main: () => <GreaseMonkey />
    },*/
    {
        path: '/products/cactus-breath',
        sidebar: () => null,
        main: () => <CactusBreath />
    },
    {
        path: '/products/san-fran-sour-dough',
        sidebar: () => null,
        main: () => <SanFranSourDough />
    },
    /*{
        path: '/products/just-fine-grind-bold-sativa',
        sidebar: () => null,
        main: () => <JustFineGrindBoldSativa />
    },
    {
        path: '/products/just-fine-grind-bold-indica',
        sidebar: () => null,
        main: () => <JustFineGrindBoldIndica />
    },*/
    {
        path: '/products/allen-wrench',
        sidebar: () => null,
        main: () => <AllenWrench />
    },
    /*{
        path: '/products/square-dance',
        sidebar: () => null,
        main: () => <SquareDance />
    },
    {
        path: '/products/just-fine-grind-sativa',
        sidebar: () => null,
        main: () => <JustFineGrindSativa />
    },
    
    {
        path: '/products/just-fine-grind-indica',
        sidebar: () => null,
        main: () => <JustFineGrindIndica />
    },*/
    {
        path: '/products/pretty-great-28',
        sidebar: () => null,
        main: () => <PrettyGreat28 />
    },
    /*{
        path: '/products/80s-kiwi-kush',
        sidebar: () => null,
        main: () => <KiwiKush />
    },*/
    {
        path: '/products/city-slicker',
        sidebar: () => null,
        main: () => <CitySlicker />
    },
    {
        path: '/products/fuzzy-hammer',
        sidebar: () => null,
        main: () => <FuzzyHammer />
    },
    {
        path: '/products/yogurt',
        sidebar: () => null,
        main: () => <Yogurt />
    },
    {
        path: '/products/hash-burger',
        sidebar: () => null,
        main: () => <HashBurger />
    },
    
    // keep the /products/type pages ABOVE the main /products page here
    {
        path: "/products/dried-flower",
        sidebar: () => null,
        main: () => <DriedFlower />
    },
    {
        path: "/products/pre-rolls",
        sidebar: () => null,
        main: () => <PreRolls />
    },
    {
        path: "/products",
        sidebar: () => null,
        main: () => <Products />
    },
    {
        path: "/buy",
        sidebar: () => null,
        main: () => <Buy />
    },
    {
        path: '/guestbook',
        sidebar: () => null,
        main: () => <Guestbook />
    },
    {
        path: '/newsletter',
        sidebar: () => null,
        main: () => <Newsletter />
    },


    /*{
        path: '/privacy-policy',
        sidebar: () => null,
        main: () => null
    },*/


];

// -----------------------------------------------------------------------------
// adam added
// -----------------------------------------------------------------------------

const defaultOptions420 = {
    loop: true,
    autoplay: true,
    animationData: animationData420,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

let disable_lottie_until_refresh = false;

let is_420 = false;

// get url parameters, show the 420 alarm if ?420=true
const qs = require('query-string');
console.log('ls',window.location.search);
const search = window.location.search;
const params = new URLSearchParams(search);
if ( params.get('show_420_and_bg_opacity') )
    is_420 = true;

// if ( params.get('bg_opacity') == '100%' )


// console.log('height ', window.innerHeight);


// -----------------------------------------------------------------------------
// end adam added
// -----------------------------------------------------------------------------

class SideBar extends React.Component {
    state = { width: 0, height: 0 };
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        // adam added
        if ( document.getElementById('Lottie420') ) {
            console.log('blah'+ document.getElementById('Lottie420') );
            this.intervalID = setInterval( () => this.tick(), 1000 ); 
            document.addEventListener("keydown", this.onKeyPress, false);
            $( window, 'body' ).click(function() {
              document.getElementById('Lottie420').style.display = 'none';
              disable_lottie_until_refresh = true;
            });
        }
        // end adam added


        // adam added - set the meta keywords site-wide
        let tab_keywords     = `table top cannabis, table top, table top 90s og kush, table top blue lemon thai, table top purple punch, table top sticky buns, table top garlic breath, table top grilled cheese, table top grease monkey, table top sundae driver, table top lemon diesel, table top diesel dough, table top jungle cake`;
        let tab_keywords_meta_tag = document.querySelector(`meta[name="keywords"]`);
        if (tab_keywords_meta_tag) // check if the meta tag already exists
          tab_keywords_meta_tag.setAttribute("content", tab_keywords);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="keywords" content="${tab_keywords}">`); // adds the description meta tag
        // end adam added - set the meta keywords site-wide


        

        

        // temp fix for the copyright problem (from jan-13-2023)
        /*let the_copyright_bar = $('.ConstantTermsAndConditions').outerHeight();

        var the_body = document.body,
            the_html = document.documentElement;

        var the_total_window_height = Math.max( the_body.scrollHeight, the_body.offsetHeight, 
                               the_html.clientHeight, the_html.scrollHeight, the_html.offsetHeight );

        console.log( 'bar is '+the_total_window_height );

        $('.ConstantTermsAndConditions').css({ 'top': the_total_window_height+'px' });*/


        // adam added - so we can add meta tags
        // we don't actually use this though, because defining it here like this doesn't let us use it in individual pages, so I'm leaving this here in case we ever want to unhide this, and we just use the "document.head.insert..." technique on each page instead
        /*
        const metaAdder = (queryProperty, value) => {
          // Get an element if it exists already
          let element = document.querySelector(`meta[${queryProperty}]`);

          // Check if the element exists
          if (element) {
            // If it does just change the content of the element
            element.setAttribute("content", value);
          } else {
            // It doesn't exist so lets make a HTML element string with the info we want
            element = `<meta ${queryProperty} content="${value}" />`;

            // And insert it into the head
            document.head.insertAdjacentHTML("beforeend", element);
          }
        };

        // to call this, you'd do: metaAdder('name="description"', 'Page description here')
        */
        // end adam added - so we can add meta tags

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        clearInterval(this.intervalID); // adam added
        document.removeEventListener("keydown", this.onKeyPress, false); // adam added

        
        /*const copyright_bar          = this.copyrightBar.current;
        const the_sidebar            = this.theSidebar.current;
        copyright_bar.style.position = '';
        copyright_bar.style.top      = '';*/
    }

    componentDidUpdate() {
        // copyright issue fix Jan 31 2023 - position the copyright bar; this only works because of the "React.createRef" that we do in "constructor(props)"
       /* const copyright_bar          = this.copyrightBar.current;
        const the_sidebar            = this.theSidebar.current;
        if( 0 && document.querySelector('body').classList.contains('products-page') || document.querySelector('body').classList.contains('home-page') )
        {
            
            let sidebar_height           = the_sidebar.offsetHeight;
            let sidebar_outerheight      = sidebar_height + 80;
            copyright_bar.style.position = 'absolute';
            copyright_bar.style.top      = sidebar_outerheight+'px';
            
            // let the_body = document.querySelector('body');
            // alert('sidebar height '+sidebar_outerheight+' body '+document.querySelector('body').classList.contains('products-page') );
        }
        else
        {
            copyright_bar.style.position = '';
            copyright_bar.style.top      = '';
        }*/
    }



    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------
    
    constructor(props) {
        super(props)
        this.state = { time: new Date().toLocaleString() }; // adam added the "time" part

        // copyright issue fix Jan 31 2023 - create the refs
        // this.copyrightBar = React.createRef();
        // this.theSidebar = React.createRef();
        

    }

    onLoad() {
        // copyright issue fix Jan 31 2023 - position the copyright bar
        /*const copyright_bar          = this.copyrightBar.current;
        const the_sidebar            = this.theSidebar.current;
        let sidebar_height           = the_sidebar.offsetHeight;
        let sidebar_outerheight      = sidebar_height + 80;
        copyright_bar.style.position = 'absolute';
        copyright_bar.style.top      = sidebar_outerheight+'px';*/
        // alert('sidebar height '+sidebar_outerheight);
    }

    // adam added

    tick() {
        this.setState({ time: new Date().toLocaleString() });
        let current_datetime = new Date();
        if ( ( current_datetime.getHours() == 4 || current_datetime.getHours() == 16 ) && current_datetime.getMinutes() == '20' && current_datetime.getSeconds() >= '00' && current_datetime.getSeconds() < '06'  &&  !disable_lottie_until_refresh )
            document.getElementById('Lottie420').style.display = '';
        else
            document.getElementById('Lottie420').style.display = 'none';
    }

    onKeyPress(event){
        if(event.keyCode === 27) {
            document.getElementById('Lottie420').style.display = 'none';
            disable_lottie_until_refresh = true;
            // alert( 'key '+event.keyCode );
        }
    }

    paramsGetter(){
        const params = new URLSearchParams(this.props.location.search);
    }




    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------


    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <Router>
                    <Route path='/terms-conditions'>
                        <TermsAndConditions />
                    </Route>
                    <Route path='/privacy-policy'>
                        <PrivacyPolicy />
                    </Route>

                    {/* 420 alarm */}
                    <div className='Lottie420' id='Lottie420' style={{ display: 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: `rgba( 0, 0, 0, ${params.get('show_420_and_bg_opacity')} )`, pointerEvents: 'none', zIndex: 100 }}>
                        <Lottie isClickToPauseDisabled={true} options={defaultOptions420} />
                    </div>

                    <Header />


                    <div className='TableTopRight'>
                        <img src={TableTopLogo} alt='TableTop Logo' />
                    </div>
                    <div className="sidebar-and-content-wrapper">

                        {/* This nested div contains the actual SideBar front-end */}
                        <div className='SideBar test' ref={this.theSidebar}>
                            <ul className='PageLinks'>
                                <li className='ListItem'>
                                    <NavLink exact to="/">Home</NavLink>
                                </li>
                                <li className='ListItem'>
                                    <NavLink to="/about" activeClassName="active">About</NavLink>
                                </li>
                                {/*<li className='ListItem'>
                                    <NavLink to="/strains" isActive={(match, location) => {
                                        if (location.pathname.includes('ogkush') || location.pathname.includes('sourkush') || location.pathname.includes('laketahoeog') || location.pathname.includes('bluelemonthai') ||
                                            location.pathname.includes('purplepunch') || location.pathname.includes('stickybuns') || location.pathname.includes('amherstsuperskunk') || location.pathname.includes('strains')) {

                                            return true;
                                        }
                                        // console.log(location.toString());
                                        return false;
                                    }} activeClassName="active">Strains</NavLink>
                                </li>*/}
                                <li className='ListItem'>
                                    <NavLink to="/products" activeClassName="active">Products</NavLink>
                                </li>

                                <li className='ListItem'>
                                    <NavLink to="/buy" activeClassName="active">Buy</NavLink>
                                </li>

                                {/*<li className='ListItem'>
                                    <a href="#" data-newsletter-modal-trigger>Newsletter</a>
                                </li>*/}

                            </ul>
                            <ul className='SocialMedia'>
                                <li className='ListItem'>
                                    <a href='https://www.facebook.com/tabletopsocial' target="_blank" rel="noreferrer">FACEBOOK</a>
                                </li>
                                <li className='ListItem'>
                                    <a href='https://www.instagram.com/tabletop_social/' target="_blank" rel="noreferrer">INSTAGRAM</a>
                                </li>
                            </ul>
                            <ul className='GuestBookVisitors'>
                                <li className='ListItem'>
                                    <Link to='/newsletter'>
                                        <img className='MobileFooter__newsletter-image' src={EmailIconSpinningMailbox} alt='Newsletter' />
                                    </Link>
                                </li>
                                <li className='ListItem'>
                                    <Link to='/guestbook'>
                                        <img id='GuestBook' src={GuestBook} alt='Guest Book' />
                                    </Link>
                                </li>
                                <li className='ListItem'>
                                    <div className='VisitorsDiv'>
                                        <img id='Visitors' src={VisitorButton} alt='Visitor Count' />
                                        <div className='VisitorsText'>
                                            <ViewerCounter />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={<route.sidebar />}
                                    />
                                ))}
                            </Switch>
                        </div>
                        {/* This nested div defines the routes for the application*/}
                        <div style={{ flex: 1 }}>
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={<route.main />}
                                    />
                                ))}
                                <Route path='*'>
                                    <NotFound />
                                </Route>
                            </Switch>
                        </div>
                        <div className='homepage-center-fixer'></div>



                    </div>

                    <div className='ConstantTermsAndConditions' ref={this.copyrightBar} onLoad={this.onLoad.bind(this)} onClick={this.onLoad.bind(this)}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a> | <a href='https://bzam.com/investors/share-information/' target='_blank'>Investors</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>

                    <LiveChat license={13454061} />
                </Router>
            )
        }
        else {
            return (
                <MobileSideBar />
            )

        }

    }
}

export default SideBar;