import React from 'react'

import { Link } from 'react-router-dom'

import FlavoursBackground from './Background/Background'
import Header from '../../BasePage/Header/Header';

//Flavour text
import DiamondDustOG from '../../../assets/img/flavours/TableTop_DiamondDustOG.svg'
import OGKush from '../../../assets/img/flavours/TableTop_90sOGKush.svg'
// import BlueLemonThai from '../../../assets/img/flavours/TableTop_BlueLemonThai.svg'
// import AmherstSuperSkunk from '../../../assets/img/flavours/TableTop_AmherstSuperSkunk.svg'
import SundaeDriver from '../../../assets/img/flavours/TableTop_SundaeDriver.svg'
// import LakeTahoeOG from '../../../assets/img/flavours/TableTop_LakeTahoeOG.svg'
import PurplePunch from '../../../assets/img/flavours/TableTop_PurplePunch.svg'
// import SourKush from '../../../assets/img/flavours/TableTop_SourKush.svg'
import StickyBuns from '../../../assets/img/flavours/TableTop_StickyBuns.svg'
import GarlicBreath from '../../../assets/img/flavours/TableTop_GarlicBreath.svg'
import GreaseMonkey from '../../../assets/img/flavours/TableTop_GreaseMonkey.svg'
import GrilledCheese from '../../../assets/img/flavours/TableTop_GrilledCheese.svg'
import GrandaddyBlack from '../../../assets/img/flavours/TableTop_GrandaddyBlack.svg'
// import CactusBreath from '../../../assets/img/flavours/TableTop_CactusBreath.svg'
// import DieselDough from '../../../assets/img/flavours/TableTop_DieselDough.svg'
// import DurbanLime from '../../../assets/img/flavours/TableTop_DurbanLime.svg'
import PrettyGreat28 from '../../../assets/img/flavours/TableTop_PrettyGreat28.svg'
import Yogurt from '../../../assets/img/flavours/TableTop_Yogurt.svg'
import HashBurger from '../../../assets/img/flavours/TableTop_HashBurger.svg'




//'Learn more' buttons
import BeigeButton from '../../../assets/img/button-beige.svg'
import BlackButton from '../../../assets/img/button-black.svg'
import GreenButton from '../../../assets/img/button-green.svg'
import PurpleButton from '../../../assets/img/button-purple.svg'
import YellowButton from '../../../assets/img/button-yellow.svg'
import BrownButton from '../../../assets/img/button-brown.svg'
import BlueLearnMoreButton from '../../../assets/img/button-blue-learn-more.svg' // needs weird name compared to others because there's a BlueButton already but it says "VERIFY" instead of "LEARN MORE"


//'Learn more' hover buttons (adam added)
import BeigeButtonHover from '../../../assets/img/hover_buttons/BeigeHover.svg'
import BlackButtonHover from '../../../assets/img/hover_buttons/BlackHover.svg'
import GreenButtonHover from '../../../assets/img/hover_buttons/GreenHover.svg'
import PurpleButtonHover from '../../../assets/img/hover_buttons/PurpleHover.svg'
import YellowButtonHover from '../../../assets/img/hover_buttons/YellowHover.svg'
import BrownButtonHover from '../../../assets/img/hover_buttons/BrownHover.svg'
import BlueLearnMoreButtonHover from '../../../assets/img/hover_buttons/BlueLearnMoreHover.svg' // needs weird name compared to others because there's a BlueButton already but it says "VERIFY" instead of "LEARN MORE"


//Each Strain Component


// import './PreRolls.css' // no need to import this because this shares CSS with Dried Flower
import MobileDriedFlower from './MobilePreRolls/MobilePreRolls';


const NewProductTickerTape = <div className={`NewProduct`}> <div className={`NewProduct__text  ticker-move-vertical`}> NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! </div> </div>


class Flavours extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------
    
    componentDidMount() {

        document.title = "TABLE TOP™ Pre-Rolls | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP™ cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        /*const imagesPreload = [BeigeButtonHover, BlackButtonHover, GreenButtonHover, PurpleButtonHover, YellowButtonHover, BrownButtonHover];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
            console.log('image: '+newImage);
        });*/

        document.body.classList.add('strains-page');
    }


    componentWillUnmount() {
        document.body.classList.remove('strains-page');
    }

    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------

    


    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <div> {/*className="FlavoursBackground"*/}
                    {/*<FlavoursBackground />*/}
                    {/*<Header />*/}
                    <div className='Flavours test2'>
                        <h1 id="HeaderText"><a href="/products">PRODUCTS</a> > PRE-ROLLS</h1>


                        <Link to='/products/hash-burger'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--hash-burger  HashBurgerCard'>
                                {/*{NewProductTickerTape}*/}
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={HashBurger} alt='HashBurger' style={{maxHeight: '50px'}} />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 3 x 0.5g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>


                        <Link to='/products/yogurt'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--yogurt  YogurtCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={Yogurt} alt='Yogurt' style={{maxHeight: '50px'}} />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 14g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>


                        <Link to='/products/pretty-great-28'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--purple  JustFineGrindIndicaCard'>
                                {NewProductTickerTape}
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={PrettyGreat28} alt='Pretty Great 28' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 28 x 0.5g</li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>


                        {/*<Link to='/products/diesel-dough'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--brown  DieselDoughCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--diesel-dough' src={DieselDough} alt='DieselDough' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/products/durban-lime'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--green  DurbanLimeCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--diesel-dough' src={DurbanLime} alt='DurbanLime' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 3 x 0.5g</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/products/blue-lemon-thai'>
                            <div className='BlueLemonThaiCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--blue-lemon-thai' src={BlueLemonThai} alt='BlueLemonThai' />
                                </div>
                                <div className='LearnMore'>
                                    <img  src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        <Link to='/products/sticky-buns'>
                            <div className='StickyBunsCard'>
                                <div className="BestSeller">
                                    <div className="BestSeller__text  ticker-move-vertical">BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER!
                                    </div>
                                </div>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--sticky-buns' src={StickyBuns} alt='StickyBuns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                            <li></li>
                                            <li></li>
                                            
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g </li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                    </div>

                </div>
            )
        }
        else { return <MobileDriedFlower /> }



    }
}

export default Flavours;