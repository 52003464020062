import React from 'react'

import { Link } from 'react-router-dom'

import FlavoursBackground from './Background/Background'
import Header from '../BasePage/Header/Header';

//Flavour text
import DiamondDustOG from '../../assets/img/flavours/TableTop_DiamondDustOG.svg'
import OGKush from '../../assets/img/flavours/TableTop_90sOGKush.svg'
import BlueLemonThai from '../../assets/img/flavours/TableTop_BlueLemonThai.svg'
// import AmherstSuperSkunk from '../../assets/img/flavours/TableTop_AmherstSuperSkunk.svg'
import SundaeDriver from '../../assets/img/flavours/TableTop_SundaeDriver.svg'
// import LakeTahoeOG from '../../assets/img/flavours/TableTop_LakeTahoeOG.svg'
import PurplePunch from '../../assets/img/flavours/TableTop_PurplePunch.svg'
// import SourKush from '../../assets/img/flavours/TableTop_SourKush.svg'
import StickyBuns from '../../assets/img/flavours/TableTop_StickyBuns.svg'
import GarlicBreath from '../../assets/img/flavours/TableTop_GarlicBreath.svg'
import GreaseMonkey from '../../assets/img/flavours/TableTop_GreaseMonkey.svg'
import GrilledCheese from '../../assets/img/flavours/TableTop_GrilledCheese.svg'
import GrandaddyBlack from '../../assets/img/flavours/TableTop_GrandaddyBlack.svg'
// import CactusBreath from '../../assets/img/flavours/TableTop_CactusBreath.svg'


//'Learn more' buttons
import BeigeButton from '../../assets/img/button-beige.svg'
import BlackButton from '../../assets/img/button-black.svg'
import GreenButton from '../../assets/img/button-green.svg'
import PurpleButton from '../../assets/img/button-purple.svg'
import YellowButton from '../../assets/img/button-yellow.svg'
import BrownButton from '../../assets/img/button-brown.svg'


//'Learn more' hover buttons (adam added)
import BeigeButtonHover from '../../assets/img/hover_buttons/BeigeHover.svg'
import BlackButtonHover from '../../assets/img/hover_buttons/BlackHover.svg'
import GreenButtonHover from '../../assets/img/hover_buttons/GreenHover.svg'
import PurpleButtonHover from '../../assets/img/hover_buttons/PurpleHover.svg'
import YellowButtonHover from '../../assets/img/hover_buttons/YellowHover.svg'
import BrownButtonHover from '../../assets/img/hover_buttons/BrownHover.svg'


//Each Strain Component


// import './Flavours.css'
import MobileFlavours from './MobileFlavours/MobileFlavours';

class Flavours extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------
    
    componentDidMount() {

        document.title = "Weed Strains by TABLE TOP | Canadian Cannabis Brand";

        // add/update the meta description
        let tab_page_description     = `Browse weed strains by TABLE TOP – the Canadian cannabis brand for folks who like satisfactory options and medium vibes.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        /*const imagesPreload = [BeigeButtonHover, BlackButtonHover, GreenButtonHover, PurpleButtonHover, YellowButtonHover, BrownButtonHover];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
            console.log('image: '+newImage);
        });*/

        document.body.classList.add('strains-page');
    }


    componentWillUnmount() {
        document.body.classList.remove('strains-page');
    }

    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------

    


    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <div className="FlavoursBackground">
                    <FlavoursBackground />
                    <Header />
                    <div className='Flavours test2'>
                        <Link to='/diamonddustog'>
                            <div className='DiamondDustOGCard'>
                                <div className='StrainTitle'>
                                    <img src={DiamondDustOG} alt='Diamond Dust OG' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/ogkush'>
                            <div className='OGKushCard'>
                                <div className='StrainTitle'>
                                    <img src={OGKush} alt='OG Kush' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Vape</li>
                                            <li>- 0.5g</li>
                                            <li>- 1g </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        {/*<Link to='/sourkush'>
                            <div className='SourKushCard'>
                                <div className='StrainTitle'>
                                    <img src={SourKush} alt='Sour Kush' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Vape</li>
                                            <li>- 0.5g</li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/laketahoeog'>
                            <div className='LakeTahoeOGCard'>
                                <div className='StrainTitle'>
                                    <img src={LakeTahoeOG} alt='LakeTahoeOG' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={GreenButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = GreenButtonHover} onMouseOut={e => e.currentTarget.src = GreenButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Vape</li>
                                            <li>- 0.5g</li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'> </li>
                                            <li> </li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        <Link to='/bluelemonthai'>
                            <div className='BlueLemonThaiCard'>
                                <div className='StrainTitle'>
                                    <img src={BlueLemonThai} alt='BlueLemonThai' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/purplepunch'>
                            <div className='PurplePunchCard'>
                                <div className='StrainTitle'>
                                    <img src={PurplePunch} alt='PurplePunch' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g </li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/stickybuns'>
                            <div className='StickyBunsCard'>
                                <div className='StrainTitle'>
                                    <img src={StickyBuns} alt='StickyBuns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g </li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        {/*<Link to='/cactusbreath'>
                            <div className='CactusBreathCard'>
                                <div className='StrainTitle'>
                                    <img src={CactusBreath} alt='StickyBuns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        <Link to='/garlicbreath'>
                            <div className='GarlicBreathCard'>
                                <div className='StrainTitle'>
                                    <img src={GarlicBreath} alt='StickyBuns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/greasemonkey'>
                            <div className='GreaseMonkeyCard'>
                                <div className='StrainTitle'>
                                    <img src={GreaseMonkey} alt='StickyBuns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={GreenButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = GreenButtonHover} onMouseOut={e => e.currentTarget.src = GreenButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/grilledcheese'>
                            <div className='GrilledCheeseCard'>
                                <div className='StrainTitle'>
                                    <img src={GrilledCheese} alt='StickyBuns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        {/*<Link to='/grandaddyblack'>
                            <div className='GrandaddyBlackCard'>
                                <div className='StrainTitle'>
                                    <img src={GrandaddyBlack} alt='StickyBuns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BrownButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BrownButtonHover} onMouseOut={e => e.currentTarget.src = BrownButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/amherstsuperskunk'>
                            <div className='AmherstSuperSkunkCard'>
                                <div className='StrainTitle'>
                                    <img src={AmherstSuperSkunk} alt='AmherstSuperSkunk' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        <Link to='/sundaedriver'>
                            <div className='SundaeDriverCard'>
                                <div className='StrainTitle'>
                                    <img src={SundaeDriver} alt='SundaeDriver' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className='ConstantTermsAndConditions  ConstantTermsAndConditions--StrainsPage' /*style={{top: '190vh'}}*/>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>

                </div>
            )
        }
        else { return <MobileFlavours /> }



    }
}

export default Flavours;