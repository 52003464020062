import React from 'react'

class ViewerCounter extends React.Component {
    constructor(props) {
        super(props)
        this.state = { visitors: 'Loading...' };
    }

    /*componentDidMount() {
        fetch("https://api.countapi.xyz/hit/tabletop.com/visits").then(
            res => res.json()).then((result) => {
                let visitorCount = result.value.toString(); 
                let numZero = 9 - visitorCount.length
                for (let i = 0; i < numZero; i++) { //Add zero's to string, to make visitor counter number 9 digits
                   visitorCount = '0' + visitorCount;
                }
                let finalVisitorCount = visitorCount.slice(0, 3) + ',' + visitorCount.slice(3, 6) + ',' + visitorCount.slice(6) //Add commas between digits
                this.setState({
                    visitors: finalVisitorCount
                })
            })
    }*/

    render() {
        return (
            <div>
                <div>
                    {/* Maybe change the color of the number to white, similar to the mockup? */}
                    {this.state.visitors}
                </div>
                VISITORS
            </div>
        )
    }
}

export default ViewerCounter;