import React from 'react'

import { Link } from 'react-router-dom';


import './FlavoursNavBar.css'

//Flavour text



class FlavoursNavBar extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------
    
    componentDidMount() {
        document.body.classList.add('individual-strain-page');

        


    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page');
        document.body.classList.remove('individual-strain-page--flower-only');
        document.body.classList.remove('individual-strain-page--pre-rolls-only');
        document.body.classList.remove('individual-strain-page--flower-and-pre-rolls');
    }


    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------


    render() {

        return (
            <div className='FlavoursNavBar'>

                <div className='FlavoursNavBarInner  FlavoursNavBarInner--flower-and-pre-rolls'>

                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        <div className='NavBarFlavour'>
                            <Link to='/products/hash-burger'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--hash-burger  HashBurger'>
                                    HASH BURGER
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/city-slicker'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--city-slicker  CitySlicker'>
                                    CITY SLICKER
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/fuzzy-hammer'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--fuzzy-hammer  FuzzyHammer'>
                                    FUZZY HAMMER
                                </div>
                            </Link>
                        </div>

                        

                    </div>
                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        <div className='NavBarFlavour'>
                            <Link to='/products/yogurt'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--yogurt  Yogurt'>
                                    YOGURT
                                </div>
                            </Link>
                        </div>

                        {/*<div className='NavBarFlavour'>
                            <Link to='/products/80s-kiwi-kush'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--purple  KiwiKush'>
                                    80s KIWI KUSH
                                </div>
                            </Link>
                        </div>*/}

                        <div className='NavBarFlavour'>
                            <Link to='/products/san-fran-sour-dough'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--beige-darker  SanFranSourDough'>
                                    SAN FRAN SOUR DOUGH
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/allen-wrench'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--beige  AllenWrench'>
                                    ALLEN WRENCH
                                </div>
                            </Link>
                        </div>

                        

                    </div>
                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>


                        <div className='NavBarFlavour'>
                            <Link to='/products/pretty-great-28'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--purple  PrettyGreat28' style={{ color: '#000000'}}>
                                    PRETTY GREAT 28
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/sticky-buns'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--yellow  StickyBuns'>
                                    STICKY BUNS
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/purple-punch'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--black  PurplePunch'>
                                    PURPLE PUNCH
                                </div>
                            </Link>
                        </div>

                        {/*<div className='NavBarFlavour'>
                            <Link to='/products/just-fine-grind-bold-sativa'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--purple  JustFineGrindBoldSativa' style={{ color: 'var(--blue)'}}>
                                    JUST FINE GRIND BOLD SATIVA
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/just-fine-grind-bold-indica'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--green  JustFineGrindBoldIndica' style={{ color: 'var(--purple)'}}>
                                    JUST FINE GRIND BOLD INDICA
                                </div>
                            </Link>
                        </div>*/}

                        

                        

                    </div>
                    

                    {/*

                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        <div className='NavBarFlavour'>
                            <Link to='/products/square-dance'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--green  SquareDance' style={{ color: 'var(--purple)'}}>
                                    SQUARE DANCE
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/just-fine-grind-sativa'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--brown  JustFineGrindSativa' style={{ color: '#d2cdb3'}}>
                                    JUST FINE GRIND SATIVA
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/just-fine-grind-indica'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--yellow  JustFineGrindIndica' style={{ color: 'var(--brown)'}}>
                                    JUST FINE GRIND INDICA
                                </div>
                            </Link>
                        </div>

                        

                    </div>
                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        

                        <div className='NavBarFlavour'>
                            <Link to='/products/diesel-dough'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--brown  DieselDough'>
                                    DIESEL DOUGH
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/grape-diamonds'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--blue  GrapeDiamonds'>
                                    GRAPE DIAMONDS
                                </div>
                            </Link>
                        </div>

                        

                    </div>
                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        <div className='NavBarFlavour'>
                            <Link to='/products/moontang'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--brown  Moontang'>
                                    MOONTANG
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/durban-lime'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--green  DurbanLime'>
                                    DURBAN LIME
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/lemon-diesel'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--yellow  LemonDiesel'>
                                    LEMON DIESEL
                                </div>
                            </Link>
                        </div>

                        

                    </div>
                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        <div className='NavBarFlavour'>
                            <Link to='/products/blue-lemon-thai'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--green  BlueLemonThai'>
                                    BLUE LEMON THAI
                                </div>
                            </Link>
                        </div>

                        

                        <div className='NavBarFlavour'>
                            <Link to='/products/grilled-cheese'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--brown  GrilledCheese'>
                                    GRILLED CHEESE
                                </div>
                            </Link>
                        </div>

                        

                    </div>
                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        <div className='NavBarFlavour'>
                            <Link to='/products/zombie-kush'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--black  ZombieKush'>
                                    ZOMBIE KUSH
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/banjo'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--yellow  Banjo'>
                                    BANJO
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>
                            <Link to='/products/race-fuel-og'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--black  RaceFuelOG'>
                                    RACE FUEL OG
                                </div>
                            </Link>
                        </div>

                    </div>
                    <div className='NavBarFlavourRow' style={{ paddingBottom: '15px' }}>

                        

                        <div className='NavBarFlavour'>
                            <Link to='/products/garlic-breath'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--blue  GarlicBreath'>
                                    GARLIC BREATH
                                </div>
                            </Link>
                        </div>
                        
                    
                        <div className='NavBarFlavour'>
                            <Link to='/products/grease-monkey'>
                                <div className='NavBarFlavour__text  NavBarFlavour__text--yellow  GreaseMonkey'>
                                    GREASE MONKEY
                                </div>
                            </Link>
                        </div>

                        <div className='NavBarFlavour'>

                        </div>
                    </div>

                    */}

                </div> {/* /FlavoursNavBarInner--flower-and-pre-rolls */}



                
 

            </div>





        )
    }

}

export default FlavoursNavBar;