import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import SanFranSourDoughPicture from '../../../../assets/img/flavours/TableTop_SanFranSourDough.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './SanFranSourDough.css'

const ThisDescription = `Why waste time making complicated bread when you can just buy it from a pro? No messy weird dough. Just yummy bread in your tummy. Anyway, this TABLE TOP San Fran Sour Dough is pretty much ready to go. You can’t toast it or make sandwiches but it’s indica-dominant with high THC and tastes gassy and earthy with notes of citrus and pine. We’ll take a loaf of that any day`;

const ThisSlug = 'san-fran-sour-dough';


class SanFranSourDough extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ San Fran Sour Dough Dried Flower | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Indica-dominant San Fran Sour Dough dried flower from TABLE TOP. Find it at your local cannabis shop.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        document.body.classList.add('individual-strain-page--'+ThisSlug);

    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page--'+ThisSlug);
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='SanFranSourDoughPage'>

                        <div className='SanFranSourDoughContent'>
                            <h1 className='ProductTitle'><img src={SanFranSourDoughPicture} alt='TABLE TOP™ San Fran Sour Dough' /></h1>
                            <div className='SanFranSourDoughText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Phantom Cookies
                                        </th>
                                        <th>
                                            Indica Dominant
                                        </th>
                                        <th>
                                            Citrus
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Sour Gelato
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Gassy
                                        </th>
                                        <th>
                                            28g {/* product size 1 */}
                                        </th>
                                        <th>
                                            18-23% {/* thc % for product size 1 */}
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Elite Chemdawg {/* lineage 3 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Earthy
                                        </th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            Farnesene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            Pine
                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>

                                        </th>
                                    </tr><tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='SanFranSourDoughPage'>
                        <div className='SanFranSourDoughContent'>

                            <h1 className='ProductTitle'><img src={SanFranSourDoughPicture} alt='TABLE TOP™ San Fran Sour Dough' /></h1>
                            <div className='SanFranSourDoughText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Phantom Cookies</th>
                                        <th>Citrus</th>
                                    </tr>
                                    <tr>
                                        <th>Sour Gelato</th>
                                        <th>Gassy</th>
                                    </tr>
                                    <tr>
                                        <th>Elite Chemdawg</th>
                                        <th>Earthy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Pine</th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Indica Dominant</th>
                                        <th></th>
                                    </tr>
                                    
                                    <br></br>
                                    
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>18-23%</th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Farnesene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>

                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default SanFranSourDough;