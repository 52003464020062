import React from 'react'

import queryString from 'query-string' // adam added

import Lottie from 'react-lottie' // adam added
import animationData420 from '../../../assets/lotties/420.json'; // adam added

import './MobileSideBar.css'
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from 'react-router-dom';

import Home from '../../Home/Home'
import About from '../../About/About'
import Products from '../../Products/Products'
import Flavours from '../../Flavours/Flavours'
import Buy from '../../Buy/Buy'
import NotFound from '../NotFound/NotFound'

import TableTopLogoText from '../../../assets/img/tabletop-logo-text.svg'

import DiamondDustOG from '../../Flavours/FlavourComponents/DiamondDustOG/DiamondDustOG'
import OGKush from '../../Flavours/FlavourComponents/OGKush/OGKush'
import SourKush from '../../Flavours/FlavourComponents/SourKush/SourKush'
import LakeTahoeOG from '../../Flavours/FlavourComponents/LakeTahoeOG/LakeTahoeOG'
// import BlueLemonThai from '../../Flavours/FlavourComponents/BlueLemonThai/BlueLemonThai'
import AmherstSuperSkunk from '../../Flavours/FlavourComponents/AmherstSuperSkunk/AmherstSuperSkunk'
import SundaeDriver from '../../Flavours/FlavourComponents/SundaeDriver/SundaeDriver'
import PurplePunch from '../../Flavours/FlavourComponents/PurplePunch/PurplePunch'
import StickyBuns from '../../Flavours/FlavourComponents/StickyBuns/StickyBuns'
// import GarlicBreath from '../../Flavours/FlavourComponents/GarlicBreath/GarlicBreath'
// import GrandaddyBlack from '../../Flavours/FlavourComponents/GrandaddyBlack/GrandaddyBlack';
// import GrilledCheese from '../../Flavours/FlavourComponents/GrilledCheese/GrilledCheese'
// import GreaseMonkey from '../../Flavours/FlavourComponents/GreaseMonkey/GreaseMonkey'
import CactusBreath from '../../Flavours/FlavourComponents/CactusBreath/CactusBreath'
// import DieselDough from '../../Flavours/FlavourComponents/DieselDough/DieselDough'
// import GrapeDiamonds from '../../Flavours/FlavourComponents/GrapeDiamonds/GrapeDiamonds'
// import Moontang from '../../Flavours/FlavourComponents/Moontang/Moontang'
// import DurbanLime from '../../Flavours/FlavourComponents/DurbanLime/DurbanLime'
// import LemonDiesel from '../../Flavours/FlavourComponents/LemonDiesel/LemonDiesel'
// import ZombieKush from '../../Flavours/FlavourComponents/ZombieKush/ZombieKush'
// import RaceFuelOG from '../../Flavours/FlavourComponents/RaceFuelOG/RaceFuelOG'
// import Banjo from '../../Flavours/FlavourComponents/Banjo/Banjo'
import JungleCake from '../../Flavours/FlavourComponents/JungleCake/JungleCake'

import SanFranSourDough from '../../Flavours/FlavourComponents/SanFranSourDough/SanFranSourDough'
// import JustFineGrindBoldSativa from '../../Flavours/FlavourComponents/JustFineGrindBoldSativa/JustFineGrindBoldSativa'
// import JustFineGrindBoldIndica from '../../Flavours/FlavourComponents/JustFineGrindBoldIndica/JustFineGrindBoldIndica'
import AllenWrench from '../../Flavours/FlavourComponents/AllenWrench/AllenWrench'
// import SquareDance from '../../Flavours/FlavourComponents/SquareDance/SquareDance'
// import JustFineGrindSativa from '../../Flavours/FlavourComponents/JustFineGrindSativa/JustFineGrindSativa'
// import JustFineGrindIndica from '../../Flavours/FlavourComponents/JustFineGrindIndica/JustFineGrindIndica'
import PrettyGreat28 from '../../Flavours/FlavourComponents/PrettyGreat28/PrettyGreat28'
// import KiwiKush from '../../Flavours/FlavourComponents/KiwiKush/KiwiKush'

import CitySlicker from '../../Flavours/FlavourComponents/CitySlicker/CitySlicker'
import FuzzyHammer from '../../Flavours/FlavourComponents/FuzzyHammer/FuzzyHammer'
import Yogurt from '../../Flavours/FlavourComponents/Yogurt/Yogurt'
import HashBurger from '../../Flavours/FlavourComponents/HashBurger/HashBurger'


import DriedFlower from '../../Products/DriedFlower/DriedFlower'
import PreRolls from '../../Products/PreRolls/PreRolls'
// import Vape from '../../Products/Vape/Vape'

import Guestbook from '../../Guestbook/Guestbook'

import Newsletter from '../../Newsletter/Newsletter';

// adam added - LiveChat
import LiveChat from 'react-livechat'

const routes = [
    {
        path: "/",
        exact: true,
        sidebar: () => null,
        main: () => < Home />
    },
    {
        path: "/about",
        sidebar: () => null,
        main: () => <About />
    },
    // keep the /products/type/subpages ABOVE the main /products/type page here
    {
        path: '/products/purple-punch',
        sidebar: () => null,
        main: () => <PurplePunch />
    },
    {
        path: '/products/sticky-buns',
        sidebar: () => null,
        main: () => <StickyBuns />
    },
    /*{
        path: '/products/garlic-breath',
        sidebar: () => null,
        main: () => <GarlicBreath />
    },*/
    /*{
        path: '/products/grilled-cheese',
        sidebar: () => null,
        main: () => <GrilledCheese />
    },*/
    /*{
        path: '/products/grape-diamonds',
        sidebar: () => null,
        main: () => <GrapeDiamonds />
    },
    {
        path: '/products/moontang',
        sidebar: () => null,
        main: () => <Moontang />
    },
    {
        path: '/products/durban-lime',
        sidebar: () => null,
        main: () => <DurbanLime />
    },
    {
        path: '/products/lemon-diesel',
        sidebar: () => null,
        main: () => <LemonDiesel />
    },
    {
        path: '/products/zombie-kush',
        sidebar: () => null,
        main: () => <ZombieKush />
    },
    {
        path: '/products/race-fuel-og',
        sidebar: () => null,
        main: () => <RaceFuelOG />
    },
    {
        path: '/products/banjo',
        sidebar: () => null,
        main: () => <Banjo />
    },*/
    {
        path: '/products/jungle-cake',
        sidebar: () => null,
        main: () => <JungleCake />
    },
    /*{
        path: '/products/diesel-dough',
        sidebar: () => null,
        main: () => <DieselDough />
    },*/
    {
        path: '/products/diamond-dust-og',
        sidebar: () => null,
        main: () => <DiamondDustOG />
    },
    {
        path: '/products/og-kush',
        sidebar: () => null,
        main: () => <OGKush />
    },
    {
        path: '/products/sour-kush',
        sidebar: () => null,
        main: () => <SourKush />
    },
    {
        path: '/products/lake-tahoe-og',
        sidebar: () => null,
        main: () => <LakeTahoeOG />
    },
    /*{
        path: '/products/blue-lemon-thai',
        sidebar: () => null,
        main: () => <BlueLemonThai />
    },*/
    {
        path: '/products/amherst-super-skunk',
        sidebar: () => null,
        main: () => <AmherstSuperSkunk />
    },
    {
        path: '/products/sundae-driver',
        sidebar: () => null,
        main: () => <SundaeDriver />
    },
    {
        path: '/products/purple-punch',
        sidebar: () => null,
        main: () => <PurplePunch />
    },
    {
        path: '/products/sticky-buns',
        sidebar: () => null,
        main: () => <StickyBuns />
    },
    /*{
        path: '/products/grease-monkey',
        sidebar: () => null,
        main: () => <GreaseMonkey />
    },*/
    {
        path: '/products/cactus-breath',
        sidebar: () => null,
        main: () => <CactusBreath />
    },
    {
        path: '/products/san-fran-sour-dough',
        sidebar: () => null,
        main: () => <SanFranSourDough />
    },
    /*{
        path: '/products/just-fine-grind-bold-sativa',
        sidebar: () => null,
        main: () => <JustFineGrindBoldSativa />
    },
    {
        path: '/products/just-fine-grind-bold-indica',
        sidebar: () => null,
        main: () => <JustFineGrindBoldIndica />
    },*/
    {
        path: '/products/allen-wrench',
        sidebar: () => null,
        main: () => <AllenWrench />
    },
    /*{
        path: '/products/square-dance',
        sidebar: () => null,
        main: () => <SquareDance />
    },
    {
        path: '/products/just-fine-grind-sativa',
        sidebar: () => null,
        main: () => <JustFineGrindSativa />
    },
    
    {
        path: '/products/just-fine-grind-indica',
        sidebar: () => null,
        main: () => <JustFineGrindIndica />
    },*/
    {
        path: '/products/pretty-great-28',
        sidebar: () => null,
        main: () => <PrettyGreat28 />
    },
    /*{
        path: '/products/80s-kiwi-kush',
        sidebar: () => null,
        main: () => <KiwiKush />
    },*/
    {
        path: '/products/city-slicker',
        sidebar: () => null,
        main: () => <CitySlicker />
    },
    {
        path: '/products/fuzzy-hammer',
        sidebar: () => null,
        main: () => <FuzzyHammer />
    },
    {
        path: '/products/yogurt',
        sidebar: () => null,
        main: () => <Yogurt />
    },
    {
        path: '/products/hash-burger',
        sidebar: () => null,
        main: () => <HashBurger />
    },
    // keep the /products/type pages ABOVE the main /products page here
    {
        path: "/products/dried-flower",
        sidebar: () => null,
        main: () => <DriedFlower />
    },
    {
        path: "/products/pre-rolls",
        sidebar: () => null,
        main: () => <PreRolls />
    },
    {
        path: "/products",
        sidebar: () => null,
        main: () => <Products />
    },
    {
        path: "/buy",
        sidebar: () => null,
        main: () => <Buy />
    },
    {
        path: '/guestbook',
        sidebar: () => null,
        main: () => <Guestbook />
    },
    {
        path: '/newsletter',
        sidebar: () => null,
        main: () => <Newsletter />
    },
];


// -----------------------------------------------------------------------------
// adam added
// -----------------------------------------------------------------------------

const defaultOptions420 = {
    loop: true,
    autoplay: true,
    animationData: animationData420,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

let disable_lottie_until_refresh = false;

let is_420 = false;

// get url parameters, show the 420 alarm if ?420=true
const qs = require('query-string');
console.log('ls',window.location.search);
const search = window.location.search;
const params = new URLSearchParams(search);
if ( params.get('show_420_and_bg_opacity') )
    is_420 = true;

// if ( params.get('bg_opacity') == '100%' )

// console.log('height ', window.innerHeight);


// -----------------------------------------------------------------------------
// end adam added
// -----------------------------------------------------------------------------


class MobileSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.setState({ show: false });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggleShow = () => {
        this.setState({ show: !this.state.show });
    }

    /**
    * Set the wrapper ref
    */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
 * Alert if clicked on outside of element
 */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({show: false})
        }
    }

    render() {
        if (this.state.show === true) {
            return (
                <Router>

                    {/* 420 alarm */}
                    <div className='Lottie420' id='Lottie420' style={{ display: 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: `rgba( 0, 0, 0, ${params.get('show_420_and_bg_opacity')} )`, pointerEvents: 'none', zIndex: 100 }}>
                        <Lottie isClickToPauseDisabled={true} options={defaultOptions420} />
                    </div>


                    {/* This  div contains the mobile sidebar front end JSX */}
                    <div className='MobileSideBar'>
                        <Link to='/'>
                            <div className='TableTopLogoMain'>
                                <img src={TableTopLogoText} alt='TableTop Logo' />
                            </div>
                        </Link>
                        <div className='HamburgerMenu' ref={this.setWrapperRef}>
                            <NavLink to='/'>
                                <button className='HamburgerMenuLink'>
                                    Home
                                </button>
                            </NavLink>
                            <NavLink to='/about'>
                                <button className='HamburgerMenuLink' onClick={() => this.toggleShow()}>
                                    About
                                </button>
                            </NavLink>
                            <NavLink to='/products'>
                                <button className='HamburgerMenuLink' onClick={() => this.toggleShow()}>
                                    Products
                                </button>
                            </NavLink>
                            <NavLink to='/buy'>
                                <button className='HamburgerMenuLink' onClick={() => this.toggleShow()}>
                                    Buy
                                </button>
                            </NavLink>
                            {/*<a href="#" data-newsletter-modal-trigger>
                                <button class="HamburgerMenuLink">Newsletter</button>
                            </a>*/}
                            <button className='HamburgerMenuLink' onClick={() => this.toggleShow()}>
                                Close Menu
                            </button>
                        </div>
                    </div>
                    {/* This  div defines the routes for the application*/}
                    <div style={{ flex: 1 }}>
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={<route.main />}
                                />
                            ))}
                            <Route path='*'>
                                <NotFound />
                            </Route>
                        </Switch>
                    </div>

                    <LiveChat license={13454061} />

                </Router>
            )
        }
        else if (this.state.show === false) {
            return (
                <Router>

                    {/* 420 alarm */}
                    <div className='Lottie420' id='Lottie420' style={{ display: 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: `rgba( 0, 0, 0, ${params.get('show_420_and_bg_opacity')} )`, pointerEvents: 'none', zIndex: 100 }}>
                        <Lottie isClickToPauseDisabled={true} options={defaultOptions420} />
                    </div>


                    {/* This  div contains the mobile sidebar front end JSX */}
                    <div className='MobileSideBar'>
                        <Link to='/'>
                            <div className='TableTopLogoMain'>
                                <img src={TableTopLogoText} alt='TableTop Logo' />
                            </div>
                        </Link>
                        <div className='HamburgerMenu' style={{ height: '75px' }}>
                            <button className='HamburgerMenuLink' onClick={() => this.toggleShow()}>
                                Menu
                            </button>
                        </div>
                    </div>
                    {/* This  div defines the routes for the application*/}
                    <div style={{ flex: 1 }}>
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={<route.main />}
                                />
                            ))}
                            <Route path='*'>
                                <NotFound />
                            </Route>
                        </Switch>
                    </div>

                    <LiveChat license={13454061} />

                </Router>
            )
        }

    }
}

export default MobileSideBar;