import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import LakeTahoeOGPicture from '../../../../assets/img/flavours/TableTop_LakeTahoeOG.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './LakeTahoeOG.css'
class LakeTahoeOG extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "Lake Tahoe OG Vape | TABLE TOP";

        // add/update the meta description
        let tab_page_description     = `Lake Tahoe OG is an indica vape from TABLE TOP with pine, citrus and earthy flavours that takes you back to simpler times when we didn’t have to do taxes.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='LakeTahoeOGPage'>
                        <div className='LakeTahoeOGContent'>
                            <h1 className='ProductTitle'><img src={LakeTahoeOGPicture} alt='TABLE TOP™ Lake Tahoe OG' /></h1>
                            <div className='LakeTahoeOGText'>
                                Take a sprig of pine. Add a spritz of citrus. Throw in some earthy vibes. Stir it up and you get this reliable indica right here. Takes you back to simpler times, you know? When we didn’t have to do taxes or pretend to like coconut water.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Chemdawg
                                        </th>
                                        <th>
                                            Indica Dominant
                                        </th>
                                        <th>
                                            Pine
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Lemon Thai
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Citrus
                                        </th>
                                        <th>
                                            0.5g
                                        </th>
                                        <th>
                                            81%
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            80s Tahoe OG
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Earthy
                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Myrcene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='ConstantTermsAndConditions' style={{top: '120vh'}}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='LakeTahoeOGPage'>
                        <div className='LakeTahoeOGContent'>
                            <h1 className='ProductTitle'><img src={LakeTahoeOGPicture} alt='TABLE TOP™ Lake Tahoe OG' /></h1>
                            <div className='LakeTahoeOGText'>
                            Take a sprig of pine. Add a spritz of citrus. Throw in some earthy vibes. Stir it up and you get this reliable indica right here. Takes you back to simpler times, you know? When we didn’t have to do taxes or pretend to like coconut water.                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Chemdawg</th>
                                        <th>Pine</th>
                                    </tr>
                                    <tr>
                                        <th>Lemon Thai</th>
                                        <th>Citrus</th>
                                    </tr>
                                    <tr>
                                        <th>80s Tahoe OG</th>
                                        <th>Earthy</th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Indica Dominant</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Vape</th>
                                        <th id='FeatureSubHeaders'>Vape</th>
                                    </tr>
                                    <tr>
                                        <th>0.5g</th>
                                        <th>81%</th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Vape</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Myrcene</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )

        }

    }
}

export default LakeTahoeOG;