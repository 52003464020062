import React from 'react'

import TableTopLogoText from '../../../../assets/img/tabletop-logo-text.svg'

class TooYoung extends React.Component {
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#231F20', color: '#d2cdb3'}}>
                <img src={TableTopLogoText} alt='Table Top Logo' style={{height: 'auto', width: '20%', textAlign: 'center'}}/>
                <p style={{fontSize: '1.5em'}}>Sorry, kid! You must be an adult to sit at this table.</p>
                <a href='/' style={{color: '#fff'}}>Try Again</a>
            </div>
        )
    }
}

export default TooYoung;