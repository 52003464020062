import React from 'react'

import './Background.css'

class OGKushBackground extends React.Component {
    render() {
        return (
            <div className='SundaeDriverBackground'>
            </div>
        )
    }
}

export default OGKushBackground; 