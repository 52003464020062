/* eslint-disable */
import React from 'react'

import NewsletterBackground from '../Background/Background'
import Header from '../../BasePage/Header/Header'
import MobileFooter from '../../BasePage/MobileSideBar/MobileFooter/MobileFooter'

import GoButton from '../../../assets/img/hover_buttons/Go.svg'
import GoButtonHover from '../../../assets/img/hover_buttons/GoHover.svg'

import $ from 'jquery';

import './MobileNewsletter.css'


class MobileNewsletter extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------

    handleClick = () => {
        window.location.href='mailto:hello@bzam.com?subject=Please%20find%20me%20a%20local%20shop%20that%20carries%20TABLE%20TOP!';
    }


    handleProvinceDropdownChange = () => {
        // if we've just changed provinces, then always hide that BC popup, because it might no longer be relevant
        document.getElementById('js-find-in-bc-infobox').classList.remove('is-visible');
    }



    openProvincePage = () => {

        // get the selected item from the dropdown
        var selectedValue = document.getElementById('Province').value;

        // Participating Retailers selected - smooth-scroll to Stockist section
        if( selectedValue === 'js-participating-retailers' )
            $("html, body").animate({ scrollTop: $('#stockist-widget').offset().top - 22 }, 800);

        // British Columbia selected - show that popup
        else if( selectedValue === 'js-find-in-bc' )
            document.getElementById('js-find-in-bc-infobox').classList.toggle('is-visible');

        // any other province selected - go to that province page in new tab
        else
            window.open(selectedValue, '_blank');

        // don't follow the link
        event.preventDefault();
    }

    
    
    componentDidMount() {
        // document.body.classList.add('newsletter-page');


        /*
        // Toggle element visibility
        var toggleVisibility = function (elem) {
            elem.classList.toggle('is-visible');
        };

        // "British Columbia" clicked on - show the extra popup / infobox
        document.addEventListener('click', function (event) {

            // If the clicked element doesn't have the right selector, bail
            if (!event.target.matches('.js-find-in-bc-mobile')) return;

            // alert('yest');

            // Don't follow the link
            event.preventDefault();

            // toggle the popup
            toggleVisibility( document.getElementById('js-find-in-bc-infobox-mobile') );

            // Log the clicked element in the console
            // console.log(event.target);

        }, false);


        function EmailForBC () {
            console.log('test');

            // window.location.href='mailto:hello@bzam.com?subject=Please%20find%20me%20a%20local%20shop%20that%20carries%20TABLE%20TOP!'
            
        }
        */


    }


    componentWillUnmount() {
        // document.body.classList.remove('newsletter-page');
    }

    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------



    render() {
        return (
            <div>
                <NewsletterBackground />
                <Header />
                <div className='MobileNewsletter'>
                    <div id='HeaderText'>
                        <span>WHERE</span><span>TO</span><span>BUY</span><br/><span>TABLE</span><span>TOP</span>
                    </div>
                    <div className='MobileNewsletterContent'>
                        <div className='MobileNewsletterText'>
                            
                            {/*<p>Choose your province from the list below.</p>*/}

                            <p>Looking for TABLE TOP cannabis near you? Nice!!! Our products are available at select retailers throughout Canada. Check your provincial online store or find participating retailers near you below.</p>

                            <div className='ProvinceWrapper'>
                                <select id="Province" onChange={() => this.handleProvinceDropdownChange()}>
                                    <option value="js-participating-retailers">Participating Retailers</option>
                                    <option value="https://bit.ly/3vaTc5B">Alberta</option>
                                    <option value="js-find-in-bc">British Columbia</option>
                                    <option value="https://bit.ly/2RRF42N">Manitoba</option>
                                    <option value="https://bit.ly/3gtCj1R">New Brunswick</option>
                                    <option value="https://bit.ly/32KlKGN">NFL &amp; Lab</option>
                                    <option value="https://bit.ly/3n9x9tp">Ontario</option>
                                    <option value="https://bit.ly/3vffenF">Saskatchewan</option>
                                    {/*
                                    <option value="NT" data-target="unavailable">Northwest Territories Cannabis Store</option>
                                    <option value="NS" data-target="unavailable">Nova Scotia Cannabis Store</option>
                                    <option value="NU" data-target="unavailable">Nunavut Cannabis Store</option>
                                    <option value="PE" data-target="unavailable">Prince Edward Island Cannabis Store</option>
                                    <option value="QC" data-target="unavailable">Quebec Cannabis Store</option>
                                    <option value="YU" data-target="unavailable">Yukon Cannabis Store</option>
                                    */}
                                </select>
                            </div>

                            <img src={GoButton} class='GoButton' id='GoButton' alt='Go' onMouseOver={e => e.currentTarget.src = GoButtonHover} onMouseOut={e => e.currentTarget.src = GoButton} onClick={() => this.openProvincePage()} />

                            <span class="find-in-bc-infobox" id="js-find-in-bc-infobox" onClick={() => this.handleClick()}>To find TABLE TOP near you email hello@bzam.com</span>



                            <div className='NewsletterDivider'></div>




                            {/*
                            <div className='ProvinceList'>
                                <div className='LeftList'>
                                    <a href='https://albertacannabis.org/search?q=Table+top&f=in_stock%3Dtrue|false%26brand%3DTable%20Top' target="_blank" rel="noopener noreferrer">Alberta</a>
                                    <a href='#' class="js-find-in-bc-mobile" rel="noopener noreferrer">British Columbia<span class="find-in-bc-infobox" id="js-find-in-bc-infobox-mobile" onClick={() => this.handleClickMobile()}>To find TABLE TOP near you email hello@bzam.com</span></a>
                                    <a href='https://lgcamb.ca/cannabis/store-list/' target="_blank" rel="noopener noreferrer">Manitoba</a>
                                    <a href='https://www.cannabis-nb.com/search/?q=table' target="_blank" rel="noopener noreferrer">New Brunswick</a>
                                    <a href='https://shopcannabisnl.com/collections/table-top' target="_blank" rel="noopener noreferrer">Newfoundland and Labrador</a>
                                    <a id='Unavailable'>Northwest Territories</a>
                                    <a id='Unavailable'>Nova Scotia</a>
                                    <a id='Unavailable'>Nunavut</a>
                                    <a href='https://ocs.ca/collections/table-top' target="_blank" rel="noopener noreferrer">Ontario</a>
                                    <a id='Unavailable'>Prince Edward Island</a>
                                    <a id='Unavailable'>Quebec</a>
                                    <a href='https://www.slga.com/permits-and-licences/cannabis-permits/cannabis-retailing/cannabis-retailers-in-saskatchewan' target="_blank" rel="noopener noreferrer">Saskatchewan</a>
                                    <a id='Unavailable'>Yukon</a>
                                </div>
                            </div>
                            */}


                        </div>
                    </div>
                    <MobileFooter />
                </div>
            </div>
        )
    }
}

export default MobileNewsletter;