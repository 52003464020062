import React from 'react'

import GuestbookBackground from './Background/Background.js'
import Header from '../BasePage/Header/Header.js';
import { Link } from 'react-router-dom';

import GuestbookPicture from '../../assets/img/Guestbook.jpg'
import MobileFooter from '../BasePage/MobileSideBar/MobileFooter/MobileFooter.js';

import './Guestbook.css'

import $ from 'jquery';
// const jQuery = window.$


class Guestbook extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------
    
    componentDidMount() {

        document.title = "TABLE TOP™ Guestbook | Medium Vibes Only | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already


        /*$("#GuestbookiFrame").on( 'load', function() {
            // alert('yes');
            $("#GuestbookiFrame body").on( "click", function() {
                alert('yes');
            });
        });*/
        
        document.body.classList.add('guestbook-page');


        /*var head = $("#GuestbookiFrame").contents().find("head");
        var css = '<style type="text/css">' +
                  'table { display:none }; ' +
                  '</style>';
        $(head).append(css);*/

        // $('#GuestBook').css('display','none');
        /*$( 'body' ).click(function() {
          document.getElementById('Lottie420').style.display = 'none';
          disable_lottie_until_refresh = true;
        });*/
    }

    componentWillUnmount() {
        document.body.classList.remove('guestbook-page');
    }

    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------


    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <div> {/*className="GuestbookBackground">*/}
                    {/*<GuestbookBackground />*/}
                    {/*<Header />*/}
                    <div className='Guestbook'>
                        <h1 id='HeaderText'>
                            TABLE TOP GUESTBOOK
                        </h1>
                        <div className='GuestbookContent'>

                            <iframe id="GuestbookiFrame"
                                title="Our Guestbook"
                                width="100%"
                                height="1000"
                                src="https://users3.smartgb.com/g/g.php?a=s&i=g36-32346-83">
                            </iframe>

                        </div>
                    </div>
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<GuestbookBackground />*/}
                    {/*<Header />*/}
                    <div className='Guestbook'>
                        <h1 id='HeaderText'>
                            <span>TABLE</span><span>TOP</span><br/><span>GUESTBOOK</span>
                        </h1>
                        <div className='GuestbookContent'>
                            
                            <iframe id="GuestbookiFrame"
                                title="Our Guestbook"
                                width="100%"
                                height="1000"
                                src="https://users3.smartgb.com/g/g.php?a=s&i=g36-32346-83">
                            </iframe>

                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default Guestbook;