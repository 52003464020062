import React from 'react'

import { Link } from 'react-router-dom'

import FlavoursBackground from '..//Background/Background'
import Header from '../../../BasePage/Header/Header';
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter'

//Flavour text
import DiamondDustOG from '../../../../assets/img/flavours/TableTop_DiamondDustOG.svg'
import OGKush from '../../../../assets/img/flavours/TableTop_90sOGKush.svg'
// import BlueLemonThai from '../../../../assets/img/flavours/TableTop_BlueLemonThai.svg'
// import AmherstSuperSkunk from '../../../../assets/img/flavours/TableTop_AmherstSuperSkunk.svg'
import SundaeDriver from '../../../../assets/img/flavours/TableTop_SundaeDriver.svg'
// import LakeTahoeOG from '../../../../assets/img/flavours/TableTop_LakeTahoeOG.svg'
import PurplePunch from '../../../../assets/img/flavours/TableTop_PurplePunch.svg'
// import SourKush from '../../../../assets/img/flavours/TableTop_SourKush.svg'
import StickyBuns from '../../../../assets/img/flavours/TableTop_StickyBuns.svg'
import GarlicBreath from '../../../../assets/img/flavours/TableTop_GarlicBreath.svg'
import GreaseMonkey from '../../../../assets/img/flavours/TableTop_GreaseMonkey.svg'
import GrilledCheese from '../../../../assets/img/flavours/TableTop_GrilledCheese.svg'
import GrandaddyBlack from '../../../../assets/img/flavours/TableTop_GrandaddyBlack.svg'
// import CactusBreath from '../../../../assets/img/flavours/TableTop_CactusBreath.svg';
// import DieselDough from '../../../../assets/img/flavours/TableTop_DieselDough.svg'
// import DurbanLime from '../../../../assets/img/flavours/TableTop_DurbanLime.svg'
import PrettyGreat28 from '../../../../assets/img/flavours/TableTop_PrettyGreat28.svg'
import Yogurt from '../../../../assets/img/flavours/TableTop_Yogurt.svg'
import HashBurger from '../../../../assets/img/flavours/TableTop_HashBurger.svg'

//'Learn more' buttons
import BeigeButton from '../../../../assets/img/button-beige.svg'
import BlackButton from '../../../../assets/img/button-black.svg'
import GreenButton from '../../../../assets/img/button-green.svg'
import PurpleButton from '../../../../assets/img/button-purple.svg'
import YellowButton from '../../../../assets/img/button-yellow.svg'
import BrownButton from '../../../../assets/img/button-brown.svg'
import BlueLearnMoreButton from '../../../../assets/img/button-blue-learn-more.svg' // needs weird name compared to others because there's a BlueButton already but it says "VERIFY" instead of "LEARN MORE"

//Each Strain Component

import './MobilePreRolls.css'


const NewProductTickerTape = <div className={`NewProduct`}> <div className={`NewProduct__text  ticker-move-vertical`}> NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! </div> </div>


class MobileFlavours extends React.Component {
    render() {
        return (
            <div>
                <FlavoursBackground />
                <Header />
                <div className='MobileFlavours'>

                    <Link to='/products/hash-burger' className='MobileFlavourCard' style={{ backgroundColor: '#a85b28' }}>
                        {/*{NewProductTickerTape}*/}
                        <div className='MobileFlavourCardName'>
                            <img src={HashBurger} alt='HashBurger' style={{width: '156px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/yogurt' className='MobileFlavourCard' style={{ backgroundColor: '#1e80bf' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={Yogurt} alt='Yogurt' style={{width: '104px', paddingLeft: '47px', paddingRight: '47px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/pretty-great-28' className='MobileFlavourCard' style={{ backgroundColor: 'var(--purple)' }}>
                        {NewProductTickerTape}
                        <div className='MobileFlavourCardName'>
                            <img src={PrettyGreat28} alt='Pretty Great 28' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' />
                        </div>
                    </Link>

                    {/*<Link to='/products/diesel-dough' className='MobileFlavourCard' style={{ backgroundColor: 'var(--diesel-dough-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={DieselDough} alt='Diesel Dough' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' />
                        </div>
                    </Link>*/}

                    {/*<Link to='/products/durban-lime' className='MobileFlavourCard' style={{ backgroundColor: 'var(--durban-lime-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={DurbanLime} alt='Durban Lime' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' />
                        </div>
                    </Link>*/}

                    {/*<Link to='/products/blue-lemon-thai' className='MobileFlavourCard' style={{ backgroundColor: '#0D6158' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={BlueLemonThai} alt='Blue Lemon Thai' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>*/}
                    <Link to='/products/sticky-buns' className='MobileFlavourCard' style={{ backgroundColor: '#EEBC34' }}>
                        <div className="BestSeller">
                            <div className="BestSeller__text  ticker-move-vertical">BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER!
                            </div>
                        </div>
                        <div className='MobileFlavourCardName'>
                            <img src={StickyBuns} alt='Sticky Buns' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' />
                        </div>
                    </Link>
                    <MobileFooter />
                </div>

            </div>
        )
    }
}

export default MobileFlavours;