import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import HashBurgerPicture from '../../../../assets/img/flavours/TableTop_HashBurger.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './HashBurger.css'

const ThisSlug = 'hash-burger';

const NewProductTickerTape = <div className={`NewProduct`}> <div className={`NewProduct__text  ticker-move-vertical`}> NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! </div> </div>

class HashBurger extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Hash Burger Pre-Rolls | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Sativa-dominant Hash Burger pre-rolls from TABLE TOP. Find it at your local cannabis shop.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already
    
        document.body.classList.add('individual-strain-page--'+ThisSlug);
    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page--'+ThisSlug);
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='HashBurgerPage'>
                        <div className='HashBurgerContent'>
                            {/*{NewProductTickerTape}*/}
                            <h1 className='ProductTitle'><img src={HashBurgerPicture} alt='TABLE TOP™ Hash Burger' /></h1>
                            <div className='HashBurgerText'>
                                No rig? No prob! Get your solventless rosin in TABLE TOP Hash Burger infused pre-rolls. These little joints are BEEFY. They’re not between two buns with lettuce and tomato, but they’ll blow your tastebuds with meaty hash flavour and enough potency to make you say, “whoa, dude!”
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Han Solo Hashplant {/* lineage 1 */}
                                        </th>
                                        <th>
                                            Hybrid {/* plant type */}
                                        </th>
                                        <th>
                                            Hash {/* flavour 1 */}
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Pre-Rolls
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Pre-Rolls
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Pre-Rolls
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Double Burger {/* lineage 2 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Citrus {/* flavour 2 */}
                                        </th>
                                        <th>
                                            3 x 0.5g {/* product size 1 */}
                                        </th>
                                        <th>
                                            28-38% {/* thc % for product size 1 */}
                                        </th>
                                        <th>
                                            Myrcene {/* terp 1 */}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            {/* lineage 3 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Spicy {/* flavour 3 */}
                                        </th>
                                        <th>
                                            {/* product size 2 */}
                                        </th>
                                        <th>
                                             {/* thc % for product size 2 */}
                                        </th>
                                        <th>
                                            Caryophyllene {/* terp 2 */}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            Lemon {/* flavour 4 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Limonene {/* terp 3 */}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                            
                                        </th>
                                        <th>Pine</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='HashBurgerPage'>
                        <div className='HashBurgerContent'>
                            {/*{NewProductTickerTape}*/}
                            <h1 className='ProductTitle'><img src={HashBurgerPicture} alt='TABLE TOP™ Hash Burger' /></h1>
                            <div className='HashBurgerText'>
                                No rig? No prob! Get your solventless rosin in TABLE TOP Hash Burger infused pre-rolls. These little joints are BEEFY. They’re not between two buns with lettuce and tomato, but they’ll blow your tastebuds with meaty hash flavour and enough potency to make you say, “whoa, dude!”
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Han Solo Hashplant</th>
                                        <th>Hash</th>
                                    </tr>
                                    <tr>
                                        <th>Double Burger</th>
                                        <th>Citrus</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Spicy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Lemon</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Pine</th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Hybrid</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Pre-Rolls</th>
                                        <th id='FeatureSubHeaders'>Pre-Rolls</th>
                                    </tr>
                                    <tr>
                                        <th>3 x 0.5g</th>
                                        <th>28-38%</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Pre-Rolls</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Myrcene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default HashBurger;