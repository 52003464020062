import React from 'react'

import './Background.css'

class PrettyGreat28Background extends React.Component {
    render() {
        return (
            <div className='PrettyGreat28Background'>
            </div>
        )
    }
}

export default PrettyGreat28Background; 