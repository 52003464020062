import React from 'react'

import './Background.css'

class Background extends React.Component {
    render() {
        return (
            <div className='ProductsBackground'>
            </div>
        )
    }
}

export default Background;