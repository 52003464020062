import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import AllenWrenchPicture from '../../../../assets/img/flavours/TableTop_AllenWrench.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './AllenWrench.css'

const ThisDescription = `The same name as one of the most reliable tools of all time? Yeah, TABLE TOP Allen Wrench is ready to get to work! Sweet and woody flavour. Notes of citrus, orange and pine. Like your workshop after some elbow grease and a spit shine. Grab your toolbelt and give ‘er!`;

const ThisSlug = 'allen-wrench';

class AllenWrench extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Allen Wrench Dried Flower | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Sativa-dominant Allen Wrench dried flower from TABLE TOP. Find it at your local cannabis shop.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        document.body.classList.add('individual-strain-page--'+ThisSlug);
    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page--'+ThisSlug);
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='AllenWrenchPage'>

                        <div className='AllenWrenchContent'>
                            <h1 className='ProductTitle'><img src={AllenWrenchPicture} alt='TABLE TOP™ Allen Wrench' /></h1>
                            <div className='AllenWrenchText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            NYC Diesel
                                        </th>
                                        <th>
                                            Sativa Dominant
                                        </th>
                                        <th>
                                            Citrus
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Trainwreck
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Orange
                                        </th>
                                        <th>
                                            28g {/* product size 1 */}
                                        </th>
                                        <th>
                                            22-28% {/* thc % for product size 1 */}
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                             {/* lineage 3 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Woody
                                        </th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            Farnesene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            Pine
                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            
                                        </th>
                                        <th>
                                            Linalool
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            Sweet
                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>

                                        </th>
                                    </tr><tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='AllenWrenchPage'>
                        <div className='AllenWrenchContent'>

                            <h1 className='ProductTitle'><img src={AllenWrenchPicture} alt='TABLE TOP™ Allen Wrench' /></h1>
                            <div className='AllenWrenchText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>NYC Diesel</th>
                                        <th>Citrus</th>
                                    </tr>
                                    <tr>
                                        <th>Trainwreck</th>
                                        <th>Orange</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Woody</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Pine</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Sweet</th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Sativa Dominant</th>
                                        <th></th>
                                    </tr>
                                    
                                    <br></br>
                                    
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>22-28%</th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Farnesene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Linalool</th>
                                        <th></th>
                                    </tr>

                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default AllenWrench;