import React from 'react'

import axios from 'axios'; // adam added
import { useState } from 'react'; // adam added

import AboutBackground from './Background/Background.js'
import Header from '../BasePage/Header/Header.js';
import { Link } from 'react-router-dom';

import AboutPicture from '../../assets/img/About.jpg'
import MobileFooter from '../BasePage/MobileSideBar/MobileFooter/MobileFooter.js';

// newsletter
// import NewsletterModal from '../Newsletter/NewsletterModal';
// import TableTopLogoText from '../../assets/img/tabletop-logo-text.svg'
// end newsletter

import './About.css'

// adam added

const getQuote = () => {
    // alert('test result text');

    axios.get('https://api.quotable.io/random')
    .then(res => {
        // alert(res.data.content);
        // setQuote(res.data.content)
    }).catch(err => {
        console.log(err);
    })
}

const API_PATH = 'https://ne3.test/tt_ajax.php';
// const API_PATH = '/tt_ajax.php';
let quote = 'text';

// end adam added


class About extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "About TABLE TOP™ | TABLE TOP Cannabis Canada | It’s Got Legs™";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP™ cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        document.body.classList.add('about-page');

    }

    componentWillUnmount() {
        document.body.classList.remove('about-page');
    }

    /*constructor(props) {
      super(props);
      this.state = {
        fname: '',
        lname: '',
        email: '',
        message: '',
        province: '',
        mailSent: false,
        error: null
      }
    }*/


    // end adam added

    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <div>
                    {/*<AboutBackground />*/}
                    {/*<Header />*/}
                    <div className='About'>
                        <h1 id='HeaderText'>
                            ABOUT TABLE TOP™
                        </h1>
                        <div className='AboutContent'>
                            <img id='Bob' src={AboutPicture} alt='' />
                            <div className='AboutText'>
                                <h1 className='FullJustify' style={{ fontWeight: '100', fontSize: '1.9em' }}>Hey, thanks for stopping by.</h1>

                                <p className='FullJustify'>They said we had to do a website for “marketing” so here we are. We did our best but we’re not internet scientists ok?? Gotta save the brain power for TABLE TOP™ cannabis products so you don’t have to think too hard when you’re stocking up. Have a click around and check out our TABLE TOP flower, vapes and pre-rolls. We hope you find them satisfactory. Drop us a line in our guestbook and let us know. Thx!</p>
                                <ul className='FeaturesList'>
                                    <li className='ListElement'>
                                        <mark>Specific strains (not blends)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Tight THC range (no guessing)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Greenhouse grown (big sunlight)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Natural terpenes (no fakes)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Sticky product (of course)</mark>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/*<NewsletterModal />*/}
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<AboutBackground />*/}
                    {/*<Header />*/}
                    <div className='About'>
                        <h1 id='HeaderText'>
                            <span>ABOUT</span><br/><span>TABLE</span><span>TOP™</span>
                        </h1>
                        <div className='AboutContent'>
                            <img id='Bob' src={AboutPicture} alt='' />
                            <div className='AboutText'>
                            <h1 className='FullJustify' style={{ fontWeight: '100', fontSize: '1.9em' }}>Hey, thanks for stopping by.</h1>
                                <p className='FullJustify'>They said we had to do a website for “marketing” so here we are. We did our best but we’re not internet scientists ok?? Gotta save the brain power for TABLE TOP™ cannabis products so you don’t have to think too hard when you’re stocking up. Have a click around and check out our TABLE TOP flower, vapes and pre-rolls. We hope you find them satisfactory. Drop us a line in our guestbook and let us know. Thx!</p>
                                <ul className='FeaturesList'>
                                    <li className='ListElement'>
                                        <mark>Specific strains (not blends)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Tight THC range (no guessing)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Greenhouse grown (big sunlight)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Natural terpenes (no fakes)</mark>
                                    </li>
                                    <li className='ListElement'>
                                        <mark>Sticky product (of course)</mark>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>

                    {/*<NewsletterModal />*/}

                </div>
            )
        }

    }
}

export default About;