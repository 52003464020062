import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import JungleCakePicture from '../../../../assets/img/flavours/TableTop_JungleCake.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './JungleCake.css'
class JungleCake extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Jungle Cake Dried Flower | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Sativa-dominant Blue Lemon Thai dried flower from TABLE TOP has earthy, fruity and diesel flavour. Find it at your local cannabis shop.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='JungleCakePage'>
                        <div className='JungleCakeContent'>
                            <h1 className='ProductTitle'><img src={JungleCakePicture} alt='TABLE TOP™ Jungle Cake' /></h1>
                            <div className='JungleCakeText'>
                                Remember that time you got lost in a magical rainforest and decided to lick the floor just to see how it felt? Maybe it was a dream. Maybe that’s what TABLE TOP Jungle Cake tastes like, except more like tropical, earthy vibes and less like eating dirt (don’t).
                                <p></p>
                                <table>
                                    <tr>
                                        <th  style={{width: "120px"}} id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th style={{width: "100px"}} id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            White Fire #43 {/* lineage 1 */}
                                        </th>
                                        <th>
                                            Indica Dominant {/* plant type */}
                                        </th>
                                        <th>
                                            Sweet {/* flavour 1 */}
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Wedding Cake {/* lineage 2 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Tropical {/* flavour 2 */}
                                        </th>
                                        <th>
                                            3.5g {/* product size 1 */}
                                        </th>
                                        <th>
                                            20-23% {/* thc % for product size 1 */}
                                        </th>
                                        <th>
                                            Caryophyllene {/* terp 1 */}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            {/* lineage 3 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Earthy {/* flavour 3 */}
                                        </th>
                                        <th>
                                           {/* product size 2 */}
                                        </th>
                                        <th>
                                            {/* thc % for product size 2 */}
                                        </th>
                                        <th>
                                            Limonene {/* terp 2 */}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                             {/* flavour 4 */}
                                        </th>
                                        <th>
                                        
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Farnesene {/* terp 3 */}
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='ConstantTermsAndConditions' style={{ top: '120vh' }}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='JungleCakePage'>
                        <div className='JungleCakeContent'>
                            <h1 className='ProductTitle'><img src={JungleCakePicture} alt='TABLE TOP™ Jungle Cake' /></h1>
                            <div className='JungleCakeText'>
                                Remember that time you got lost in a magical rainforest and decided to lick the floor just to see how it felt? Maybe it was a dream. Maybe that’s what TABLE TOP Jungle Cake tastes like, except more like tropical, earthy vibes and less like eating dirt (don’t).
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Lemon Thai</th>
                                        <th>Earthy</th>
                                    </tr>
                                    <tr>
                                        <th>Toxic Blue</th>
                                        <th>Fruity</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Diesel</th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Sativa Dominant</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                    </tr>
                                    <tr>
                                        <th>3.5g</th>
                                        <th>18-23%</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>19-21% </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Terpinolene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Ocimene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Myrcene</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default JungleCake;