import React from 'react'

import { Link } from 'react-router-dom'

import FlavoursBackground from './Background/Background'
import Header from '../../BasePage/Header/Header';

//Flavour text
import DiamondDustOG from '../../../assets/img/flavours/TableTop_DiamondDustOG.svg'
import OGKush from '../../../assets/img/flavours/TableTop_90sOGKush.svg'
// import BlueLemonThai from '../../../assets/img/flavours/TableTop_BlueLemonThai.svg'
// import AmherstSuperSkunk from '../../../assets/img/flavours/TableTop_AmherstSuperSkunk.svg'
import SundaeDriver from '../../../assets/img/flavours/TableTop_SundaeDriver.svg'
// import LakeTahoeOG from '../../../assets/img/flavours/TableTop_LakeTahoeOG.svg'
import PurplePunch from '../../../assets/img/flavours/TableTop_PurplePunch.svg'
// import SourKush from '../../../assets/img/flavours/TableTop_SourKush.svg'
import StickyBuns from '../../../assets/img/flavours/TableTop_StickyBuns.svg'
import GarlicBreath from '../../../assets/img/flavours/TableTop_GarlicBreath.svg'
import GreaseMonkey from '../../../assets/img/flavours/TableTop_GreaseMonkey.svg'
import GrilledCheese from '../../../assets/img/flavours/TableTop_GrilledCheese.svg'
import GrandaddyBlack from '../../../assets/img/flavours/TableTop_GrandaddyBlack.svg'
// import CactusBreath from '../../../assets/img/flavours/TableTop_CactusBreath.svg'
// import GrapeDiamonds from '../../../assets/img/flavours/TableTop_GrapeDiamonds.svg'
// import Moontang from '../../../assets/img/flavours/TableTop_Moontang.svg'
// import DurbanLime from '../../../assets/img/flavours/TableTop_DurbanLime.svg'
// import LemonDiesel from '../../../assets/img/flavours/TableTop_LemonDiesel.svg'
// import ZombieKush from '../../../assets/img/flavours/TableTop_ZombieKush.svg'
// import RaceFuelOG from '../../../assets/img/flavours/TableTop_RaceFuelOG.svg'
// import Banjo from '../../../assets/img/flavours/TableTop_Banjo.svg'
import JungleCake from '../../../assets/img/flavours/TableTop_JungleCake.svg'

import SanFranSourDough from '../../../assets/img/flavours/TableTop_SanFranSourDough.svg'
// import JustFineGrindBoldSativa from '../../../assets/img/flavours/TableTop_JustFineGrindBoldSativa.svg'
// import JustFineGrindBoldIndica from '../../../assets/img/flavours/TableTop_JustFineGrindBoldIndica.svg'
import AllenWrench from '../../../assets/img/flavours/TableTop_AllenWrench.svg'
// import SquareDance from '../../../assets/img/flavours/TableTop_SquareDance.svg'
// import JustFineGrindIndica from '../../../assets/img/flavours/TableTop_JustFineGrindIndica.svg'
// import JustFineGrindSativa from '../../../assets/img/flavours/TableTop_JustFineGrindSativa.svg'
// import KiwiKush from '../../../assets/img/flavours/TableTop_KiwiKush.svg'
import CitySlicker from '../../../assets/img/flavours/TableTop_CitySlicker.svg'
import FuzzyHammer from '../../../assets/img/flavours/TableTop_FuzzyHammer.svg'
import Yogurt from '../../../assets/img/flavours/TableTop_Yogurt.svg'



//'Learn more' buttons
import BeigeButton from '../../../assets/img/button-beige.svg'
import BlackButton from '../../../assets/img/button-black.svg'
import GreenButton from '../../../assets/img/button-green.svg'
import PurpleButton from '../../../assets/img/button-purple.svg'
import YellowButton from '../../../assets/img/button-yellow.svg'
import BrownButton from '../../../assets/img/button-brown.svg'
import BlueLearnMoreButton from '../../../assets/img/button-blue-learn-more.svg' // needs weird name compared to others because there's a BlueButton already but it says "VERIFY" instead of "LEARN MORE"


//'Learn more' hover buttons (adam added)
import BeigeButtonHover from '../../../assets/img/hover_buttons/BeigeHover.svg'
import BlackButtonHover from '../../../assets/img/hover_buttons/BlackHover.svg'
import GreenButtonHover from '../../../assets/img/hover_buttons/GreenHover.svg'
import PurpleButtonHover from '../../../assets/img/hover_buttons/PurpleHover.svg'
import YellowButtonHover from '../../../assets/img/hover_buttons/YellowHover.svg'
import BrownButtonHover from '../../../assets/img/hover_buttons/BrownHover.svg'
import BlueLearnMoreButtonHover from '../../../assets/img/hover_buttons/BlueLearnMoreHover.svg' // needs weird name compared to others because there's a BlueButton already but it says "VERIFY" instead of "LEARN MORE"


//Each Strain Component


import '../ProductsSubpage.css'
import MobileDriedFlower from './MobileDriedFlower/MobileDriedFlower';


const NewProductTickerTape = <div className={`NewProduct`}> <div className={`NewProduct__text  ticker-move-vertical`}> NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! </div> </div>

class Flavours extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------
    
    componentDidMount() {

        document.title = "TABLE TOP™ Dried Flower | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP™ cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        /*const imagesPreload = [BeigeButtonHover, BlackButtonHover, GreenButtonHover, PurpleButtonHover, YellowButtonHover, BrownButtonHover];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
            console.log('image: '+newImage);
        });*/

        document.body.classList.add('strains-page');
    }


    componentWillUnmount() {
        document.body.classList.remove('strains-page');
    }

    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------

    


    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <div> {/*className="FlavoursBackground">*/}
                    {/*<FlavoursBackground />*/}
                    {/*<Header />*/}

                    <div className='Flavours'>


                        <h1 id="HeaderText"><a href="/products">PRODUCTS</a> > DRIED FLOWER</h1>


                        <Link to='/products/city-slicker'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--city-slicker  CitySlickerCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={CitySlicker} alt='City Slicker' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlueLearnMoreButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlueLearnMoreButtonHover} onMouseOut={e => e.currentTarget.src = BlueLearnMoreButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 14g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>

                        <Link to='/products/fuzzy-hammer'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--fuzzy-hammer  FuzzyHammerCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={FuzzyHammer} alt='Fuzzy Hammer' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={GreenButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = GreenButtonHover} onMouseOut={e => e.currentTarget.src = GreenButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>


                        <Link to='/products/yogurt'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--yogurt  YogurtCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={Yogurt} alt='Yogurt' style={{maxHeight: '50px'}} />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 14g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>


                        {/*<Link to='/products/80s-kiwi-kush'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--purple  KiwiKushCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={KiwiKush} alt='80s Kiwi Kush' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={GreenButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = GreenButtonHover} onMouseOut={e => e.currentTarget.src = GreenButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 7g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}


                        <Link to='/products/san-fran-sour-dough'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--beige-darker  SanFranSourDoughCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={SanFranSourDough} alt='San Fran Sour Dough' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>


                        {/*<Link to='/products/just-fine-grind-bold-sativa'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--purple  JustFineGrindBoldSativaCard'>
                                {NewProductTickerTape}
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={JustFineGrindBoldSativa} alt='Just Fine Grind BOLD Sativa' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlueLearnMoreButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlueLearnMoreButtonHover} onMouseOut={e => e.currentTarget.src = BlueLearnMoreButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Infused Milled Flower</li>
                                            <li>- 3g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}


                        {/*<Link to='/products/just-fine-grind-bold-indica'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--green  JustFineGrindBoldIndicaCard'>
                                {NewProductTickerTape}
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={JustFineGrindBoldIndica} alt='Just Fine Grind BOLD Indica' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Infused Milled Flower</li>
                                            <li>- 3g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}


                        <Link to='/products/allen-wrench'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--beige  JustFineGrindBoldIndicaCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={AllenWrench} alt='Allen Wrench' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlueLearnMoreButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlueLearnMoreButtonHover} onMouseOut={e => e.currentTarget.src = BlueLearnMoreButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>


                        {/*<Link to='/products/square-dance'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--green  JustFineGrindBoldIndicaCard'>
                                {NewProductTickerTape}
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={SquareDance} alt='Square Dance' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}


                        {/*<Link to='/products/just-fine-grind-sativa'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--green  JustFineGrindSativaCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={JustFineGrindSativa} alt='Just Fine Grind Sativa' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Milled Flower</li>
                                            <li>- 14g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        

                        {/*<Link to='/products/just-fine-grind-indica'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--yellow  JustFineGrindIndicaCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={JustFineGrindIndica} alt='Just Fine Grind Indica' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BrownButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BrownButtonHover} onMouseOut={e => e.currentTarget.src = BrownButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Milled Flower</li>
                                            <li>- 14g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}


                        {/*<Link to='/products/grape-diamonds'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--blue  GrapeDiamondsCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grape-diamonds' src={GrapeDiamonds} alt='Grape Diamonds' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 7g</li>
                                            <li>- 14g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}

                        {/*<Link to='/products/moontang'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--brown  MoontangCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--moontang' src={Moontang} alt='Moontang' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}

                        {/*<Link to='/products/durban-lime'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--green  DurbanLimeCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--durban-lime' src={DurbanLime} alt='Durban Lime' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 3 x 0.5g</li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}

                        {/*<Link to='/products/lemon-diesel'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--yellow  LemonDieselCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--lemon-diesel' src={LemonDiesel} alt='Lemon Diesel' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 7g</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}

                        {/*<Link to='/products/zombie-kush'>
                            <div className='ProductLearnMoreCard  ZombieKushCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--zombie-kush' src={ZombieKush} alt='Zombie Kush' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}

                        {/*<Link to='/products/banjo'>
                            <div className='ProductLearnMoreCard  ProductLearnMoreCard--yellow  BanjoCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--banjo' src={Banjo} alt='Banjo' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={GreenButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = GreenButtonHover} onMouseOut={e => e.currentTarget.src = GreenButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}

                        {/*<Link to='/products/race-fuel-og'>
                            <div className='ProductLearnMoreCard  RaceFuelOGCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--race-fuel-og' src={RaceFuelOG} alt='Race Fuel OG' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 14g</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}

                        

                        {/*
                        
                        IF YOU UNHIDE THESE, MAKE SURE TO PUT SPACES IN THE ALT TAGS

                        <Link to='/products/jungle-cake'>
                            <div className='ProductLearnMoreCard  JungleCakeCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--durban-lime' src={JungleCake} alt='JungleCake' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>

                        <Link to='/diamond-dust-og'>
                            <div className='DiamondDustOGCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--diamond-dust-og' src={DiamondDustOG} alt='Diamond Dust OG' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/og-kush'>
                            <div className='OGKushCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--og-kush' src={OGKush} alt='OG Kush' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Vape</li>
                                            <li>- 0.5g</li>
                                            <li>- 1g </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/sour-kush'>
                            <div className='SourKushCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--sour-kush' src={SourKush} alt='Sour Kush' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Vape</li>
                                            <li>- 0.5g</li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/lake-tahoe-og'>
                            <div className='LakeTahoeOGCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--lake-tahoe-og' src={LakeTahoeOG} alt='LakeTahoeOG' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={GreenButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = GreenButtonHover} onMouseOut={e => e.currentTarget.src = GreenButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Vape</li>
                                            <li>- 0.5g</li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'> </li>
                                            <li> </li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/products/blue-lemon-thai'>
                            <div className='BlueLemonThaiCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--blue-lemon-thai' src={BlueLemonThai} alt='Blue Lemon Thai' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BeigeButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BeigeButtonHover} onMouseOut={e => e.currentTarget.src = BeigeButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        <Link to='/products/purple-punch'>
                            <div className='PurplePunchCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--purple-punch' src={PurplePunch} alt='Purple Punch' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g </li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/products/sticky-buns'>
                            <div className='StickyBunsCard'>
                                <div className="BestSeller">
                                    <div className="BestSeller__text  ticker-move-vertical">BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER!
                                    </div>
                                </div>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--sticky-buns' src={StickyBuns} alt='Sticky Buns' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 3.5g</li>
                                            <li>- 7g </li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        {/*<Link to='/products/cactusbreath'>
                            <div className='CactusBreathCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--cactus-breath' src={CactusBreath} alt='Cactus Breath' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/products/garlic-breath'>
                            <div className='GarlicBreathCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--garlic-breath' src={GarlicBreath} alt='Garlic Breath' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={PurpleButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = PurpleButtonHover} onMouseOut={e => e.currentTarget.src = PurpleButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/products/grease-monkey'>
                            <div className='GreaseMonkeyCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grease-monkey' src={GreaseMonkey} alt='Grease Monkey' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={GreenButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = GreenButtonHover} onMouseOut={e => e.currentTarget.src = GreenButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/products/grilled-cheese'>
                            <div className='GrilledCheeseCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grilled-cheese' src={GrilledCheese} alt='Grilled Cheese' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'>Pre-Rolls</li>
                                            <li>- 7 x 0.5g</li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*

                        IF YOU UNHIDE THESE, MAKE SURE TO PUT SPACES IN THE ALT TAGS

                        <Link to='/products/grandaddy-black'>
                            <div className='GrandaddyBlackCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--grandaddy-black' src={GrandaddyBlack} alt='Grandaddy Black' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BrownButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BrownButtonHover} onMouseOut={e => e.currentTarget.src = BrownButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>*/}
                        {/*<Link to='/products/amherst-super-skunk'>
                            <div className='AmherstSuperSkunkCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--amherst-super-skunk' src={AmherstSuperSkunk} alt='Amherst Super Skunk' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={BlackButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = BlackButtonHover} onMouseOut={e => e.currentTarget.src = BlackButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                        {/*<div style={{ height:'20px', content: 'none' }}>
                            <br></br>
                        </div>
                        <Link to='/products/sundae-driver'>
                            <div className='SundaeDriverCard'>
                                <div className='StrainTitle'>
                                    <img className='ProductLearnMoreCard__strain-logo  ProductLearnMoreCard__strain-logo--sundae-driver' src={SundaeDriver} alt='Sundae Driver' />
                                </div>
                                <div className='LearnMore'>
                                    <img src={YellowButton} alt='Learn More' onMouseOver={e => e.currentTarget.src = YellowButtonHover} onMouseOut={e => e.currentTarget.src = YellowButton} />
                                </div>
                                <div className='Amounts'>
                                    <div className='AmountVape'>
                                        <ul>
                                            <li id='AmountHeader'></li>
                                            <li></li>
                                            <li> </li>
                                            <li> </li>
                                        </ul>
                                    </div>
                                    <div className='AmountFlower'>
                                        <ul>
                                            <li id='AmountHeader'>Dried Flower</li>
                                            <li>- 28g</li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Link>*/}
                    </div>

                </div>
            )
        }
        else { return <MobileDriedFlower /> }



    }
}

export default Flavours;