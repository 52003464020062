import React from 'react';

import ProductsBackground from './Background/Background.js'
import Header from '../BasePage/Header/Header.js';

import MobileProducts from './MobileProducts/MobileProducts.js';
import { Link } from 'react-router-dom';

import './Products.css'

//Images for product page
import Flower from '../../assets/img/Products_Flower.jpg';
import Prerolls from '../../assets/img/Products_Prerolls.jpg';
import Vape from '../../assets/img/Products_Vape.jpg';

import FlowerButtonImage from '../../assets/img/Products_Flower_button.svg';
import FlowerButtonImageHover from '../../assets/img/Products_Flower_button_hover.svg';

import PrerollsButtonImage from '../../assets/img/Products_Prerolls_button.svg';
import PrerollsButtonImageHover from '../../assets/img/Products_Prerolls_button_hover.svg';

import VapeButtonImage from '../../assets/img/Products_Vape_button.svg';
import VapeButtonImageHover from '../../assets/img/Products_Vape_button_hover.svg';



class Products extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Flower, Vapes & Pre-Rolls | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP™ cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already
    
        document.body.classList.add('products-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('products-page');
    }
    // end adam added

    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <div>
                    {/*<ProductsBackground />*/}
                    {/*<Header />*/}
                    {/*<div className='ConstantTermsAndConditions'>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>*/}
                    <div className='Products'>
                        <h1 id="HeaderText">TABLE TOP™ PRODUCTS</h1>
                        <div className='ProductContent'>
                            <div className='ProductText'>
                                <div className='DriedFlower'>
                                    <div className='ProductDescription'>
                                        <a href="/products/dried-flower" className='ProductDescription__button  ProductDescription__button--flower' onMouseOver={e => e.currentTarget.firstChild.src = FlowerButtonImageHover} onMouseOut={e => e.currentTarget.firstChild.src = FlowerButtonImage}>
                                            <img className='ProductDescription__button-image' src={FlowerButtonImage} alt='' />
                                        </a>
                                        <div className='ProductDescription__text  ProductDescription__text--flower'>
                                            <h1 className='ProductDescription__heading'>Dried Flower</h1>
                                            <p className='ProductDescription__p'>Pick a bud. Grab a bag. Try something new. Stick with an old fave. Whatever you’re after, you’ve got some decent options from TABLE TOP™. Either way you’re getting high THC in tight ranges, freshness and flavour. It’s literally the least we could do (jk our growers actually do the most – y’all are great).</p>
                                        </div>
                                    </div>
                                </div> {/* /.DriedFlower */}

                                <div className='PreRolls'>
                                    <div className='ProductDescription'>
                                        <a href="/products/pre-rolls" className='ProductDescription__button  ProductDescription__button--pre-rolls' onMouseOver={e => e.currentTarget.firstChild.src = PrerollsButtonImageHover} onMouseOut={e => e.currentTarget.firstChild.src = PrerollsButtonImage}>
                                            <img className='ProductDescription__button-image' src={PrerollsButtonImage} alt='' />
                                        </a>
                                        <div className='ProductDescription__text  ProductDescription__text--pre-rolls'>
                                            <h1 className='ProductDescription__heading'>Pre-Rolls</h1>
                                            <p className='ProductDescription__p'>Whether your rolling skills are the worst or you’re blaming a strong gust of wind (lol nice), having pre-rolled options on the table is pretty neat. We’ve got a few different ones to choose from which is also pretty neat. One day we will learn more adjectives. Until then, try some of our neat TABLE TOP pre-rolls!</p>
                                        </div>
                                    </div>
                                </div> {/* /.PreRolls */}

                                {/*
                                <div className='Vape'>
                                    <div className='ProductDescription'>
                                        <a href="/products/vape" className='ProductDescription__button  ProductDescription__button--vape' onMouseOver={e => e.currentTarget.firstChild.src = VapeButtonImageHover} onMouseOut={e => e.currentTarget.firstChild.src = VapeButtonImage}>
                                            <img className='ProductDescription__button-image' src={VapeButtonImage} alt='' />
                                        </a>
                                        <div className='ProductDescription__text  ProductDescription__text--vape'>
                                            <h1 className='ProductDescription__heading'>Vape</h1>
                                            <p className='ProductDescription__p'>Nobody rocks a hair net and goggles like the TABLE TOP lab team in BC. They’re good at other stuff too, like making our distillate with natural flavours (they’ll talk your ear off about terpenes, for real). Plus, our cartridges are probably the most high-tech thing around here with their No Burn Technology™ which minimizes overheating and burnt flavours. Nice. Ok that’s enough science for one day.</p>
                                        </div>
                                    </div>
                                </div> */} {/* /.Vape */}

                            </div>
                        </div>
    
                    </div>
                </div>
            )
        }
        else {
            return <MobileProducts />
        }
        
    }

}

export default Products;