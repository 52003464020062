import React from 'react'

import './Background.css'

class GuestbookBackground extends React.Component {
    render() {
        return (
            <div className='GuestbookBackground'>
            </div>
        )
    }
}

export default GuestbookBackground; 