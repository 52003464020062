import React from 'react'

import './PrivacyPolicy.css'

// adam added - LiveChat
import LiveChat from 'react-livechat'

class PrivacyPolicy extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Privacy Policy | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Bzam Management Inc. dba BZAM (“BZAM”, “We” or “Us”) are committed to protecting your privacy with respect to the collection, use, and disclosure of your personal information. `;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added

    render() {
        return <div id="content" className="site-content" role="document" style={{padding: '30px'}}>
            <main className="main ">
                <div className="row">
                    <div className>
                        <article id="post-152" className="post-152 page type-page status-publish">
                            <header className="entry-header">
                                <div className="bgtfw container">
                                </div>
                            </header>{/* .entry-header */}
                            <div className="entry-content">
                                <div className="bgtfw ">
                                    <div className="boldgrid-section" style={{ marginTop: '-72px', marginBottom: '-54px' }}>
                                        <div className="container">
                                            <div className="row" style={{ paddingTop: '75px', paddingBottom: '75px' }}>
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <h2 className>Privacy Policy</h2>
                                                    <p className="fixed-width small-legal">Last updated: September 27, 2021</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boldgrid-section">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 col-xs-12 col-sm-12 privacy-policy-container">


                                                    <p><strong>Policy Statement</strong></p>
                                                    <p>Bzam Management Inc. dba BZAM (“BZAM”, “We” or “Us”) are committed to protecting your privacy with respect to the collection, use, and disclosure of your personal information. This Privacy Policy applies to BZAM’s activities in Canada which are subject to applicable Canadian federal and provincial privacy laws and describes the personal information that BZAM collects from or about you, as well as how we use and to whom we disclose that information. Please review this document carefully to understand our policies and practices for collection, use and disclosure of your Personal Information. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you indicate that you understand, accept, and consent to the collection, use and disclosure of your Personal Information (defined below) as described in this policy. </p>
                                                    <p>We are responsible for the protection of Personal Information in our custody or control. This Privacy Policy describes key principles and practices we follow regarding our collection, use, disclosure, retention and disposal of your Personal Information, whether collected by us online (including electronic newsletter), via telephone or mobile devices, mail, or any other means. This Privacy Policy does not apply to the practices of any company other than BZAM, or to people that we do not employ or manage, including without limitation, any affiliates, business partners or other third parties. </p>
                                                    <p>This Privacy Policy may be amended by us from time to time in order to address modifications to existing law, regulations, company policies or business practices. Any such amendments will be reflected in the Privacy Policy posted on our Website.</p>
                                                    <p><strong>What is Personal Information?</strong></p>
                                                    <p>Personal information (“Personal Information”) is information about you, and may include (without limitation) your age, name, address, income, ethnicity, employee file, social status, and personal credit or financial information. It reveals something of a personal nature about you, and it could reasonably identify you if disclosed (either on its own, or in combination with other information). Personal Information does not include your business contact information, such as your title, business address or business email address (when used for the purpose of communicating to you in relation to your employment or profession), or information that has been anonymized or aggregated so that it can no longer be used to identify you. </p>
                                                    <p><strong>What Personal Information Do We Collect?</strong></p>
                                                    <p>We collect Personal Information from you online (including electronic newsletter and guestbooks), over the telephone or by mail or by any other means and will inform you of the purposes for which we collect and use your Personal Information at the time of collection, or as otherwise set out in this Privacy Policy. Types of Personal Information we may collect include:</p>
                                                    <ul>
                                                      <li>identifying and contact information, such as your name, address, gender, telephone number, e-mail address, and birth date;</li>
                                                      <li>certain Personal Information related to your request for or purchase of certain products or services;</li>
                                                      <li>financial information, such as credit card details or billing addresses;</li>
                                                      <li>information related to your use of our Website, including internet service providers and Internet Protocol (IP) addresses of devices being used to access our Website, operating systems and the types and versions of browsers used to access our Websites, dates and times users access our Websites, as well as Website cookies;</li>
                                                      <li>to send communications, including newsletters and promotional campaigns, informing you of our products and services;</li>
                                                      <li>other information through your use of our Websites, correspondence or discussions with us, and other mechanisms for transacting and communicating with us.</li>
                                                    </ul>
                                                    <p>You may also provide information to be published or displayed (“<strong>posted</strong>”) on public areas of the Website (“<strong>User Submissions</strong>”). Your User Submissions are posted on and transmitted to others at your own risk. We may also collect market-related data from you, which could include Personal Information, related to trends in the industry. Additionally, we may, at times, receive Personal Information about you from certain third parties engaged to perform services. In such circumstances, we will take reasonable steps to ensure that disclosure by such third parties is made to us only in compliance with applicable privacy laws.</p>
                                                    <p><strong>Cookies and Tracking Technology </strong></p>
                                                    <p>We may use “cookies" or other automatic data collection technologies to collect certain information about your browsing actions and patterns, including:</p>
                                                    <ul>
                                                      <li>details of your visits to our Website; and,</li>
                                                      <li>information about your computer and internet connection, including your IP address, operating system and browser type.</li>
                                                    </ul>
                                                    <p>The information we collect automatically is statistical data and may include Personal Information, and we may maintain it or associate it with Personal Information we collect in other ways. It helps us improve our Website and to deliver a better and more personalized services, including by enabling us to estimate our audience size and usage patterns, store information about your preferences, speed up your searches and recognize you when you return to the Website.</p>
                                                    <p><strong>Why does BZAM Collect Personal Information?</strong></p>
                                                    <p>We collect and use your Personal Information to perform our necessary business activities, including:</p>
                                                    <ul>
                                                      <li>to process, verify, and deliver orders of products or services, subscription services related to newsletters, magazines or informational campaigns;</li>
                                                      <li>to communicate with you regarding products or services, via email, SMS, text or by telephone;</li>
                                                      <li>to manage our relationship with you;</li>
                                                      <li>to receive and respond to inquiries, and to improve our products and services;</li>
                                                      <li>to permit you to register and access an account with us;</li>
                                                      <li>to identify and carry out improvements to the products and services we provide;</li>
                                                      <li>as permitted or as is necessary to comply with legal and regulatory requirements;</li>
                                                      <li>to deliver targeted marketing, service updates, and promotional materials based on your use of our Website;</li>
                                                      <li>to measure the performance of our email marketing campaigns (e.g. by analyzing open and click rates);</li>
                                                      <li>to track and analyze trends and patterns impacting our business;</li>
                                                      <li>to detect, prevent, mitigate and investigate fraudulent or illegal activities;</li>
                                                      <li>to create aggregated information that does not allow you to be personally identified or contacted; and</li>
                                                      <li>any other reasonable purpose, so long as your consent has been provided.</li>
                                                    </ul>
                                                    <p><strong>How does BZAM use the Personal Information Collected?</strong></p>
                                                    <p>We may use Personal Information collected:</p>
                                                    <ul>
                                                      <li>as permitted or as is necessary to comply with our legal and regulatory requirements;</li>
                                                      <li>for the reasons described in this Privacy Policy, including to process your orders, and to communicate with you about our products and services;</li>
                                                      <li>in any other reasonable manner, so long as we have obtained your prior consent.</li>
                                                    </ul>
                                                    <p>We may use aggregated, anonymized information collected for statistical purposes in order to, for example, identify the demographics of our customers, or to analyze trends and patterns. We may also disclose aggregated, anonymized data to third parties, but never any identifiable Personal Information (except in accordance with this Privacy Policy).</p>
                                                    <p><strong>When does BZAM Disclose Personal Information?</strong></p>
                                                    <p>We may disclose Personal Information to your authorized family member, but only with your consent or as otherwise permitted or required by law. We may also share your Personal Information with our employees, consultants, affiliated companies and other third parties that provide services to us, and who require it in order to ensure we are able to manage our relationship with you and provide you with products and services, as described in this Privacy Policy. Additionally, your Personal Information may be disclosed with your consent, or:</p>
                                                    <ul>
                                                      <li>for the purposes described in this Privacy Policy;</li>
                                                      <li>to our third party service providers (who may be located outside Canada) for purposes related to supporting our business, including (without limitation) IT service providers, payment processers, and packaging and shipping partners;</li>
                                                      <li>as part of an audit by governmental or regulatory authorities;</li>
                                                      <li>as permitted or required by applicable law or regulatory requirements;</li>
                                                      <li>during emergency situations or where necessary to protect the safety of a person; and</li>
                                                      <li>as part of a merger, acquisition, divestment or change in control of all or a part of our business.</li>
                                                    </ul>
                                                    <p><strong>How does BZAM Protect Personal Information? </strong></p>
                                                    <p>Wherever possible, we store your Personal Information in Canada, under our custody and control, using reasonable organizational, contractual, technical and physical security measures to protect your Personal Information from unauthorized access, collection, use, disclosure or destruction. However, some of the Personal Information we collect from you, such as financial information related to your purchase of products or services (i.e. credit card purchases), may be transferred, stored or processed outside of Canada. In this case, your Personal Information would be subject to the laws of the country to which it is transferred and stored. That country may have laws that require that your Personal Information be disclosed to government agencies under different circumstances than would Canada. Should you have questions or concerns about the specific safeguards we have in place, please feel free to contact our General Counsel at the coordinates below. </p>
                                                    <p><strong>How can Personal Information be Accessed or Updated?</strong></p>
                                                    <p>Your Personal Information is just that – yours. We will make reasonable efforts to ensure your Personal Information is accurate, complete and up-to-date, but should you wish to review, change or otherwise access your Personal Information held by us, please contact our General Counsel as per the details below. Please note, however, that there may be times, or circumstances, where your Personal Information cannot be accessed or changed, such as if the information has been destroyed pursuant to our record retention policies. In such cases we will advise you of the reasons why your information cannot be accessed, subject to any legal or regulatory requirements we may otherwise have. Please note that we will retain your Personal Information only for as long as necessary to provide you with the products and services you have requested, or for other essential purposes such as complying with applicable laws.</p>
                                                    <p>You have the right to refuse to give your Personal Information to BZAM. By giving your Personal Information to BZAM you consent to your Personal Information being collected, stored, used, disclosed and processed in accordance with this Privacy Policy. You may withdraw your consent at any time by contacting our General Counsel. If you choose to withdraw or refuse to give consent, BZAM may not be able to provide or continue to provide certain services or information to you. </p>
                                                    <p><strong>Contacting BZAM</strong></p>
                                                    <p>For any questions, concerns or complaints about the collection, use, or disclosure of Personal Information by BZAM, please contact our General Counsel, who is responsible for overseeing compliance with this Privacy Policy.</p>
                                                    <p>BZAM Management Inc.<br />
                                                    Address: <br />
                                                    1570 - 200 Burrard Street, <br />
                                                    Vancouver, British Columbia V6C 3L6<br />
                                                    Attention: General Counsel &amp; Chief Privacy Officer<br />
                                                    E: generalcounsel@bzam.com<br />
                                                    T: 1-844-256-2926</p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* .bgtfw container */}
                            </div>{/* .entry-content */}
                            <footer className="entry-footer">
                                <div className="bgtfw container">
                                </div>
                            </footer>{/* .entry-footer */}
                        </article>{/* #post-## */}
                    </div>
                </div>
            </main>{/* /.main */}

            <LiveChat license={13454061} />

        </div>

    }

}

export default PrivacyPolicy;
