import React from 'react';
import AgeGateBackground from '../AgeGate/Background/Background';
import AGHeader from '../AgeGate/AGHeader/AGHeader';

import './NotFound.css'

class NotFound extends React.Component {
    render() {
        return(
            <div>
                <AgeGateBackground />
                <AGHeader />
                <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh',
                }}>
                    <h1 style={{color: '#EEBC34', fontSize: '92px'}} id='FourZeroFour'>404: Page Not Found.</h1>
                </div>
            </div>
        )
    }
}

export default NotFound;