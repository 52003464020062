import React from 'react'

import { Link } from 'react-router-dom'

import StickyBunsBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import StickyBunsPicture from '../../../../assets/img/flavours/TableTop_StickyBuns.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './StickyBuns.css'

const ThisSlug = 'sticky-buns';

class StickyBuns extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Sticky Buns Dried Flower & Pre-Rolls | TABLE TOP Cannabis" // don't use "&amp;" here;

        // add/update the meta description
        let tab_page_description     = `TABLE TOP’s Sticky Buns brings vanilla, earthy and mint flavours and - you guessed it - sticky buds. Pick this popular indica up at your local cannabis shop.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already
    
        document.body.classList.add('individual-strain-page--'+ThisSlug);
    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page--'+ThisSlug);
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    {/*<StickyBunsBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='StickyBunsPage'>
                        <div className='StickyBunsContent'>

                            <div className="BestSeller">
                                <div className="BestSeller__text  ticker-move-vertical">BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER!
                                </div>
                            </div>

                            <h1 className='ProductTitle'><img src={StickyBunsPicture} alt='TABLE TOP™ Sticky Buns' /></h1>
                            <div className='StickyBunsText'>
                                The sweet smell of this indica-dominant hybrid is almost like a funny joke because by the time the flavour hits your tongue you’ll be like, damn, these TABLE TOP Sticky Buns are more like OG buns of steel. The sticky situation comes from the big, sticky trichomes covering the dense, deep green buds. Definitely bring a napkin.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Kush Mints
                                        </th>
                                        <th>
                                            Indica Dominant
                                        </th>
                                        <th>
                                            Vanilla
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Gellati
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Earthy
                                        </th>
                                        <th>
                                            3.5g
                                        </th>
                                        <th>
                                            22-28% 
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Mint
                                        </th>
                                        <th>
                                            7g
                                        </th>
                                        <th>
                                            22-28%
                                        </th>
                                        <th>
                                            Myrcene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            28g
                                        </th>
                                        <th>
                                            17-20%
                                        </th>
                                        <th>
                                            Linalool
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">
                                            Pre-Rolls
                                        </th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">
                                            Pre-Rolls
                                        </th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">
                                            Pre-Rolls
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th>
                                            3 x 0.5g
                                        </th>
                                        <th>
                                            20-26%
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            Myrcene
                                        </th>
                                    </tr><tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            Linalool
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<StickyBunsBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='StickyBunsPage'>
                        <div className='StickyBunsContent'>

                            <div className="BestSeller">
                                <div className="BestSeller__text  ticker-move-vertical">BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER!
                                </div>
                            </div>

                            <h1 className='ProductTitle'><img src={StickyBunsPicture} alt='TABLE TOP™ Sticky Buns'/></h1>
                            <div className='StickyBunsText'>
                                The sweet smell of this indica-dominant hybrid is almost like a funny joke because by the time the flavour hits your tongue you’ll be like, damn, these TABLE TOP Sticky Buns are more like OG buns of steel. The sticky situation comes from the big, sticky trichomes covering the dense, deep green buds. Definitely bring a napkin.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Kush Mints</th>
                                        <th>Vanilla</th>
                                    </tr>
                                    <tr>
                                        <th>Gellati</th>
                                        <th>Earthy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Mint</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Indica Dominant</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                    </tr>
                                    <tr>
                                        <th>3.5g</th>
                                        <th>22-28%</th>
                                    </tr>
                                    <tr>
                                        <th>7g</th>
                                        <th>22-28%</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>17-20%</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">Pre-Rolls</th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">Pre-Rolls</th>
                                    </tr>
                                    <tr>
                                        <th>3 x 0.5g</th>
                                        <th>20-26%</th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Myrcene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Linalool</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">Pre-Rolls</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Myrcene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Linalool</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default StickyBuns;