import React from 'react'

import { Link } from 'react-router-dom'

import SourKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import SourKushPicture from '../../../../assets/img/flavours/TableTop_SourKush.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './SourKush.css'
class SourKush extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "Sour Kush Dried Flower + Vape | TABLE TOP";

        // add/update the meta description
        let tab_page_description     = `Get TABLE TOP Sour Kush indica in dried flower or vape form at your local weed store. Classic Kush lineage, citrus and earthy flavour. Medium vibes all the way.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    <SourKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='SourKushPage'>
                        <div className='SourKushContent'>
                            <h1 className='ProductTitle'><img src={SourKushPicture} alt='TABLE TOP™ Sour Kush' /></h1>
                            <div className='SourKushText'>
                            The best things in life are sour. Wanna fight about it? Maybe try this Sour Kush first. Sour, indica-powered citrus flavour. Classic Kush lineage. Earthy and floral notes to round it all out. Gets the old drool factory going. Hard to fight when you’re drooling. Case closed.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Sour Diesel
                                        </th>
                                        <th>
                                            Indica Dominant
                                        </th>
                                        <th>
                                            Citrus
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape + Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            OG Kush
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Earthy
                                        </th>
                                        <th>
                                            0.5g
                                        </th>
                                        <th>
                                            81%
                                        </th>
                                        <th>
                                            Terpinolene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Floral
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th>
                                            Ocimene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            28g
                                        </th>
                                        <th>17-21%</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='ConstantTermsAndConditions' style={{top: '120vh'}}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <SourKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='SourKushPage'>
                        <div className='SourKushContent'>
                            <h1 className='ProductTitle'><img src={SourKushPicture} alt='TABLE TOP™ Sour Kush'/></h1>
                            <div className='SourKushText'>
                            The best things in life are sour. Wanna fight about it? Maybe try this Sour Kush first. Sour, indica-powered citrus flavour. Classic Kush lineage. Earthy and floral notes to round it all out. Gets the old drool factory going. Hard to fight when you’re drooling. Case closed.                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Sour Diesel</th>
                                        <th>Citrus</th>
                                    </tr>
                                    <tr>
                                        <th>OG Kush</th>
                                        <th>Earthy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Floral</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Indica Dominant</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>17-21%</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Vape</th>
                                        <th id='FeatureSubHeaders'>Vape</th>
                                    </tr>
                                    <tr>
                                        <th>0.5g</th>
                                        <th>81%</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Vape + Dried Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Terpinolene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Ocimene</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default SourKush;