import React from 'react'

import './Background.css'

class DiamondDustOGhBackground extends React.Component {
    render() {
        return (
            <div className='DiamondDustOGBackground'>
            </div>
        )
    }
}

export default DiamondDustOGhBackground; 