import React from 'react'

import './Background.css'

class NewsletterBackground extends React.Component {
    render() {
        return (
            <div className='NewsletterBackground'>
                
            </div>
        )
    }
}

export default NewsletterBackground;