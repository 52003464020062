import React from 'react'

// adam added - LiveChat
import LiveChat from 'react-livechat'

class TermsAndConditions extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Terms & Conditions | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Please read these terms and conditions carefully as then contain important information regarding your rights, remedies and obligations.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added

    render() {
        return (
            <div id="content" className="site-content" role="document" style={{padding: '30px'}}>
  <main className="main ">
    <div className="row">
      <div className>
        <article id="post-152" className="post-152 page type-page status-publish">
          <header className="entry-header">
            <div className="bgtfw container">
            </div>
          </header>{/* .entry-header */}
          <div className="entry-content">
            <div className="bgtfw ">
              <div className="boldgrid-section" style={{marginTop: '-72px', marginBottom: '-54px'}}>
                <div className="container">
                  <div className="row" style={{paddingTop: '75px', paddingBottom: '75px'}}>
                    <div className="col-md-12 col-xs-12 col-sm-12">
                      <h2 className>Terms &amp; Conditions</h2>
                      <p className="fixed-width small-legal">Last Updated: September 27, 2021</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boldgrid-section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 col-sm-12">

                      <p>Please read these terms and conditions carefully as then contain important information regarding your rights, remedies and obligations.</p>
                      <p>BZAM Management Inc. (“BZAM”) maintain a website currently located at https://www.tabletopwebsite.com/ (the "Website") which provides articles, publications, information, data and other materials (the "Content") and offers certain services, including making certain products (“Products”) available for sale to you (collectively the “Services”). The Website is provided to you subject to your agreement to comply with these terms and conditions of use ("Terms and Conditions"). By accessing or using the Website you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to be bound by these Terms and Conditions and our Privacy Policy, you may not access or use the Website, the Content or any of the Services.</p>
                      <p><strong>Amendments to Terms of Use</strong></p>
                      <p>BZAM reserves the right to revise, modify, supplement or delete any information, materials, services and/or resources contained on the Website, including the right to change, amend or modify these Terms and Conditions at any time without prior notice. If you do not agree with this or with any change, you must stop using or accessing the Website. Your continued access to or use of the Website after such changes shall be considered, and shall be, your acceptance of these Terms and Conditions as modified. It is your responsibility to review these Terms and Conditions regularly.</p>
                      <p><strong>Jurisdiction and Governing Law</strong></p>
                      <p>The Website is hosted on servers located in British Columbia, Canada. As such, your access and use of the Website and Content, and receipt of any Services, shall be deemed to be provided in the Province of British Columbia, and subject to the laws of the Province of British Columbia and the federal laws of Canada applicable therein. Any action, proceeding, claim or dispute arising from, related to, or in connection with the Terms and Conditions, the Website, Content, Products, Services, or your access or use of, or reliance on, any of the foregoing, shall be brought before the courts located in the City of Vancouver in the Province of British Columbia, and decided in accordance with the laws of the Province of British Columbia, and you irrevocably consent to the exclusive jurisdiction and venue of such courts. Notwithstanding the foregoing, either you or BZAM may apply to any court of competent jurisdiction to obtain injunctive or other emergency or similar relief.</p>
                      <p>If you access the Website from outside of Canada, you do so at your own risk and are responsible for compliance with local, national or international laws, including, without limitation, securities laws, privacy laws, and import and export laws. You further understand and acknowledge that the Website may not be available in all jurisdictions and that you are responsible for ensuring that it is lawful for you to use the Website and access the Content and Services in your jurisdiction.</p>
                      <p>If you are residing in a jurisdiction where it is illegal to take part in any of the activities related to the Website, including reviewing the Content or receiving Products or the Services, you may not use the Website. By accessing or using the Website you are expressly representing that you have verified in your own jurisdiction that such access and use complies with all applicable laws, and you acknowledge that BZAM is entitled to, and shall, rely on such representation.</p>
                      <p><strong>Use of the Website</strong></p>
                      <p>You agree that you will not, without BZAM’s prior written permission, use the Website, the Content, Products and the Services for purposes other than your own personal non-commercial use and benefit. You may not frame the Website or the Content or any part thereof on any commercial or non-commercial internet website. You acquire absolutely no rights or licences to the Website or the Content other than the limited right to use the Website and the Content in accordance with these Terms and Conditions. You may only access and use the Website for legitimate purposes and not for any illegal or unauthorized purpose, including in violation of any criminal law, intellectual property law, privacy law or any other applicable law or regulation. You represent and warrant that you are at least the age of majority in the jurisdiction in which you reside, and are legally capable of entering into a binding agreement. Except as expressly provided in these Terms and Conditions, any reproduction, retransmission, distribution, sale, republication, modification, translation of or creation of derivative works based on the Website or the Content, in whole or in part, and any disassembly, reverse engineering or other exploitation of the Website, without the prior written permission of BZAM, is strictly prohibited. BZAM reserves the right to take any actions necessary and appropriate to preserve the integrity of the Website.</p>
                      <p><strong>Materials provided to BZAM or posted on any BZAM’s Websites</strong></p>
                      <p>BZAM does not claim ownership of the materials you provide to BZAM or post, upload, input, or submit to any Website or its associated services (collectively "User Submissions"). By posting, uploading, inputting, providing, or submitting your User Submissions in any way, you are granting BZAM, its affiliated agencies and necessary sublicensees permission to use your User Submission in connection with the activities of BZAM including, without limitation, the rights to: copy, distribute, transmit, publicly display, reproduce, edit, translate and reformat your User Submission; and to publish your name and the names of any individuals you provide us in connection with your User Submission.</p>
                      <p>All information you provide to BZAM including information you provide through the Website or post on the Website may be subject to access and disclosure in accordance with federal and provincial privacy laws.</p>
                      <p>No compensation will be paid with respect to the use or other publication of your User Submission, or other information as provided herein. BZAM is under no obligation to post or use any User Submission you may provide and BZAM may remove any User Submission at any time in BZAM’s sole discretion.</p>
                      <p>By posting, uploading, inputting, providing or submitting your User Submission you warrant and represent that you own or otherwise control all of the rights to your User Submission as described in this section including without limitation, all the rights necessary for you to provide, post, upload, input or submit the User Submissions.</p>
                      <p><strong>Accounts and Registration </strong></p>
                      <p>If a feature of the Website is accessible only by use of a user name and password, you are solely responsible and liable for any use and misuse of your user name and password and for all activities that occur under your user name and password. For security reasons, you must keep your user name and password confidential and not disclose them to any person or permit any other person to use them, except as otherwise authorized by BZAM. All user names and passwords created through use of the Website or Services shall be and remain the property of BZAM, and may be cancelled or suspended at any time by BZAM without any notice or liability to you or any other person. BZAM is not under any obligation to verify the actual identity or authority of any person using your user name and password, and has taken all reasonably necessary steps to ensure you and any users of the Website or Services is of legal age in the jurisdiction in which they reside. You must immediately notify BZAM of any unauthorized use of your user name or password, or if you know or suspect that your user name or password has been lost or stolen, has become known to any other person, or has been otherwise compromised. If you choose to register with BZAM for the online purchase of Products, you agree to: (i) provide true, current and complete information about yourself; and (ii) maintain and promptly update online information about yourself to keep it true, current and complete.</p>
                      <p><strong>Product Sales, Shipment, Returns and Exchanges</strong></p>
                      <p>In addition to the Content, BZAM offers certain Services, including making available to you for purchase, through the Website and otherwise, certain Products. BZAM may, at any time, limit or withdraw the quantities of Product, or types of Product, offered on this Website, or reject or limit the sale of Products to any person, subject to applicable legal or regulatory obligations. When you submit an order for Products to BZAM, you will be required to register with BZAM and provide payment and financial details that are truthful, accurate and complete, and in so doing you represent and warrant that you intend to use the Products lawfully, in accordance with applicable advice, and that you will not resell or transfer the Products to any other person. Further, you represent and warrant that you have the lawful authority to use such method of payment for the purchase of Products from BZAM, and that your registration information is and will be at all material times truthful, accurate and complete. All orders for Products are subject to acceptance by BZAM. BZAM reserves the right to refuse to provide Products to anyone, at any time, for any reason, and to accept or reject any Products for returns or exchanges, subject to applicable legal or regulatory obligations. If you experience any adverse reaction to a Product, or are unsatisfied with a Product that you believe may be defective, please contact BZAM as soon as possible at the coordinates below.</p>
                      <p>BZAM will use commercially reasonable efforts to ensure that prices, Product descriptions, details and depictions are accurate, complete and up-to-date, but does not guarantee any of the foregoing. In the event any information is inaccurate or incomplete, BZAM will not be obligated to provide such Product, and any payments accepted by BZAM will be refunded as soon as reasonably possible. Prices for Products offered by BZAM on this Website, or otherwise, are subject to change, without notice, and (unless otherwise indicated) are quoted in Canadian dollars exclusive of applicable taxes, duties and shipping costs. You may order Products for shipment solely within Canada. Title to Products shall pass to you, the purchaser, upon transfer to the courier. BZAM is not responsible for Products that are undeliverable, or delivered to an incorrect address, as a result of inaccurate or incomplete information provided by you. Products that are returned to BZAM due to being refused, unclaimed (i.e. not picked up), or as a result of incorrect information, may be subject to additional shipping charges.</p>
                      <p><strong>Disclaimer and Limitation of Liability</strong></p>
                      <p>THE WEBSITE AND THE CONTENT ARE PROVIDED "AS IS" WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND. USE OF THE WEBSITE OR THE CONTENT IS AT YOUR OWN RISK. BZAM MAKES NO REPRESENTATIONS, WARRANTIES OR CONDITIONS ABOUT THE QUALITY, ACCURACY, RELIABILITY, COMPLETENESS, CURRENCY, OR TIMELINESS OF THE WEBSITE OR THE CONTENT. BZAM DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY ERRORS, OMISSIONS OR INACCURACIES IN THE WEBSITE, THE CONTENT OR THE SERVICES.</p>
                      <p>TO THE FULLEST EXTENT PERMITTED BY LAW, BZAM DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY KIND WITH RESPECT TO THE WEBSITE, THE CONTENT AND THE SERVICES, WHETHER EXPRESS, IMPLIED OR COLLATERAL, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OR THAT THE WEBSITE OR THE CONTENT ARE OR WILL BE ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION. IN NO EVENT WILL BZAM BE LIABLE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER LEGAL THEORY, FOR ANY DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE DAMAGES, LOST PROFITS, LOSS OF USE, LOSS OF DATA, BREACH OF PRIVACY, PERSONAL INJURY, FINES, FEES, PENALTIES OR OTHER LIABILITIES), WHETHER OR NOT BZAM IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM THE USE OF, OR THE INABILITY TO MAKE USE OF, THE WEBSITE, THE CONTENT OR THE SERVICES.</p>
                      <p>BZAM’S TOTAL AGGREGATE LIABILITY TO YOU IN CONNECTION WITH OR RELATED TO ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING FROM, RELATED TO, OR IN CONNECTION WITH THESE TERMS AND CONDITIONS, THE WEBSITE, CONTENT, SERVICES OR ANY OF BZAM’S PRODUCTS, SHALL BE THE LESSER OF $50CAD AND THE AMOUNT PAID BY YOU FOR ANY SERVICES OR PRODUCTS SOLD TO YOU DIRECTLY BY BZAM THROUGH THE WEBSITE IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE CAUSE OF ACTION GIVING RISE TO SUCH LIABILITY.</p>
                      <p><strong>Intellectual Property Ownership</strong></p>
                      <p>All Content, designs, illustrations, software, artwork, video, music, sound, names, words, titles, phrases, logos and marks displayed on the Website or in the Content are owned and maintained by BZAM and are protected by copyright, trade-mark and other intellectual property laws and treaty provision laws. Any unauthorized publication, use, copying or modification of the Website or the Content, including trademarks, tradenames and design marks, may violate applicable legislation and may result in legal action.</p>
                      <p><strong>Privacy</strong></p>
                      <p>BZAM is committed to respecting the privacy and personal information of all persons with whom BZAM does business. Accordingly, BZAM’s Privacy Policy describes how BZAM collects, uses and discloses the personal information of those individuals who access or use the Website or the Services. Please see BZAM’s Privacy Policy, available on the Website, for further information. You acknowledge and agree that access to and use of the Website and the Services is primarily provided online, and that your information, including personal information, may be transferred and stored as set out in the Privacy Policy.</p>
                      <p><strong>Communications Through This Website</strong></p>
                      <p>The internet is not a fully secure medium and any communications may be lost, intercepted or altered. BZAM is not liable for any damages related to communications to, or from, this Website or the Services.</p>
                      <p><strong>Third Party Websites </strong></p>
                      <p>The Website may, for convenience purposes only, provide links to third party websites or services that are not owned or controlled by BZAM. BZAM does not endorse the information contained in those websites, or guarantee their quality, accuracy, reliability, completeness, currency, timeliness, non-infringement, merchantability or fitness for any purpose. The content in any linked website is not under BZAM’s control and if you choose to access any such website, you do so solely at your own risk.</p>
                      <p><strong>Error, Omissions and Inaccuracies</strong></p>
                      <p>BZAM does not guarantee, or accept any responsibility or liability for, the accuracy, currency or completeness of the Content or the Website, including with respect to any information, data, prices, illustrations, specifications, availability, Products or the Services. BZAM reserves the right to change or update the Website and the Content to correct errors, inaccuracies, or omissions at any time without prior notice. The Content contained on the Website does not constitute a solicitation or an offering of securities in any jurisdiction. Due to online technical and security risks, the information set out in the Website should not be used for the purpose of making any decisions with respect to investments related to BZAM or the Content.</p>
                      <p><strong>Indemnity</strong></p>
                      <p>IN CONSIDERATION FOR ACCESSING OR USING THE WEBSITE, YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS BZAM ITS OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, CONSULTANTS, ADVISORS, REPRESENTATIVES AND AGENTS, FROM AND AGAINST ANY LOSSES, LIABILITIES, CLAIMS, DEMANDS, THREATS, ACTIONS, PROCEEDINGS, EXPENSES (INCLUDING REASONABLE LEGAL FEES AND COURT COSTS) IN ANY WAY ARISING FROM, RELATED TO, OR IN CONNECTION WITH YOUR USE OF THE WEBSITE, INCLUDING IN CONNECTION WITH ANY PRODUCTS OR SERVICES OFFERED THROUGH THE WEBSITE OR THE USE OF ANY CONTENT OR INFORMATION CONTAINED IN OR OBTAINED THROUGH THE WEBSITE, THE SERVICES, YOUR VIOLATION OF THE TERMS AND CONDITIONS OR ANY APPLICABLE LAW OR REGULATION, OR THE POSTING, COMMUNICATION OR TRANSMISSION OF ANYTHING ON OR THROUGH THE WEBSITE BY YOU, INCLUDING BUT NOT LIMITED TO, ANY THIRD PARTY CLAIM THAT ANY INFORMATION OR MATERIALS PROVIDED BY YOU BREACH APPLICABLE LAW OR INFRINGE UPON ANY THIRD PARTY PROPRIETARY OR OTHER RIGHTS.</p>
                      <p><strong>Entire Agreement</strong></p>
                      <p>These Terms and Conditions, and any documents, materials or information expressly referred to in them represent the entire agreement between you and BZAM with respect to the subject matter set out herein, and supersede any prior agreement, understanding or arrangement between you and BZAM, whether oral or in writing. You acknowledge that, in entering into these Terms and Conditions, neither you nor BZAM have relied on any representation, undertaking or promise given by the other or implied from anything said or written between you and us prior to such Terms, except as expressly stated in these Terms and Conditions.</p>
                      <p><strong>Termination</strong></p>
                      <p>BZAM may terminate or suspend access to the Website or to the Services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach these Terms and Conditions. All provisions of these Terms and Conditions shall survive termination. Upon termination, your right to use the Website and receive the Services will immediately cease.</p>
                      <p><strong>Miscellaneous</strong></p>
                      <p>Any headings and titles herein are for convenience only. These Terms and Conditions are not assignable, transferable or sub-licensable except with the prior written consent of BZAM. BZAM may assign, transfer or convey these Terms and Conditions, or any of BZAM’s rights thereunder, to a third party without notice to you. Any failure by BZAM to exercise or enforce any rights under these Terms and Conditions will not constitute a waiver of such right. A waiver by BZAM of any default shall not constitute a waiver of any subsequent default. No waiver by BZAM is effective unless it is communicated to you in writing. If and solely to the extent that any court or tribunal of competent jurisdiction holds any provision of these Terms and Conditions to be unenforceable in a final non-appealable order, such unenforceable provision shall be stricken and the remainder of these Terms and Conditions shall not be affected. Any such stricken provision shall, to the extent possible, be replaced with a provision that is enforceable and that comes as close as possible to expressing the intention of the original provision.</p>
                      <p><strong>Contact</strong></p>
                      <p>If you have any questions or concerns regarding these Terms and Conditions, the Website, the Services, or any of BZAM’s products, please contact us at:</p>
                      <p>T: 1-844-256-BZAM (2926); or<br />
                      E: hello@bzam.com</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>{/* .bgtfw container */}
          </div>{/* .entry-content */}
          <footer className="entry-footer">
            <div className="bgtfw container">
            </div>
          </footer>{/* .entry-footer */}

        </article>{/* #post-## */}
      </div>
    </div>
  </main>{/* /.main */}

  <LiveChat license={13454061} />
  
</div>

        )
    }
}

export default TermsAndConditions;