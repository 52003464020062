import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import FuzzyHammerPicture from '../../../../assets/img/flavours/TableTop_FuzzyHammer.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './FuzzyHammer.css'

const ThisDescription = `They made fun of you for your cargo shorts, but they didn’t know it was all leading up to this moment. TABLE TOP Fuzzy Hammer. Sativa-dominant, loaded with berry, floral and menthol flavour. Fits perfectly in your ounce-sized pockets. Nailed it.`;

const ThisSlug = 'fuzzy-hammer';


class FuzzyHammer extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Fuzzy Hammer Dried Flower | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Sativa-dominant Fuzzy Hammer dried flower from TABLE TOP. Find it at your local cannabis shop.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        document.body.classList.add('individual-strain-page--'+ThisSlug);

    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page--'+ThisSlug);
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='FuzzyHammerPage'>

                        <div className='FuzzyHammerContent'>
                            <h1 className='ProductTitle'><img src={FuzzyHammerPicture} alt='TABLE TOP™ Fuzzy Hammer' /></h1>
                            <div className='FuzzyHammerText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            9LB Hammer
                                        </th>
                                        <th>
                                            Sativa Dominant
                                        </th>
                                        <th>
                                            Berry
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Velvet Bud
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Floral
                                        </th>
                                        <th>
                                            28g {/* product size 1 */}
                                        </th>
                                        <th>
                                            22-28% {/* thc % for product size 1 */}
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                             {/* lineage 3 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Menthol
                                        </th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            Pinene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>

                                        </th>
                                    </tr><tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='FuzzyHammerPage'>
                        <div className='FuzzyHammerContent'>

                            <h1 className='ProductTitle'><img src={FuzzyHammerPicture} alt='TABLE TOP™ Fuzzy Hammer' /></h1>
                            <div className='FuzzyHammerText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>9LB Hammer</th>
                                        <th>Berry</th>
                                    </tr>
                                    <tr>
                                        <th>Velvet Bud</th>
                                        <th>Floral</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Menthol</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Sativa Dominant</th>
                                        <th></th>
                                    </tr>
                                    
                                    <br></br>
                                    
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>22-28%</th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Pinene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>

                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default FuzzyHammer;