import React from 'react'

import './Background.css'

class AboutBackground extends React.Component {
    render() {
        return (
            <div className='AboutBackground'>
            </div>
        )
    }
}

export default AboutBackground; 