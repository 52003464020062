import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import YogurtPicture from '../../../../assets/img/flavours/TableTop_Yogurt.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './Yogurt.css'

const ThisDescription = `A flower called Yogurt? Yeah, you heard right. This one’s for folks who like it dank and cheesy. What else would you expect when UK Cheese and Forbidden Fruit bump uglies in the grow room? This funky hybrid, that’s what.`;

const ThisSlug = 'yogurt';


class Yogurt extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Yogurt Pre-Rolls | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Hybrid Yogurt pre-rolls from TABLE TOP. Find it at your local cannabis shop.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

        document.body.classList.add('individual-strain-page--'+ThisSlug);

    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page--'+ThisSlug);
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='YogurtPage'>

                        <div className='YogurtContent'>
                            <h1 className='ProductTitle'><img src={YogurtPicture} alt='TABLE TOP™ Yogurt' /></h1>
                            <div className='YogurtText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            UK Cheese
                                        </th>
                                        <th>
                                            Hybrid
                                        </th>
                                        <th>
                                            Dank
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Forbidden Fruit
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Herbal
                                        </th>
                                        <th>
                                            14g {/* product size 1 */}
                                        </th>
                                        <th>
                                            20-26% {/* thc % for product size 1 */}
                                        </th>
                                        <th>
                                            Terpinolene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                             {/* lineage 3 */}
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Cheesy
                                        </th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            Ocimene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                            Earthy
                                        </th>
                                        <th id='FeatureSubHeaders'>

                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            
                                        </th>
                                        <th>
                                            Myrcene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">
                                            Pre-Rolls
                                        </th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">
                                            Pre-Rolls
                                        </th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">
                                            Pre-Rolls
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th>
                                            7 x 0.5g
                                        </th>
                                        <th>
                                            20-26%
                                        </th>
                                        <th>
                                            Terpinolene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            Ocimene
                                        </th>
                                    </tr><tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            Myrcene
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<OGKushBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='YogurtPage'>
                        <div className='YogurtContent'>

                            <h1 className='ProductTitle'><img src={YogurtPicture} alt='TABLE TOP™ Yogurt' /></h1>
                            <div className='YogurtText'>
                                {ThisDescription}
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>UK Cheese</th>
                                        <th>Dank</th>
                                    </tr>
                                    <tr>
                                        <th>Forbidden Fruit</th>
                                        <th>Herbal</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Cheesy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Earthy</th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Hybrid</th>
                                        <th></th>
                                    </tr>
                                    
                                    <br></br>
                                    
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                    </tr>
                                    <tr>
                                        <th>14g</th>
                                        <th>20-26%</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">Pre-Rolls</th>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">Pre-Rolls</th>
                                    </tr>
                                    <tr>
                                        <th>7 x 0.5g</th>
                                        <th>20-26%</th>
                                    </tr>
                                    
                                    <br></br>

                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Terpinolene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Ocimene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Myrcene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders' className="feature-sub-headers-extra-top-padding">Pre-Rolls</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Terpinolene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Ocimene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Myrcene</th>
                                        <th></th>
                                    </tr>

                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default Yogurt;