import React from 'react'

import './Background.css'

class BuyBackground extends React.Component {
    render() {
        return (
            <div className='BuyBackground'>
                
            </div>
        )
    }
}

export default BuyBackground;