import React from 'react'

import './Background.css'

class CactusBreathBackground extends React.Component {
    render() {
        return (
            <div className='CactusBreathBackground'>
            </div>
        )
    }
}

export default CactusBreathBackground; 