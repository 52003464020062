import React from 'react'

import { Link } from 'react-router-dom';

import './MobileFooter.css'

import ViewerCounter from '../../ViewerCounter/ViewerCounter'
// import AnimatedBook from '../../../../assets/img/animated-book.gif'
import EmailIconSpinningMailbox from '../../../../assets/img/email-icon-spinning-mailbox.gif'
import GuestBook from '../../../../assets/img/guestbook-button.svg' //Add the guestbook button into DOM later
import VisitorButton from '../../../../assets/img/visitor-button.svg'

class MobileFooter extends React.Component {
    render() {
        return (
            <div className='MobileFooter'>
                <div className='MobileFooterVisitors'>
                    <div>
                        <Link to='/newsletter'>
                            <img id='Guestbook' src={EmailIconSpinningMailbox} alt='Newsletter' />
                        </Link>
                    </div>
                    <div>
                        <Link to='/guestbook'>
                            <img id='Guestbook' src={GuestBook} alt='Guest Book' />
                        </Link>
                    </div>
                    <div>
                        <div className='VisitorsDiv'>
                            <img id='Visitors' src={VisitorButton} alt='Visitor Count' />
                            <div className='VisitorsText'>
                                <ViewerCounter />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='MobileFooterSocialMedia'>
                    <div>
                        <a href='https://www.facebook.com/tabletopsocial' target="_blank" rel="noreferrer">FACEBOOK</a>
                    </div>
                    <div>
                        <a href='https://www.instagram.com/tabletop_social/' target="_blank" rel="noreferrer">INSTAGRAM</a>
                    </div>
                    {/*<div>
                        <a href='https://www.google.ca' target="_blank" rel="noreferrer">YOUTUBE</a>
                    </div>*/}
                </div>
                <div className='MobileConstantTermsAndConditions'>
                    <div className='MobileLeftDisclaimer'>
                        <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a> | <a href='https://bzam.com/investors/share-information/' target='_blank'>Investors</a>
                    </div>
                    <div className='MobileRightDisclaimer'>
                    © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileFooter;

<ul className='SocialMedia'>
    <li className='ListItem'>
        <a href='https://www.facebook.com/tabletopsocial' target="_blank" rel="noreferrer">FACEBOOK</a>
    </li>
    <li className='ListItem'>
        <a href='https://www.instagram.com/tabletop_social/' target="_blank" rel="noreferrer">INSTAGRAM</a>
    </li>
    {/*<li className='ListItem'>
        <a href='https://www.google.ca' target="_blank" rel="noreferrer">YOUTUBE</a>
    </li>*/}
    <li className='ListItem'>
        {/*<img id='AnimatedBook' src={AnimatedBook} alt='Button' />*/}
    </li>
    <li className='ListItem'>
        <div className='VisitorsDiv'>
            <img id='Visitors' src={VisitorButton} alt='Visitor Count' />
            <div className='VisitorsText'>
                <ViewerCounter />
            </div>
        </div>
    </li>
</ul>