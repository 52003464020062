import React from 'react'

import { Link } from 'react-router-dom'

import PurplePunchBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import PurplePunchPicture from '../../../../assets/img/flavours/TableTop_PurplePunch.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './PurplePunch.css'

const ThisSlug = 'purple-punch';

class PurplePunch extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Purple Punch Dried Flower | TABLE TOP Cannabis";

        // add/update the meta description
        let tab_page_description     = `Purple Punch from TABLE TOP is an indica strain with purple flavour and Larry OG x Grandaddy Purple lineage. Pick it up at your fave local or ask them to get it!`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already
    
        document.body.classList.add('individual-strain-page--'+ThisSlug);
    }

    componentWillUnmount() {
        document.body.classList.remove('individual-strain-page--'+ThisSlug);
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    {/*<PurplePunchBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='PurplePunchPage'>
                        <div className='PurplePunchContent'>
                            <h1 className='ProductTitle'><img src={PurplePunchPicture} alt='TABLE TOP™ Purple Punch' /></h1>
                            <div className='PurplePunchText'>
                                Looks like purple stuff. Tastes like purple stuff. Yup, it’s TABLE TOP Purple Punch – our citrus, blueberry and grape flaovured, indica-dominant hybrid with purple buds, orange hairs and a generous dusting of trichomes.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Larry OG
                                        </th>
                                        <th>
                                            Indica Dominant
                                        </th>
                                        <th>
                                            Citrus
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Grandaddy Purple
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Spicy
                                        </th>
                                        <th>
                                            3.5g
                                        </th>
                                        <th>
                                            20-23% 
                                        </th>
                                        <th>
                                            Farnesene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Earthy
                                        </th>
                                        <th>
                                            7g
                                        </th>
                                        <th>
                                            20-23% 
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            28g
                                        </th>
                                        <th>
                                            16-20%
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
        else {
            return (
                <div>
                    {/*<PurplePunchBackground />*/}
                    {/*<Header />*/}
                    <FlavoursNavBar />
                    <div className='PurplePunchPage'>
                        <div className='PurplePunchContent'>
                            <h1 className='ProductTitle'><img src={PurplePunchPicture} alt='TABLE TOP™ Purple Punch' /></h1>
                            <div className='PurplePunchText'>
                                Looks like purple stuff. Tastes like purple stuff. Yup, it’s TABLE TOP Purple Punch – our citrus, blueberry and grape flaovured, indica-dominant hybrid with purple buds, orange hairs and a generous dusting of trichomes.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Larry OG</th>
                                        <th>Citrus</th>
                                    </tr>
                                    <tr>
                                        <th>Grandaddy Purple</th>
                                        <th>Spicy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Earthy</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Indica Dominant</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                    </tr>
                                    <tr>
                                        <th>3.5g</th>
                                        <th>20-23%</th>
                                    </tr>
                                    <tr>
                                        <th>7g</th>
                                        <th>20-23%</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>16-20%</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Farnesene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default PurplePunch;