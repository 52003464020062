/* eslint-disable */
import React from 'react'

import NewsletterBackground from './Background/Background'
import Header from '../BasePage/Header/Header'
import { Link } from 'react-router-dom'

import MobileFooter from '../BasePage/MobileSideBar/MobileFooter/MobileFooter.js';

// import GoButton from '../../assets/img/hover_buttons/Go.svg'
// import GoButtonHover from '../../assets/img/hover_buttons/GoHover.svg'

import MobileNewsletter from './MobileNewsletter/MobileNewsletter'

import TableTopLogoText from '../../assets/img/tabletop-logo-text.svg'

import $ from 'jquery';

// import './test.js'

import './Newsletter.css'

class Newsletter extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------

    
    componentDidMount() {

        document.title = "TABLE TOP™ Cannabis Newsletter | It’s Got Legs™";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already




        // Feb 02 2023 - attempt to load external JS - doesn't work because jQuery won't work when we load an external JS file for some inexplicable reason, even thbough we have "import jQuery" stuff set up properly in this file, which we know because the jQuery "inlined" DOES run properly but the exact same jquery loaded externally won't work at all
        // const script = document.createElement("script");
        // script.src   = "/NameOfJSFile.js";
        // script.async = true;
        // document.body.appendChild(script);


        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // start of Feb 02 2023 newsletter stuff
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------




        // -----------------------------------------------------------------------------
        // Shake effect
        // -----------------------------------------------------------------------------

        var currently_shaking = false; 

        function shake(thing_to_shake)
        {

          $(thing_to_shake).css('border-color', 'red');
          return;

          // below is hidden for the TT site because we'd have to add this jQuery stuff first (like effect/shake), so we just highlight bad fields with red outlines instead
          /*currently_shaking = true;
          $(thing_to_shake).effect('shake', {distance: 2}, 600);
          // wait until the effect finishes before allowing button clicks again
          $(thing_to_shake).promise().done(function()
          {
            currently_shaking = false;
          });*/
        } // end shake function



        // -----------------------------------------------------------------------------
        // Validate email
        // -----------------------------------------------------------------------------

        function is_email(email_address)
        {
          // first do the basic checks
          //if ( !email_address || email_address == undefined || email_address == "Email Address*" )
          //  return false
          // then the regex check
          var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          return regex.test(email_address);
        }



        // -----------------------------------------------------------------------------
        // To run after page loaded
        // -----------------------------------------------------------------------------

        $(function()
        {

          
          // must set all vars here here or React will have a fit
          let shake_if_invalid         = false;
          let submit_newsletter_form   = false;
          let newsletter_form_is_valid = false;
          let the_delay;


          // -----------------------------------------------------------------------------
          // bzam report form (newsletter form)
          // -----------------------------------------------------------------------------

          // -----------------------------------------------------------------------------
          // just for modal - close modal
          // -----------------------------------------------------------------------------

          $('[data-newsletter-modal-close]').on('click', function()
          {
            $('[data-newsletter-modal]').attr('data-newsletter-modal', 'hidden');
            return false;
          });

          // -------------------------------------
          // Province dropdown
          // -------------------------------------

          function toggle_newsletter_province_dropdown()
          {
            // toggle the dropdown
            $('[data-newsletter-province-dropdown]').toggle();
            
            // square off bottom if dropdown showing, otherwise rounded bottom
            if( $('[data-newsletter-province-dropdown]').is(':visible') )
              $('[data-newsletter-province-dropdown-toggle]').attr('data-newsletter-province-dropdown-toggle', 'on'); // squares off the bottom
            else
              $('[data-newsletter-province-dropdown-toggle]').attr('data-newsletter-province-dropdown-toggle', 'off'); // fully rounded
          }


          // -------------------------------------
          // "Province" dropdown button clicked on
          // -------------------------------------

          $('[data-newsletter-province-dropdown-toggle]').on('click', function()
          {
            // alert('test');
            toggle_newsletter_province_dropdown();
            return false;
          });


          // -------------------------------------
          // individual province clicked on (BC, Alberta, ...)
          // -------------------------------------

          $('[data-newsletter-province]').on('click', function()
          {

            // clear red border if showing
            $('[data-newsletter-province-dropdown-toggle]').css('border', '');

            // set only this one as selected
            $('[data-newsletter-province]').attr('data-newsletter-province-selected', 'false');
            $(this).attr('data-newsletter-province-selected', 'true');
            
            // reset the rounded edges of Province dropdown
            $('[data-newsletter-province-dropdown-toggle]').attr('data-newsletter-province-dropdown-toggle', 'off');
            
            // update text ("Alberta" instead of "Province" for example)
            $("[data-newsletter-province-selected-span]").html( $(this).html() ); // update the text of the main button 
            // css will hide the currently selected province
            
            // hide dropdown
            $("[data-newsletter-province-dropdown]").hide();

            validate_newsletter_form( shake_if_invalid = false, submit_newsletter_form = false );
            
            return false;
          });

          // -------------------------------------
          // newsletter form checkboxes (red border if not filled in)
          // -------------------------------------

          $('[data-newsletter-agree-to-newsletter-checkbox], [data-newsletter-read-privacy-policy-checkbox]').change(function()
          {
            // if they've left this box unchecked, highlight it red
            if ( $(this).is(':checked') )
            {
              $(this).parent().find('[data-newsletter-checkbox-pretty]').css({ 'border': '' });
              // console.log('checked');
            }
            else
            {
              $(this).parent().find('[data-newsletter-checkbox-pretty]').css({ 'border': '1px solid red' });
              // console.log('not checked');
            }

            // if both boxes are checked, validate the form
            // if ( $('[data-newsletter-agree-to-newsletter-checkbox]').is(':checked') && $('[data-newsletter-read-privacy-policy-checkbox]').is(':checked') )
              validate_newsletter_form( shake_if_invalid = false, submit_newsletter_form = false );
          });


          // -------------------------------------
          // newsletter form validator
          // -------------------------------------

          function validate_newsletter_form( shake_if_invalid = false, submit_newsletter_form = false )
          {

            // prevent someone from submitting twice
            if( $('[data-newsletter-submit]').attr('data-newsletter-submit') === 'submitting' )
              return false;

            // set the marker so we know we're in the process of submitting
            $('[data-newsletter-submit]').attr('data-newsletter-submit', 'submitting');

            newsletter_form_is_valid = true;

            var first_name    = $('[data-newsletter-first-name]').val();
            var last_name     = $('[data-newsletter-last-name]').val();
            var email_address = $('[data-newsletter-email-address]').val();
            var province      = $('[data-newsletter-province-selected="true"]').attr('data-newsletter-province');

            // sanitize province string
            if ( province )
              province = province.replace('&amp;', '__amp').replace('&', '__amp');
            // console.log('province '+province);

            // if anything is blank, don't enable the submit button, and maybe shake it (and add border if checkbox)
            // we only shake if they've hit submit, not if they've just left a field and so we're validating things
            if ( first_name == '' )
            {
              if( shake_if_invalid )
                shake( $('[data-newsletter-first-name]') );
              newsletter_form_is_valid = false;
            }
            if ( last_name == '' )
            {
              if( shake_if_invalid )
                shake( $('[data-newsletter-last-name]') );
              newsletter_form_is_valid = false;
            }
            if ( email_address == '' || !is_email( email_address ) )
            {
              if( shake_if_invalid )
                shake( $('[data-newsletter-email-address]') );
              newsletter_form_is_valid = false;
            }
            if ( !$('[data-newsletter-province-selected="true"]').length )
            {
              if( shake_if_invalid )
                shake( $('[data-newsletter-province-dropdown-toggle], [data-newsletter-province-dropdown]') );
              newsletter_form_is_valid = false;
            }
            if ( !$('[data-newsletter-agree-to-newsletter-checkbox]').is(':checked') )
            {
              if( shake_if_invalid )
                shake( $('[data-newsletter-agree-to-newsletter]') );
              $('[data-newsletter-agree-to-newsletter-checkbox]').parent().find('[data-newsletter-checkbox-pretty]').css({ 'border': '1px solid red' });
              newsletter_form_is_valid = false;
            }
            if ( !$('[data-newsletter-read-privacy-policy-checkbox]').is(':checked') )
            {
              if( shake_if_invalid )
                shake( $('[data-newsletter-read-privacy-policy]') );
              $('[data-newsletter-read-privacy-policy-checkbox]').parent().find('[data-newsletter-checkbox-pretty]').css({ 'border': '1px solid red' });
              newsletter_form_is_valid = false;
            }


            // if everything is valid, enable submit button 
            if ( newsletter_form_is_valid )
            {
              // console.log('Form would be submittable in this case (email would be sent)');
              $('[data-newsletter-submit-arrow]').attr('src', '/img/newsletter__submit-arrow.svg');
              $('[data-newsletter-submit]').attr('data-newsletter-submit', '').attr('data-newsletter-submit-link', 'enabled');
            }
            // if everything wasn't filled in correctly, remove the "submitting" marker so user can try to submit again
            else
            {
              // delay between disabling should be longer if they hit submit so things stop shaking before submit can be pressed again
              the_delay = submit_newsletter_form ? 1000 : 10 ;
              // delay that slightly so that repeated clicks don't re-trigger this too often
              setTimeout( function()
              {
                $('[data-newsletter-submit]').attr('data-newsletter-submit', '').attr('data-newsletter-submit-link', 'disabled');
                $('[data-newsletter-submit-arrow]').attr('src', '/img/newsletter__submit-arrow--disabled.svg');
              }, the_delay );
            }


            // if everything is valid AND they are submitting the form, submit it
            if ( newsletter_form_is_valid && submit_newsletter_form )
            {

              $('[data-newsletter-submit]').attr('data-newsletter-submit', 'submitting').html('SUBMITTING...');

              var site_language = "EN"; // might use this info in future

              let the_data = "form=newsletter_form&site_language="+site_language+"&first_name="+first_name+"&last_name="+last_name+"&email_address="+email_address+"&province="+province;
              // console.log('going to submit '+the_data); return false;

              $.ajax(
              {
                url: '/inc/add-to-klaviyo-list.inc.php',
                data: the_data,
                method: 'POST',
                context: document.body
              }).done(function( response_text )
              {
                // prevent page from jumping by maintaning the height here
                $('[data-newsletter-form-container]').css( 'height', $('[data-newsletter-form-container]').outerHeight() );

                // show the text that PHP returned to the user
                $('[data-newsletter-thanks-for-joining]').html(response_text);

                // fade out the form and fade in the message
                $('[data-newsletter-form]').fadeOut().promise().done(function()
                {
                  $('[data-newsletter-thanks-for-joining]').fadeIn();
                });
                
                // console.log( 'response: '+response_text );

              }); // end ajax.done

              return false;
            }

            

          } // end function validate_newsletter_form


          // -------------------------------------
          // newsletter form - user left field (so validate)
          // -------------------------------------

          $('[data-newsletter-input]').on('blur', function()
          {
            if( $(this).val() )
              $(this).css('border-color', '');
            validate_newsletter_form( shake_if_invalid = false, submit_newsletter_form = false );
          });


          // -------------------------------------
          // newsletter form submitted
          // -------------------------------------

          $('[data-newsletter-submit-link]').on('click', function()
          {
            event.preventDefault();
            // alert('submitted');
            validate_newsletter_form( shake_if_invalid = true, submit_newsletter_form = true );
          });







        }); // end jQuery to run when page loaded

        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // end of Feb 02 2023 newsletter stuff
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------
        // -----------------------------------------------------------------------------




        
        /*
        // Toggle element visibility
        var toggleVisibility = function (elem) {
            elem.classList.toggle('is-visible');
        };

        // "British Columbia" clicked on - show the extra popup / infobox
        document.addEventListener('click', function (event) {

            // If the clicked element doesn't have the right selector, bail
            if ( !event.target.matches('#GoButton') ) return;

            var selectedValue = document.getElementById('Province').value;

            if( selectedValue !== 'js-find-in-bc' )
                window.open(selectedValue, '_blank');

            // Don't follow the link
            event.preventDefault();

            // toggle the popup
            // toggleVisibility( document.getElementById('js-find-in-bc-infobox') );

            // Log the clicked element in the console
            // console.log(event.target);

        }, false);
    
        */

        document.body.classList.add('newsletter-page');
    }


    componentWillUnmount() {
        document.body.classList.remove('newsletter-page');
    }

    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------



    render() {
        /*let width = window.innerWidth;
        if (width > 700) {*/
            return (


                <div>

                    {/* this must exist, but it can be empty I think, otherwise the mobile menu doesn't top-align properly on this page, because of how this was all originally set up */}
                    <div className='TableTopLogoMain  TableTopLogoMain--newsletter'>
                        <img src={TableTopLogoText} alt='TableTop Logo' />
                    </div>

                    <div className="newsletter-header-wrapper">
                      <h1 id="HeaderText" className="HeaderText--newsletter-desktop">GET MEDIUM VIBES IN YOUR INBOX</h1>
                      <h1 id="HeaderText" className="HeaderText--newsletter-mobile"><span>GET</span><span>MEDIUM</span><br/><span>VIBES</span><span>IN</span><br/><span>YOUR</span><span>INBOX</span></h1>
                    </div>

                    <div className="newsletter-spacer"></div>

                    <div className="newsletter" data-newsletter-form-container>

                      <div className="newsletter__heading-wrapper">
                        {/*<h1 className="section__heading  newsletter__heading">Get medium vibes in your inbox</h1>*/}
                        <h3 className="newsletter__subheading">Do you like memes? Dumb table jokes? Hearing about new strains? You should probably sign up for our newsletter!! Just enter your info below and we’ll do the rest.</h3>
                      </div>

                      {/* <!-- thank-you message that only shows after successful submission --> */}
                      <p className="newsletter__thanks-for-joining" data-newsletter-thanks-for-joining>{/* <!-- JS fills this in --> */}</p>

                      <form className="newsletter__form" action="" data-newsletter-form>

                        <p className="newsletter__required">*Required</p>
                        
                        <div className="newsletter__form-inner">
                          <input type="text" className="newsletter__input" placeholder="First Name*" data-newsletter-input data-newsletter-first-name />
                          <input type="text" className="newsletter__input" placeholder="Last Name*" data-newsletter-input data-newsletter-last-name />
                          <input type="email" className="newsletter__input  newsletter__input--email" placeholder="Email Address*" data-newsletter-input data-newsletter-email-address />

                          {/* <!-- Province --> */}
                          <div className="newsletter__province-container">
                            <a href="#" className="newsletter__province-dropdown-toggle" data-newsletter-province-dropdown-toggle="off"><span className="newsletter__province-selected" data-newsletter-province-selected-span>Province*</span> <span className="newsletter__province-plus-symbol">+</span></a>
                            <div className="newsletter__province-dropdown" data-newsletter-province-dropdown="hidden">
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Alberta">Alberta</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="British Columbia">British Columbia</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Manitoba">Manitoba</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="New Brunswick">New Brunswick</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Newfoundland & Labrador">Newfoundland &amp; Labrador</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Northwest Territories">Northwest Territories</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Nova Scotia">Nova Scotia</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Nunavut">Nunavut</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Ontario">Ontario</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Prince Edward Island">PEI</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Quebec">Quebec</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Saskatchewan">Saskatchewan</a>
                              <a className="newsletter__province-link" href="#" data-newsletter-province-selected="false" data-newsletter-province="Yukon">Yukon</a>
                            </div>
                          </div> {/* <!-- /province-container --> */}
                        
                        </div> {/* <!-- /.newsletter__form-inner --> */}

                        <div className="newsletter__disclainers-and-submit">
                          
                          <div className="newsletter__disclainers">

                            <label className="newsletter__form-disclaimer-label" for="agree-to-newsletter" data-newsletter-agree-to-newsletter>
                              <input className="newsletter__form-disclaimer-checkbox" type="checkbox" id="agree-to-newsletter" name="agree-to-newsletter" data-newsletter-agree-to-newsletter-checkbox />
                              <span className="newsletter__form-disclaimer-checkbox-pretty" data-newsletter-checkbox-pretty></span>
                              <span className="newsletter__form-disclaimer-text">Yes, I agree to receive the newsletter and understand that I can opt out at any time by clicking the "unsubscribe" link at the bottom of the newsletter.</span>
                            </label>
                            
                            <label className="newsletter__form-disclaimer-label  newsletter__form-disclaimer-label--last" for="read-privacy-policy" data-newsletter-read-privacy-policy>
                              <input className="newsletter__form-disclaimer-checkbox" type="checkbox" id="read-privacy-policy" name="read-privacy-policy" data-newsletter-read-privacy-policy-checkbox />
                              <span className="newsletter__form-disclaimer-checkbox-pretty" data-newsletter-checkbox-pretty></span>
                              <span className="newsletter__form-disclaimer-text">Yes, I have read the <a className="newsletter__form-disclaimer-link" href="/privacy-policy" target="_blank"><u>Privacy Policy</u></a> and consent to the collection of my personal information, its use and disclosure in accordance with this Policy.</span>
                            </label>

                          </div>

                          <div className="newsletter__address-and-submit">

                            <div className="newsletter__address">
                                <p className="newsletter__address-text">BZAM Management Inc<br/>
                                200 Burrard Street, Suite 1570<br/>
                                Vancouver, British Columbia V6C&nbsp;3L6 Canada<br/>
                                <a className="newsletter__email-address-link" href="mailto:hello@bzam.com">hello@bzam.com</a></p>
                            </div>

                            <div className="newsletter__submit">

                                <a className="newsletter__submit-link" href="#" data-newsletter-submit data-newsletter-submit-link="disabled">
                                  SIGN UP
                                  {/* < !-- <img
                                    className="rectangle-button__image  rectangle-button__image--sign-me-up"
                                    src="/img/button-sign-me-up.svg?v=1"
                                    alt="SIGN ME UP"
                                    data-swap-image-on-hover
                                    data-image-enabled="/img/button-sign-me-up.svg?v=1"
                                    data-image-hovered="/img/button-sign-me-up--hovered.svg?v=1"
                                  > --> */}
                                </a>

                            </div>

                          </div>

                        </div> {/* <!-- /.newsletter__form-disclainers-and-submit --> */}

                      </form> {/* <!-- /.newsletter__form --> */}


                    
                      

                    </div> {/* <!-- /.newsletter --> */}
                
                    <MobileFooter />

                </div>
                





            )
        /*}
        else {
            return <MobileNewsletter />
        }*/

    }
}

export default Newsletter;