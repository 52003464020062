import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie'
import animationData from '../../assets/lotties/medium-vibes-only-v2.json'

import './Home.css' 

import { Link } from 'react-router-dom';

import Background from './Background/Background.js'
import MobileFooter from '../BasePage/MobileSideBar/MobileFooter/MobileFooter';
import Header from '../BasePage/Header/Header';

const defaultOptions = {
    autoplay: false,
    loop: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};



/*export type AnimationItem = {
    name: 'stuff';
    playSpeed: .1;
    autoplay: false;
    setSpeed(): .1;
}
*/

/*Lottie.loadAnimation({
      container: document.getElementById("MediumVibesOnlyLottie"),
      // renderer : 'svg',
      loop     : true,
      autoplay : true,
      animationData: animationData,
      rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
    }
});*/

/*useEffect(() => {
    var medium_vibes_only_lottie = Lottie.loadAnimation(
    {
      container: document.getElementById("MediumVibesOnlyLottie"),
      // renderer : 'svg',
      loop     : true,
      autoplay : true,
      speed: .1,
      animationData: animationData,
      rendererSettings:
      {
          preserveAspectRatio: "xMidYMid slice"
      }
    });
    medium_vibes_only_lottie.setSpeed(0.1);
}, []);*/






// -----------------------------------------------------------------------------
// adam added
// -----------------------------------------------------------------------------

// console.log('height ', window.innerHeight);

// -----------------------------------------------------------------------------
// end adam added
// -----------------------------------------------------------------------------



//Render sidebar and home here

class Home extends React.Component {
    
    // adam added
    componentDidMount() {

        document.title = "TABLE TOP™ Cannabis | Canadian Cannabis | Medium Vibes Only";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP™ cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already
    
        document.body.classList.add('home-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('home-page');
    }
    // end adam added

    render() {
        let width = window.innerWidth;
        
        // adam added
        let window_height = window.innerHeight; 
        let medium_vibes_only_top_margin = '240'; // (window_height/2) - (171/2);
        // adam added



        
        if (width > 700) {
            return (
                <div>
                    {/*<Background />*/}
                    {/*<Header />*/}
                    <div className='Home'>
                        <div className='Lottie' id='MediumVibesOnlyLottie'>
                            <Lottie isClickToPauseDisabled={true} options={defaultOptions} speed='.5' />
                        </div>
                    </div>

                </div>

            )
        }
        else {
            return (
                <div>
                    {/*<Background />*/}
                    {/*<Header />*/}
                    <div className='Home'>
                        <div className='Lottie' style={{marginTop: medium_vibes_only_top_margin+'px'}} > {/* adam added the style rules */}
                            <Lottie isClickToPauseDisabled={true} options={defaultOptions} />
                        </div>
                    </div>
                    <div className='HomeMobileFooter'>
                        <MobileFooter />
                    </div>
                    
                    {/*<div className='ConstantTermsAndConditions' style={{top: '200vh'}}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>*/}


                    



                </div>
            )
        }

    }

}

export default Home;