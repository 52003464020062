import React from 'react'

import './Header.css'

import TickerTapeChair from '../../../assets/img/ticker-tape-chair.png'

// const message = <p><img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!28 gram bags!!!<img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!28 gram bags!!!<img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!28 gram bags!!!<img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!28 gram bags!!!<img src={TickerTapeChair} alt='Table Top' /></p>
// const message = <p>NEW OUNCES!!!<img src={TickerTapeChair} alt='Table Top' />ALLEN WRENCH (NYC DIESEL X TRAINWRECK)!!!<img src={TickerTapeChair} alt='Table Top' />NEW OUNCES!!!<img src={TickerTapeChair} alt='Table Top' />SQUARE DANCE (1:1 THC:CBD)<img src={TickerTapeChair} alt='Table Top' /></p>
const message = <p>NEW!!!<img src={TickerTapeChair} alt='Table Top' />YOGURT 14G FLOWER + PRE-ROLLS!!!<img src={TickerTapeChair} alt='Table Top' />IT'S DANK!!</p>


class Header extends React.Component {
    render() {
        return (
            <div className="tcontainer"><div className="ticker-wrap"><div className="ticker-move">
                <div className="ticker-item">{message} 
                </div>
            </div></div></div>
        )
    }
}

export default Header;