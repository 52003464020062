import React from 'react'

import { Link } from 'react-router-dom'

import FlavoursBackground from '..//Background/Background'
import Header from '../../../BasePage/Header/Header';
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter'

//Flavour text
import DiamondDustOG from '../../../../assets/img/flavours/TableTop_DiamondDustOG.svg'
import OGKush from '../../../../assets/img/flavours/TableTop_90sOGKush.svg'
// import BlueLemonThai from '../../../../assets/img/flavours/TableTop_BlueLemonThai.svg'
// import AmherstSuperSkunk from '../../../../assets/img/flavours/TableTop_AmherstSuperSkunk.svg'
import SundaeDriver from '../../../../assets/img/flavours/TableTop_SundaeDriver.svg'
// import LakeTahoeOG from '../../../../assets/img/flavours/TableTop_LakeTahoeOG.svg'
import PurplePunch from '../../../../assets/img/flavours/TableTop_PurplePunch.svg'
// import SourKush from '../../../../assets/img/flavours/TableTop_SourKush.svg'
import StickyBuns from '../../../../assets/img/flavours/TableTop_StickyBuns.svg'
import GarlicBreath from '../../../../assets/img/flavours/TableTop_GarlicBreath.svg'
import GreaseMonkey from '../../../../assets/img/flavours/TableTop_GreaseMonkey.svg'
import GrilledCheese from '../../../../assets/img/flavours/TableTop_GrilledCheese.svg'
import GrandaddyBlack from '../../../../assets/img/flavours/TableTop_GrandaddyBlack.svg'
// import CactusBreath from '../../../../assets/img/flavours/TableTop_CactusBreath.svg';
// import GrapeDiamonds from '../../../../assets/img/flavours/TableTop_GrapeDiamonds.svg'
// import Moontang from '../../../../assets/img/flavours/TableTop_Moontang.svg'
// import DurbanLime from '../../../../assets/img/flavours/TableTop_DurbanLime.svg'
// import LemonDiesel from '../../../../assets/img/flavours/TableTop_LemonDiesel.svg'
// import ZombieKush from '../../../../assets/img/flavours/TableTop_ZombieKush.svg'
// import RaceFuelOG from '../../../../assets/img/flavours/TableTop_RaceFuelOG.svg'
// import Banjo from '../../../../assets/img/flavours/TableTop_Banjo.svg'
import JungleCake from '../../../../assets/img/flavours/TableTop_JungleCake.svg'

import SanFranSourDough from '../../../../assets/img/flavours/TableTop_SanFranSourDough.svg'
// import JustFineGrindBoldSativa from '../../../../assets/img/flavours/TableTop_JustFineGrindBoldSativa.svg'
// import JustFineGrindBoldIndica from '../../../../assets/img/flavours/TableTop_JustFineGrindBoldIndica.svg'
import AllenWrench from '../../../../assets/img/flavours/TableTop_AllenWrench.svg'
// import SquareDance from '../../../../assets/img/flavours/TableTop_SquareDance.svg'
// import JustFineGrindIndica from '../../../../assets/img/flavours/TableTop_JustFineGrindIndica.svg'
// import JustFineGrindSativa from '../../../../assets/img/flavours/TableTop_JustFineGrindSativa.svg'
// import KiwiKush from '../../../../assets/img/flavours/TableTop_KiwiKush.svg'

import CitySlicker from '../../../../assets/img/flavours/TableTop_CitySlicker.svg'
import FuzzyHammer from '../../../../assets/img/flavours/TableTop_FuzzyHammer.svg'
import Yogurt from '../../../../assets/img/flavours/TableTop_Yogurt.svg'


//'Learn more' buttons
import BeigeButton from '../../../../assets/img/button-beige.svg'
import BlackButton from '../../../../assets/img/button-black.svg'
import GreenButton from '../../../../assets/img/button-green.svg'
import PurpleButton from '../../../../assets/img/button-purple.svg'
import YellowButton from '../../../../assets/img/button-yellow.svg'
import BrownButton from '../../../../assets/img/button-brown.svg'
import BlueLearnMoreButton from '../../../../assets/img/button-blue-learn-more.svg' // needs weird name compared to others because there's a BlueButton already but it says "VERIFY" instead of "LEARN MORE"

//Each Strain Component

import './MobileDriedFlower.css'


const NewProductTickerTape = <div className={`NewProduct`}> <div className={`NewProduct__text  ticker-move-vertical`}> NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! &nbsp; NEW!!! </div> </div>


class MobileFlavours extends React.Component {
    render() {
        return (
            <div>
                <FlavoursBackground />
                <Header />
                <div className='MobileFlavours'>

                    <Link to='/products/city-slicker' className='MobileFlavourCard' style={{ backgroundColor: '#ffc62b' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={CitySlicker} alt='City Slicker' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlueLearnMoreButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/fuzzy-hammer' className='MobileFlavourCard' style={{ backgroundColor: 'var(--beige-darker)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={FuzzyHammer} alt='Fuzzy Hammer' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={GreenButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/yogurt' className='MobileFlavourCard' style={{ backgroundColor: '#1e80bf' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={Yogurt} alt='Yogurt' style={{width: '104px', paddingLeft: '46px', paddingRight: '46px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' />
                        </div>
                    </Link>

                    {/*<Link to='/products/80s-kiwi-kush' className='MobileFlavourCard' style={{ backgroundColor: 'var(--purple)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={KiwiKush} alt='Kiwi Kush' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={GreenButton} alt='Button' />
                        </div>
                    </Link>*/}

                    <Link to='/products/san-fran-sour-dough' className='MobileFlavourCard' style={{ backgroundColor: 'var(--beige-darker)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={SanFranSourDough} alt='San Fran Sour Dough' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>

                    {/*<Link to='/products/just-fine-grind-bold-sativa' className='MobileFlavourCard' style={{ backgroundColor: 'var(--purple)' }}>
                        {NewProductTickerTape}
                        <div className='MobileFlavourCardName'>
                            <img src={JustFineGrindBoldSativa} alt='Just Fine Grind BOLD Sativa' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlueLearnMoreButton} alt='Button' />
                        </div>
                    </Link>*/}

                    {/*<Link to='/products/just-fine-grind-bold-indica' className='MobileFlavourCard' style={{ backgroundColor: 'var(--green)' }}>
                        {NewProductTickerTape}
                        <div className='MobileFlavourCardName'>
                            <img src={JustFineGrindBoldIndica} alt='Just Fine Grind BOLD Indica' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>*/}

                    <Link to='/products/allen-wrench' className='MobileFlavourCard' style={{ backgroundColor: 'var(--beige)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={AllenWrench} alt='Allen Wrench' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlueLearnMoreButton} alt='Button' />
                        </div>
                    </Link>

                    {/*<Link to='/products/square-dance' className='MobileFlavourCard' style={{ backgroundColor: 'var(--green)' }}>
                        {NewProductTickerTape}
                        <div className='MobileFlavourCardName'>
                            <img src={SquareDance} alt='Square Dance' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>*/}

                    {/*<Link to='/products/just-fine-grind-sativa' className='MobileFlavourCard' style={{ backgroundColor: 'var(--brown)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={JustFineGrindSativa} alt='Just Fine Grind Sativa' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>*/}

                    {/*<Link to='/products/just-fine-grind-indica' className='MobileFlavourCard' style={{ backgroundColor: 'var(--yellow)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={JustFineGrindIndica} alt='Just Fine Grind Indica' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BrownButton} alt='Button' />
                        </div>
                    </Link>*/}

                    {/*<Link to='/products/grape-diamonds' className='MobileFlavourCard' style={{ backgroundColor: 'var(--grape-diamonds-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={GrapeDiamonds} alt='Grape Diamonds' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>
                    <Link to='/products/moontang' className='MobileFlavourCard' style={{ backgroundColor: 'var(--moontang-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={Moontang} alt='Moontang' style={{width: '158px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/durban-lime' className='MobileFlavourCard' style={{ backgroundColor: 'var(--durban-lime-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={DurbanLime} alt='Durban Lime' style={{width: '158px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/lemon-diesel' className='MobileFlavourCard' style={{ backgroundColor: 'var(--lemon-diesel-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={LemonDiesel} alt='Lemon Diesel' style={{width: '158px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/zombie-kush' className='MobileFlavourCard' style={{ backgroundColor: 'var(--zombie-kush-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={ZombieKush} alt='Zombie Kush' style={{width: '158px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/banjo' className='MobileFlavourCard' style={{ backgroundColor: 'var(--banjo-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={Banjo} alt='Banjo' style={{height: '24px', paddingLeft: '0', paddingRight: '0'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={GreenButton} alt='Button' />
                        </div>
                    </Link>

                    <Link to='/products/race-fuel-og' className='MobileFlavourCard' style={{ backgroundColor: 'var(--race-fuel-og-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={RaceFuelOG} alt='Race Fuel OG' style={{width: '158px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>*/}

                    {/*<Link to='/products/jungle-cake' className='MobileFlavourCard' style={{ backgroundColor: 'var(--jungle-cake-color)' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={JungleCake} alt='Jungle Cake' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>*/}
                   
                    {/*<Link to='/products/blue-lemon-thai' className='MobileFlavourCard' style={{ backgroundColor: '#0D6158' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={BlueLemonThai} alt='Blue Lemon Thai' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>*/}
                    <Link to='/products/purple-punch' className='MobileFlavourCard' style={{ backgroundColor: '#000000' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={PurplePunch} alt='Purple Punch' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>
                    <Link to='/products/sticky-buns' className='MobileFlavourCard' style={{ backgroundColor: '#EEBC34' }}>

                        <div className="BestSeller">
                            <div className="BestSeller__text  ticker-move-vertical">BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER! &nbsp; BESTSELLER!
                            </div>
                        </div>

                        <div className='MobileFlavourCardName'>
                            <img src={StickyBuns} alt='Sticky Buns' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' />
                        </div>
                    </Link>

                    {/*<Link to='/products/grease-monkey' className='MobileFlavourCard' style={{ backgroundColor: '#EEBC34' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={GreaseMonkey} alt='Grease Monkey' style={{width: '158px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={GreenButton} alt='Button' />
                        </div>
                    </Link>*/}

                    <MobileFooter />
                </div>

            </div>
        )
    }
}

export default MobileFlavours;