import React from 'react'

import './AGHeader.css'

import TickerTapeChair from '../../../../assets/img/ticker-tape-chair.png'

// const message = <p><img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!<span id='Censor'>28 gram bags</span>!!!<img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!<span id='Censor'>28 gram bags</span>!!!<img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!<span id='Censor'>28 gram bags</span>!!!<img src={TickerTapeChair} alt='Table Top' />NOW AVAILABLE<img src={TickerTapeChair} alt='Table Top' />!!!<span id='Censor'>28 gram bags</span>!!!<img src={TickerTapeChair} alt='Table Top' /></p>
// const message = <p>NEW OUNCES!!!<img src={TickerTapeChair} alt='Table Top' /><span id='Censor'>ALLEN WRENCH (NYC DIESEL X TRAINWRECK)</span>!!!<img src={TickerTapeChair} alt='Table Top' />NEW OUNCES!!!<img src={TickerTapeChair} alt='Table Top' /><span id='Censor'>SQUARE DANCE (1:1 THC:CBD)</span><img src={TickerTapeChair} alt='Table Top' /></p>
const message = <p>NEW!!!<img src={TickerTapeChair} alt='Table Top' /><span id='Censor'>YOGURT 14G FLOWER + PRE-ROLLS!!!</span><img src={TickerTapeChair} alt='Table Top' /><span id='Censor'>IT'S DANK!!</span></p>

class AGHeader extends React.Component {
    render() {
        return (
            <div className="tcontainer"><div className="ticker-wrap"><div className="ticker-move">
                <div className="ticker-item">{message} 
                </div>
            </div></div></div>
        )
    }
}

export default AGHeader;