import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import SundaeDriverPicture from '../../../../assets/img/flavours/TableTop_SundaeDriver.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './SundaeDriver.css'
class SundaeDriver extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "Sundae Driver Dried Flower | TABLE TOP";

        // add/update the meta description
        let tab_page_description     = `A sweet and creamy hybrid flower from TABLE TOP, Sundae Driver may tempt you to go Wednesday speed to your fave cannabis shop to get it. Bring your ID!`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added
    
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='SundaeDriverPage'>
                        <div className='SundaeDriverContent'>
                            <h1 className='ProductTitle'><img src={SundaeDriverPicture} alt='TABLE TOP™ Sundae Driver' /></h1>
                            <div className='SundaeDriverText'>
                                Backseat drivers are the worst. Sundae Driver on the other hand? Who said we gotta be in a rush all the time? Stop and smell the sweet and creamy hybrid flower once in a while, eh? That’s the spirit.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Fruity Pebbles OG
                                        </th>
                                        <th>
                                            Hybrid
                                        </th>
                                        <th>
                                            Sweet
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Grape Pie
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Mellow
                                        </th>
                                        <th>
                                            28g
                                        </th>
                                        <th>
                                            16-20% 
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Creamy
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Farnesene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='ConstantTermsAndConditions' style={{ top: '120vh' }}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='SundaeDriverPage'>
                        <div className='SundaeDriverContent'>
                            <h1 className='ProductTitle'><img src={SundaeDriverPicture} alt='TABLE TOP™ Sundae Driver' /></h1>
                            <div className='SundaeDriverText'>
                                Backseat drivers are the worst. Sundae Driver on the other hand? Who said we gotta be in a rush all the time? Stop and smell the sweet and creamy hybrid flower once in a while, eh? That’s the spirit.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Fruity Pebbles OG</th>
                                        <th>Sweet</th>
                                    </tr>
                                    <tr>
                                        <th>Grape Pie</th>
                                        <th>Mellow</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Creamy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Hybrid</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>16-20% </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Farnesene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default SundaeDriver;