import React from 'react'

import './Background.css'

class CitySlickerBackground extends React.Component {
    render() {
        return (
            <div className='CitySlickerBackground'>
            </div>
        )
    }
}

export default CitySlickerBackground; 