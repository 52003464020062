import React from 'react'

import './Background.css'

class Background extends React.Component {
    render() {
        return (
            <div className='FlavoursBackground'>
                
            </div>
        )
    }
}

export default Background;