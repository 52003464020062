import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import DiamondDustOGPicture from '../../../../assets/img/flavours/TableTop_DiamondDustOG.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './DiamondDustOG.css'
class DiamondDustOG extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "Diamond Dust OG Dried Flower | TABLE TOP";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP Diamond Dust OG dried flower is an indica with Sour Bubble x Matrix lineage and citrus, fruity and creamy flavour. Buy it by the ounce at weed stores.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added

    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='DiamondDustOGPage'>
                        <div className='DiamondDustOGContent'>
                            <h1 className='ProductTitle'><img src={DiamondDustOGPicture} alt='TABLE TOP™ Diamond Dust OG' /></h1>
                            <div className='DiamondDustOGText'>
                                Always look for the diamond in the dust. Is that how it goes? Anyway, this one is indica dominant, highly puffable and won’t set you back three months’ salary. Plus, it joins Sour Bubble and Matrix in holy matrimony. Amen to that.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Sour Bubble
                                        </th>
                                        <th>
                                            Indica Dominant
                                        </th>
                                        <th>
                                            Citrus
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Dried Flower
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Matrix
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Fruity
                                        </th>
                                        <th>
                                            28g
                                        </th>
                                        <th>
                                            16-20% 
                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Creamy
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Farnesene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='ConstantTermsAndConditions' style={{ top: '120vh' }}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='DiamondDustOGPage'>
                        <div className='DiamondDustOGContent'>
                            <h1 className='ProductTitle'><img src={DiamondDustOGPicture} alt='TABLE TOP™ Diamond Dust OG' /></h1>
                            <div className='DiamondDustOGText'>
                                Always look for the diamond in the dust. Is that how it goes? Anyway, this one is indica dominant, highly puffable and won’t set you back three months’ salary. Plus, it joins Sour Bubble and Matrix in holy matrimony. Amen to that.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>Lineage</th>
                                        <th id='FeatureHeaders'>Flavour Profile</th>
                                    </tr>
                                    <tr>
                                        <th>Sour Bubble</th>
                                        <th>Citrus</th>
                                    </tr>
                                    <tr>
                                        <th>Matrix</th>
                                        <th>Fruity</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Creamy</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Plant Type</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Indica Dominant</th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Products</th>
                                        <th id='FeatureHeaders'>THC</th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                    </tr>
                                    <tr>
                                        <th>28g</th>
                                        <th>16-20% </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <th id='FeatureHeaders'>Terpenes</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th id='FeatureSubHeaders'>Dried Flower</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Caryophyllene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Farnesene</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Limonene</th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <MobileFooter />
                    </div>
                </div>
            )
        }

    }
}

export default DiamondDustOG;