import React from 'react'

import { Link } from 'react-router-dom'

import FlavoursBackground from '..//Background/Background'
import Header from '../../BasePage/Header/Header';
import MobileFooter from '../../BasePage/MobileSideBar/MobileFooter/MobileFooter'

//Flavour text
import DiamondDustOG from '../../../assets/img/flavours/TableTop_DiamondDustOG.svg'
import OGKush from '../../../assets/img/flavours/TableTop_90sOGKush.svg'
import BlueLemonThai from '../../../assets/img/flavours/TableTop_BlueLemonThai.svg'
// import AmherstSuperSkunk from '../../../assets/img/flavours/TableTop_AmherstSuperSkunk.svg'
import SundaeDriver from '../../../assets/img/flavours/TableTop_SundaeDriver.svg'
// import LakeTahoeOG from '../../../assets/img/flavours/TableTop_LakeTahoeOG.svg'
import PurplePunch from '../../../assets/img/flavours/TableTop_PurplePunch.svg'
// import SourKush from '../../../assets/img/flavours/TableTop_SourKush.svg'
import StickyBuns from '../../../assets/img/flavours/TableTop_StickyBuns.svg'
import GarlicBreath from '../../../assets/img/flavours/TableTop_GarlicBreath.svg'
import GreaseMonkey from '../../../assets/img/flavours/TableTop_GreaseMonkey.svg'
import GrilledCheese from '../../../assets/img/flavours/TableTop_GrilledCheese.svg'
import GrandaddyBlack from '../../../assets/img/flavours/TableTop_GrandaddyBlack.svg'
// import CactusBreath from '../../../assets/img/flavours/TableTop_CactusBreath.svg';


//'Learn more' buttons
import BeigeButton from '../../../assets/img/button-beige.svg'
import BlackButton from '../../../assets/img/button-black.svg'
import GreenButton from '../../../assets/img/button-green.svg'
import PurpleButton from '../../../assets/img/button-purple.svg'
import YellowButton from '../../../assets/img/button-yellow.svg'
import BrownButton from '../../../assets/img/button-brown.svg'

//Each Strain Component

import './MobileFlavours.css'
class MobileFlavours extends React.Component {
    render() {
        return (
            <div>
                <FlavoursBackground />
                <Header />
                <div className='MobileFlavours'>
                    <Link to='/diamonddustog' className='MobileFlavourCard' style={{ backgroundColor: '#2480bb' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={DiamondDustOG} alt='Diamond Dust OG' style={{width: '160px', paddingLeft: '20px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>
                    <Link to='/ogkush' className='MobileFlavourCard' style={{ backgroundColor: '#b586a9' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={OGKush} alt='OG Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>
                    {/*<Link to='/sourkush' className='MobileFlavourCard' style={{ backgroundColor: '#A55A2D' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={SourKush} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' />
                        </div>
                    </Link>*/}
                    {/*<Link to='/laketahoeog' className='MobileFlavourCard' style={{ backgroundColor: '#2480BB' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={LakeTahoeOG} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={GreenButton} alt='Button' />
                        </div>
                    </Link>*/}
                    <Link to='/bluelemonthai' className='MobileFlavourCard' style={{ backgroundColor: '#0D6158' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={BlueLemonThai} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BeigeButton} alt='Button' />
                        </div>
                    </Link>
                    <Link to='/purplepunch' className='MobileFlavourCard' style={{ backgroundColor: '#D2CDb3' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={PurplePunch} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>
                    <Link to='/stickybuns' className='MobileFlavourCard' style={{ backgroundColor: '#EEBC34' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={StickyBuns} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' />
                        </div>
                    </Link>
                    {/*<Link to='/cactusbreath' className='MobileFlavourCard' style={{ backgroundColor: '#006158' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={CactusBreath} alt='Cactus Breath' style={{width: '150px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' style={{marginRight: '-30px'}}/>
                        </div>
                    </Link>*/}
                    <Link to='/garlicbreath' className='MobileFlavourCard' style={{ backgroundColor: '#2480BB' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={GarlicBreath} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={PurpleButton} alt='Button' />
                        </div>
                    </Link>
                    <Link to='/greasemonkey' className='MobileFlavourCard' style={{ backgroundColor: '#EEBC34' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={GreaseMonkey} alt='Sour Kush' style={{width: '158px', paddingLeft: '21px', paddingRight: '21px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={GreenButton} alt='Button' />
                        </div>
                    </Link>
                    <Link to='/grilledcheese' className='MobileFlavourCard' style={{ backgroundColor: '#A55A2D' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={GrilledCheese} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' />
                        </div>
                    </Link>
                    {/*<Link to='/grandaddyblack' className='MobileFlavourCard' style={{ backgroundColor: '#000' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={GrandaddyBlack} alt='Sour Kush' />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BrownButton} alt='Button' />
                        </div>
                    </Link>*/}
                    {/*<Link to='/amherstsuperskunk' className='MobileFlavourCard' style={{ backgroundColor: '#d1ccb3' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={AmherstSuperSkunk} alt='Amherst Super Skunk' style={{width: '160px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={BlackButton} alt='Button' style={{marginRight: '-21px'}} />
                        </div>
                    </Link>*/}
                    <Link to='/sundaedriver' className='MobileFlavourCard' style={{ backgroundColor: '#b586a9' }}>
                        <div className='MobileFlavourCardName'>
                            <img src={SundaeDriver} alt='Sundae Driver' style={{width: '160px', paddingRight: '20px'}} />
                        </div>
                        <div className='MobileFlavourCardButton'>
                            <img src={YellowButton} alt='Button' style={{marginRight: '-21px'}} />
                        </div>
                    </Link>
                    <MobileFooter />
                </div>

            </div>
        )
    }
}

export default MobileFlavours;