import React from 'react';

import ProductsBackground from '../Background/Background'
import Header from '../../BasePage/Header/Header'

import MobileFooter from '../../BasePage/MobileSideBar/MobileFooter/MobileFooter'

import './MobileProducts.css'

//Images for product page
import Flower from '../../../assets/img/Products_Flower.png';
import Vape from '../../../assets/img/Products_Vape.png';

import FlowerButtonImage from '../../../assets/img/Products_Flower_button.svg';
import FlowerButtonImageHover from '../../../assets/img/Products_Flower_button_hover.svg';

import PrerollsButtonImage from '../../../assets/img/Products_Prerolls_button.svg';
import PrerollsButtonImageHover from '../../../assets/img/Products_Prerolls_button_hover.svg';


class MobileProducts extends React.Component {
    render() {
        return (
            <div>
                <ProductsBackground />
                <Header />
                <div className='MobileProducts'>
                    <h1 id='HeaderText'>
                        <span>PRODUCTS</span><span>PAGE</span>
                    </h1>
                    {/*<div className='MobileProductImage'>
                        <img src={Flower} alt='Flower' style={{ marginRight: '10px' }} />
                        <img src={Vape} alt='Vape' />
                    </div>*/}
                    <div className='MobileProductInfo  MobileProductInfo--flower' style={{marginBottom: '20px'}}>
                        <div className='ProductDescription'>
                            <div>
                                <a href="/products/dried-flower" class="ProductDescription__button  ProductDescription__button--mobile  ProductDescription__button--flower" onMouseOver={e => e.currentTarget.firstChild.src = FlowerButtonImageHover} onMouseOut={e => e.currentTarget.firstChild.src = FlowerButtonImage}><img class="ProductDescription__button-image" src="/static/media/Products_Flower_button.3e1fd1de.svg" alt="" /></a>
                                <h1>Dried Flower</h1>
                                <p>Pick a bud. Grab a bag. Try something new. Stick with an old fave. Whatever you’re after, you’ve got some decent options from TABLE TOP™. Either way you’re getting high THC in tight ranges, freshness and flavour. It’s literally the least we could do (jk our growers actually do the most – y’all are great).</p>
                                {/*<ul className='ProductFeatures'>
                                    <li>
                                        Feature 01
                                    </li>
                                    <li>
                                        Feature 02
                                    </li>
                                    <li>
                                        Feature 03
                                    </li>
                                    <li>
                                        Feature 03
                                    </li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>

                    {/* prerolls text:

                    Whether your rolling skills are the worst or you’re blaming a strong gust of wind (lol nice), having pre-rolled options on the table is pretty neat. We’ve got a few different ones to choose from which is also pretty neat. One day we will learn more adjectives. Until then, try some of our neat TABLE TOP pre-rolls!

                    */}

                    <div className='MobileProductInfo  MobileProductInfo--pre-rolls'>
                        <div className='ProductDescription'>
                            <div>
                                <a href="/products/pre-rolls" class="ProductDescription__button  ProductDescription__button--mobile  ProductDescription__button--pre-rolls"  onMouseOver={e => e.currentTarget.firstChild.src = PrerollsButtonImageHover} onMouseOut={e => e.currentTarget.firstChild.src = PrerollsButtonImage}><img class="ProductDescription__button-image" src="/static/media/Products_Prerolls_button.ed3594dc.svg" alt="" /></a>
                                <h1>Pre-Rolls</h1>
                                <p>Whether your rolling skills are the worst or you’re blaming a strong gust of wind (lol nice), having pre-rolled options on the table is pretty neat. We’ve got a few different ones to choose from which is also pretty neat. One day we will learn more adjectives. Until then, try some of our neat TABLE TOP pre-rolls!</p>
                                {/*<ul className='ProductFeatures'>
                                    <li>
                                        Feature 01
                                    </li>
                                    <li>
                                        Feature 02
                                    </li>
                                    <li>
                                        Feature 03
                                    </li>
                                    <li>
                                        Feature 03
                                    </li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                    <MobileFooter />
                </div>

            </div>
        )
    }

}

export default MobileProducts;