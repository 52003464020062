import React from 'react'

import { Link } from 'react-router-dom'

import OGKushBackground from './Background/Background.js'
import Header from '../../../BasePage/Header/Header.js'
import FlavoursNavBar from '../FlavoursNavBar/FlavoursNavBar'
import OGKushPicture from '../../../../assets/img/flavours/TableTop_90sOGKush.svg'
import MobileFooter from '../../../BasePage/MobileSideBar/MobileFooter/MobileFooter.js'


import './OGKush.css'
class OGKush extends React.Component {

    // adam added
    componentDidMount() {

        document.title = "90s OG Kush Vape | TABLE TOP";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP 90s OG Kush vape is a classic indica with earthy, herbal and woody flavour. Buy it at your favourite local shop. Tearaway pants not included.`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already

    
    }
    // end adam added

    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    <OGKushBackground />
                    <Header />
                    <FlavoursNavBar />
                    <div className='OGKushPage'>
                        <div className='OGKushContent'>
                            <h1 className='ProductTitle'><img src={OGKushPicture} alt='TABLE TOP™ OG Kush' /></h1>
                            <div className='OGKushText'>
                                Let the debate rage on about where this classic indica came from, but it's been pleasing crowds since the '90s so we figured it was a sure bet. You may recognize its distinctive notes of earth and spice. Tearaway pants not included, but any pants can be tearaways if you're brave enough.
                                <p></p>
                                <table>
                                    <tr>
                                        <th id='FeatureHeaders'>
                                            Lineage
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Plant Type
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Flavour Profile
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Products
                                        </th>
                                        <th id='FeatureHeaders'>
                                            THC
                                        </th>
                                        <th id='FeatureHeaders'>
                                            Terpenes
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Unknown
                                        </th>
                                        <th>
                                            Indica Dominant
                                        </th>
                                        <th>
                                            Earthy
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                        <th id='FeatureSubHeaders'>
                                            Vape
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                            Herbal
                                        </th>
                                        <th>
                                            0.5g
                                        </th>
                                        <th>
                                            81%
                                        </th>
                                        <th>
                                            Limonene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th> </th>
                                        <th>
                                        </th>
                                        <th>
                                            Woodsy
                                        </th>
                                        <th>1g</th>
                                        <th>
                                            81%
                                        </th>
                                        <th>
                                            Myrcene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th> </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>

                                        </th>
                                        <th>
                                            Caryophyllene
                                        </th>
                                    </tr>
                                    <tr>
                                        <th> </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th> </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th> </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th> </th>
                                        <th></th>
                                        <th>

                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th> </th>
                                        <th></th>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='ConstantTermsAndConditions' style={{top: '120vh'}}>
                        <div className='LeftDisclaimer'>
                            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                        </div>
                        <div className='RightDisclaimer'>
                        © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
            <div>
                <OGKushBackground />
                <Header />
                <FlavoursNavBar />
                <div className='OGKushPage'>
                    <div className='OGKushContent'>
                        <h1 className='ProductTitle'><img src={OGKushPicture} alt='TABLE TOP™ OG Kush'/></h1>
                        <div className='OGKushText'>
                            Let the debate rage on about where this classic indica came from, but it's been pleasing crowds since the '90s so we figured it was a sure bet. You may recognize its distinctive notes of earth and spice. Tearaway pants not included, but any pants can be tearaways if you're brave enough.
                            <p></p>
                            <table>
                                <tr>
                                    <th id='FeatureHeaders'>Lineage</th>
                                    <th id='FeatureHeaders'>Flavour Profile</th>
                                </tr>
                                <tr>
                                    <th>Unknown</th>
                                    <th>Earthy</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Herbal</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Woodsy</th>
                                </tr>
                                {/* DIVIDER */}
                                <br></br>
                                <tr>
                                    <th id='FeatureHeaders'>Plant Type</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th>Sativa Dominant</th>
                                    <th></th>
                                </tr>
                                {/* DIVIDER */}
                                <br></br>
                                <tr>
                                    <th id='FeatureHeaders'>Products</th>
                                    <th id='FeatureHeaders'>THC</th>
                                </tr>
                                <tr>
                                    <th id='FeatureSubHeaders'>Vape</th>
                                    <th id='FeatureSubHeaders'>Vape</th>
                                </tr>
                                <tr>
                                    <th>0.5g</th>
                                    <th>81%</th>
                                </tr>
                                <tr>
                                    <th>1g</th>
                                    <th>81%</th>
                                </tr>
                                <tr>
                                    <th id='FeatureSubHeaders'></th>
                                    <th id='FeatureSubHeaders'></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                {/* DIVIDER */}
                                <br></br>
                                <tr>
                                    <th id='FeatureHeaders'>Terpenes</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th id='FeatureSubHeaders'>Vape</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th>Limonene</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th>Myrcene</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th>Caryophyllene</th>
                                    <th></th>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <MobileFooter />
                </div>
            </div>
        )
        }

    }
}

export default OGKush;