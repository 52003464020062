/* eslint-disable */
import React from 'react'

import BuyBackground from './Background/Background'
import Header from '../BasePage/Header/Header'
import { Link } from 'react-router-dom'

import Stockist from './Stockist.js'

import GoButton from '../../assets/img/hover_buttons/Go.svg'
import GoButtonHover from '../../assets/img/hover_buttons/GoHover.svg'

import MobileBuy from './MobileBuy/MobileBuy'

import $ from 'jquery';

import './Buy.css'

class Buy extends React.Component {


    // -----------------------------------------------------------------------------
    // adam added
    // -----------------------------------------------------------------------------

    handleClick = () => {
        window.location.href='mailto:hello@bzam.com?subject=Please%20find%20me%20a%20local%20shop%20that%20carries%20TABLE%20TOP!';
    }


    handleProvinceDropdownChange = () => {
        // if we've just changed provinces, then always hide that BC popup, because it might no longer be relevant
        document.getElementById('js-find-in-bc-infobox').classList.remove('is-visible');
    }


    

    openProvincePage = () => {

        // get the selected item from the dropdown
        var selectedValue = document.getElementById('Province').value;

        // Participating Retailers selected - smooth-scroll to Stockist section
        if( selectedValue === 'js-participating-retailers' )
            $("html, body").animate({ scrollTop: $('#stockist-widget').offset().top - 22 }, 800);

        // British Columbia selected - show that popup
        else if( selectedValue === 'js-find-in-bc' )
            document.getElementById('js-find-in-bc-infobox').classList.toggle('is-visible');

        // any other province selected - go to that province page in new tab
        else
            window.open(selectedValue, '_blank');

        // don't follow the link
        event.preventDefault();
    }



    


    
    componentDidMount() {

        document.title = "Find TABLE TOP™ Cannabis Near You | It’s Got Legs™";

        // add/update the meta description
        let tab_page_description     = `TABLE TOP cannabis is for folks who want pretty good options that don’t break the bank. A Canadian cannabis brand, we’re known for our reliable dried flower, ounces, vapes and pre-rolls, including TABLE TOP Sticky Buns, TABLE TOP Purple Punch and TABLE TOP Jungle Cake. It’s Got Legs™`;
        let tab_description_meta_tag = document.querySelector(`meta[name="description"]`);
        if (tab_description_meta_tag) // check if the meta tag already exists
          tab_description_meta_tag.setAttribute("content", tab_page_description);
        else
          document.head.insertAdjacentHTML('beforeend', `<meta name="description" content="${tab_page_description}">`); // adds the description meta tag
        // end update the meta description (or add one of tag doesn't exist already


        document.body.classList.add('buy-page');


        
        /*
        // Toggle element visibility
        var toggleVisibility = function (elem) {
            elem.classList.toggle('is-visible');
        };

        // "British Columbia" clicked on - show the extra popup / infobox
        document.addEventListener('click', function (event) {

            // If the clicked element doesn't have the right selector, bail
            if ( !event.target.matches('#GoButton') ) return;

            var selectedValue = document.getElementById('Province').value;

            if( selectedValue !== 'js-find-in-bc' )
                window.open(selectedValue, '_blank');

            // Don't follow the link
            event.preventDefault();

            // toggle the popup
            // toggleVisibility( document.getElementById('js-find-in-bc-infobox') );

            // Log the clicked element in the console
            // console.log(event.target);

        }, false);
    
        */

    }


    componentWillUnmount() {
        document.body.classList.remove('buy-page');
    }

    // -----------------------------------------------------------------------------
    // end adam added
    // -----------------------------------------------------------------------------



    render() {
        let width = window.innerWidth;
        if (width > 700) {
            return (
                <div>
                    {/*<BuyBackground />*/}
                    {/*<div className='BuyBackground'>*/}
                        {/*<Header />*/}
                        <div className='Buy'>
                            <h1 id='HeaderText'>
                                WHERE TO BUY TABLE TOP
                            </h1>
                            <div className='BuyContent'>
                                <div className='BuyText'>
                                    

                                    {/*<p>Choose your province from the list below:</p>*/}
                                    
                                    <p>Looking for TABLE TOP cannabis near you? Nice!!! Our products are available at select retailers throughout Canada. Check your provincial online store or find participating retailers near you below.</p>

                                    <div className='ProvinceWrapper'>
                                        <select id="Province" style={{paddingRight: "20px"}} onChange={() => this.handleProvinceDropdownChange()}>
                                            <option value="js-participating-retailers">Participating Retailers</option>
                                            <option value="https://bit.ly/3vaTc5B">Alberta Cannabis Store</option>
                                            <option value="js-find-in-bc">British Columbia Cannabis Store</option>
                                            <option value="https://bit.ly/2RRF42N">Manitoba Cannabis Store</option>
                                            <option value="https://bit.ly/3gtCj1R">New Brunswick Cannabis Store</option>
                                            <option value="https://bit.ly/32KlKGN">Newfoundland and Labrador Cannabis Store</option>
                                            <option value="https://bit.ly/3n9x9tp">Ontario Cannabis Store</option>
                                            <option value="https://bit.ly/3vffenF">Saskatchewan Cannabis Store</option>
                                            {/*
                                            <option value="NT" data-target="unavailable">Northwest Territories Cannabis Store</option>
                                            <option value="NS" data-target="unavailable">Nova Scotia Cannabis Store</option>
                                            <option value="NU" data-target="unavailable">Nunavut Cannabis Store</option>
                                            <option value="PE" data-target="unavailable">Prince Edward Island Cannabis Store</option>
                                            <option value="QC" data-target="unavailable">Quebec Cannabis Store</option>
                                            <option value="YU" data-target="unavailable">Yukon Cannabis Store</option>
                                            */}
                                        </select>
                                    </div>

                                    <img src={GoButton} class='GoButton' id='GoButton' alt='Go' onMouseOver={e => e.currentTarget.src = GoButtonHover} onMouseOut={e => e.currentTarget.src = GoButton} onClick={() => this.openProvincePage()} />

                                    <span class="find-in-bc-infobox" id="js-find-in-bc-infobox" onClick={() => this.handleClick()}>To find TABLE TOP near you email hello@bzam.com</span>



                                    <div className='BuyDivider'></div>



                                    {/*<Stockist />*/}

                                    <div className="hibuddy">
                                        <iframe class="hibuddy__iframe" src="https://hibuddy.ca/brandstoremap?brand=TABLE%20TOP&view=list" frameborder="0"></iframe>
                                    </div>

                                    




                                    {/*
                                    <div className='ProvinceList'>
                                        <div className='LeftList'>
                                            <a href='https://bit.ly/3vaTc5B' target="_blank" rel="noopener noreferrer">Alberta</a>
                                            <a href='#' class="js-find-in-bc" rel="noopener noreferrer">British Columbia<span class="find-in-bc-infobox" id="js-find-in-bc-infobox" onClick={() => this.handleClick()}>To find TABLE TOP near you email hello@bzam.com</span></a>
                                            <a href='https://bit.ly/2RRF42N' target="_blank" rel="noopener noreferrer">Manitoba</a>
                                            <a href='https://bit.ly/3gtCj1R' target="_blank" rel="noopener noreferrer">New Brunswick</a>
                                            <a href='https://bit.ly/32KlKGN' target="_blank" rel="noopener noreferrer">Newfoundland and Labrador</a>
                                            <a id='Unavailable'>Northwest Territories</a>
                                            <a id='Unavailable'>Nova Scotia</a>
                                        </div>
                                        <div className='RightList'>
                                            <a id='Unavailable'>Nunavut</a>
                                            <a href='https://bit.ly/3n9x9tp' target="_blank" rel="noopener noreferrer">Ontario</a>
                                            <a id='Unavailable'>Prince Edward Island</a>
                                            <a id='Unavailable'>Quebec</a>
                                            <a href='https://bit.ly/3vffenF' target="_blank" rel="noopener noreferrer">Saskatchewan</a>
                                            <a id='Unavailable'>Yukon</a>
                                        </div>
                                    </div>
                                    */}

                                </div> {/* /.BuyText */}
                            </div> {/* /.BuyContent */}
                        </div> {/* /.Buy */}

                        {/*<div className='ConstantTermsAndConditions  ConstantTermsAndConditions--BuyPage'>
                            <div className='LeftDisclaimer'>
                                <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</Link> | <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> | <a href='mailto:hello@bzam.com'>hello@bzam.com</a>
                            </div>
                            <div className='RightDisclaimer'>
                            © 2021 BZAM Management Inc. All Rights Reserved. Website Design by: <a href='https://www.webbcreative.ca/' target="_blank" rel='noopener noreferrer'><span style={{ color: 'red' }}>W</span><span style={{ color: 'orange' }}>e</span><span style={{ color: 'yellow' }}>b</span><span style={{ color: '#90ee90' }}>b</span> <span style={{ color: 'cyan' }}>C</span><span style={{ color: 'blue' }}>r</span><span style={{ color: 'purple' }}>e</span><span style={{ color: 'red' }}>a</span><span style={{ color: 'red' }}>t</span><span style={{ color: 'orange' }}>i</span><span style={{ color: 'yellow' }}>v</span><span style={{ color: '#90ee90' }}>e</span></a>
                            </div>
                        </div>*/}
                    {/*</div>*/} {/* /.BuyBackground */}
                </div>

            )
        }
        else {
            return <MobileBuy />
        }

    }
}

export default Buy;